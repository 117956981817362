<template>
  <div :id="chartId" style="width: 100%; height: 100% !important;"></div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, computed, watch, nextTick } from 'vue';
import zingchart from 'zingchart';
import 'zingchart/modules-es6/zingchart-treemap.min.js';
import { useStore } from 'vuex';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'HeatMap',
  setup() {
    const chartId = ref('zingchart-' + Math.random().toString(36).substr(2, 9));
    const store = useStore();

    const heatData = computed(() => store.state.analysis.heatList);
    const heatType = computed(() => store.state.analysis.heatType);

    const formattedHeatData = computed(() => {
      return heatData.value.map((item, index) => {

        let font = 10;
        if (index < 3) {
          font = 30;
        } else if (index < 6) {
          font = 15;
        } else if (index < 10) {
          font = 10;
        } else if (index < 15) {
          font = font - 1;
        } else {
          font = font - 3;
        }

        if (heatType.value === 'change') {
          font = 15;
        }
        let fontSize = `${font}px`;

        let backColor

        if (item.long) {
          backColor = item.long < item.short ? 'var(--color-red)' : 'var(--color-green)'
        } else if (item.data) {
          backColor = item.data < 0 ? 'var(--color-red)' : 'var(--color-green)'
        } else {
          backColor = 'var(--color-green)'
        }

        return {
          ...item,
          style: {
            fontSize: fontSize,
            textAlign: 'center',
            verticalAlign: 'middle',
            backgroundColor: backColor,
          },
        };
      });
    });

    const chartData = ref({
      type: 'treemap',
      backgroundColor: "transparent",
      overflow: 'hidden',
      options: {
        splitType: 'balancedV2',
        box: {
          borderColor: "rgba(45, 45, 45, 0.9) ",
          borderRadius: '8px',
          borderWidth: '5px',
          margin: '5px',
          padding: '10px',
          overflow: 'hidden',
          hoverState: 'none'
        },
        tooltipBox: {
          text: "%text",
          overflow: 'hidden',
        },
      },
      gui: {
        contextMenu: {
          empty : true
        }
      },
      plotarea: {
        margin: '5',
      },
      series: formattedHeatData.value,
    });

    const renderChart = () => {
      zingchart.render({
        id: chartId.value,
        data: chartData.value,
        height: '100%',
        width: '100%',
      });
    };

    const removeBtn = () => {
      nextTick(() => {
          const toolBar = document.querySelector(`#${chartId.value}-tools`);
          if (toolBar) {
          toolBar.style.display = 'none'; // 요소 숨기기
          }
      });
    }

    onMounted(async () => {
      renderChart();
      removeBtn();
    });

    onBeforeUnmount(() => {
      zingchart.exec(chartId.value, 'destroy');
    });

    watch(formattedHeatData, (newData) => {
      chartData.value.series = newData;
      renderChart();
      removeBtn();
    });

    return {
      chartId,
    };
  },
  computed: {
    ...mapState('analysis', ['heatList', 'heatType']),
    ...mapState('dominance', ['lang']),
  },
  methods: {
    ...mapActions('analysis', ['loadHeatMap']),
  },
  watch: {
    heatType() {
      this.loadHeatMap();
    },
    lang() {
      this.loadHeatMap();
    }
  },
};
</script>

<style>
#chartId {
  width: 100%;
  height: 100%;
}
</style>
