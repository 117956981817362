<template>
  <div class="mainWrap list">
    <div class="subCont whiteDoc">
      <TitleView />
      <div id="screen__pc" class="wrapper">
        <div class="brief-wrapper">
          <div class="brief-btn-container">
            <button @click="timeChange(0)" class="brief-button" :class="{ selectTime: briefTime === 'AM' }">AM</button>
            <button @click="timeChange(1)" class="brief-button" :class="{ selectTime: briefTime === 'PM' }">PM</button>
          </div>
          <div class="brief-title-wrapper" v-for="(title, i) in titleList" :key="i">
            <div class="title-brief-wrapper">
              <div class="pre-title-brief">{{ $t('brief.aiBrief') }}</div>
              <div class="title-brief" @click="openDetail(title.id)">
                {{ title.mainTitle }} AI {{ $t('brief.briefing') }}
                <div class="title-btn-wrapper" :class="{ open: detailTab && briefId === title.id }">
                  <button id="title-btn" :class="{ openDetail: detailTab && briefId === title.id }" ></button>
                </div>
              </div>
            </div>
            <AIDetail v-if="detailTab && briefId === title.id" :aiBrief="detail" />
          </div>
        </div>
      </div>
      <div id="screen__mobile" class="brief-mobile-wrapper">
        <div class="brief-btn-container">
          <button @click="timeChange(0)" class="brief-button" :class="{ selectTime: briefTime === 'AM' }">AM</button>
          <button @click="timeChange(1)" class="brief-button" :class="{ selectTime: briefTime === 'PM' }">PM</button>
        </div>
        <div class="brief-title-wrapper-mobile" v-for="(title, i) in titleList" :key="i">
          <!-- <div class="title-brief-wrapper-mobile"> -->
            <div class="brief_title">{{ $t('brief.aiBrief') }}</div>
            <div class="title-brief" @click="openDetail(title.id)" :class="{ 'active-title': detailTab && briefId === title.id }">
                {{ title.mainTitle }} AI {{ $t('brief.briefing') }}
                <div class="title-btn-wrapper-mobile" :class="{ open: detailTab && briefId === title.id }">
                  <button id="title-btn" :class="{ openDetail: detailTab && briefId === title.id }" ></button>
                </div>
              </div>
              <!-- <div class="title-brief" v-if="briefNum <= i">
                {{ title.mainTitle }} AI {{ $t('brief.briefing') }}
                <div class="title-btn-wrapper-lock-mobile">
                  <button  id="title-btn-lock" ></button>
                </div>
              </div> -->
          <!-- </div> -->
          <AIDetail v-if="detailTab && briefId === title.id" :aiBrief="detail" />

        </div>

      </div>

    </div>
  </div>
</template>

<script>
import "@/assets/css/brief.css"
import { mapActions, mapMutations, mapState } from 'vuex'

import TitleView from "@/components/public/TitleView.vue"
import AIDetail from "@/components/brief/sub/AIDetail.vue"

export default {
  name: 'AIBrief',
  data() {
    return {
      // lang: localStorage.getItem("lang") || "en",
      detailTab: false,
      detail: [],
      briefId: 0,
      briefNum: 0,
    }
  },
  components: {
    TitleView,
    AIDetail,
  },
  async created() {
    await this.loadTitle();
    await this.chkBrief();
  },
  computed: {
    ...mapState("brief", ["briefTime", "titleList", "aiBrief"]),
    ...mapState("dominance", ["lang", "warnModal"]),

  },
  methods: {
    ...mapMutations("dominance", ["changeWarn"]),
    ...mapMutations("brief", ["changeTime"]),
    ...mapActions("brief", ["loadTitle", "loadDetail"]),

    async timeChange(type) {
      this.changeTime(type)
      await this.loadTitle();
    },

    async openDetail(id) {
      let language;

      if (this.lang === 'ko') {
        language = 'korea'
      } else if (this.lang === 'ja') {
        language = 'japan'
      } else if (this.lang === 'zh') {
        language = 'chinese'
      } else if (this.lang === 'vi') {
        language = 'vietnam'
      } else {
        language = 'english'
      }

      let info = {
        "id": id,
        lang: language
      }

      await this.loadDetail(info)
      this.detail = this.aiBrief;

      if (this.briefId === id) {
        this.detailTab = !this.detailTab;
      } else {
        this.briefId = id;
        await this.loadDetail(info);
        this.detail = this.aiBrief;
        this.detailTab = true;
      }

      this.briefId = info.id
    },

    async chkBrief() {
      this.briefNum = this.titleList.length/2
    },

    warnOpen() {
      this.changeWarn(true);
    },
  }
}

</script>
