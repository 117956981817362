import { API } from "./apiAuth";


// clearing chart 조회 api
const getLiq = async (day) => {
  try {
    const response = await API.get(`liq?inter=${day}`);
    return response.data;
  } catch (error) {
    console.error("Error get clearing chart data: ", error);
    throw error;
  }
};

export default {
    getLiq,
};


