<template>
  <div class="subPage_container" v-if="loadDetail && aiBrief">
      <div>
          <div class="bbs_info">
              <h1 class="time">{{ aiBrief.Date }} {{ aiBrief.Time }} (UTC)</h1>
          </div>

          <div class="briefing_Txt">
            <div ref="content" v-html="aiBrief.content" @click="handleClick"></div>
          </div>
      </div>
  </div>

  <div class="subPage_container" v-if="!loadDetail">
      <div class="loading_spinner">
      <div class="loading_circle"></div>
      </div>
  </div>

</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

export default {
name: 'AIDetail',
data() {
  return {
    lang: localStorage.getItem('lang') || 'en',

  };
},
props: {
  aiBrief: Object,
},
async mounted() {
  // 사진 확대 모달
  Fancybox.bind('[data-fancybox="gallery"]', {});
  this.chkLang();
},
computed: {
  ...mapState("brief", ["loadDetail"]),
},
methods: {
  ...mapActions("board", ["getLangAiBrief", "getAibriefTitleList"]),
  ...mapMutations("board", ["setLanguage"]),

  chkLang() {
    this.$nextTick(() => {
      const content = this.$refs.content;
      if ((content && content.querySelectorAll(".ai-not-news section")).length === 5) {
        const dominance = content.querySelectorAll(".ai-not-news section")[1]
        dominance.style.display = "none";
      }
      if (content && content.querySelectorAll(".audio-wrapper")) {
      const audioWrappers = content.querySelectorAll(".audio-wrapper");
        audioWrappers.forEach((audioWrapper) => {
          audioWrapper.style.display = "none";
        });
      }
    });
  },
  handleClick(e) {
    if (
      e.target.classList.contains("ai-title") ||
      e.target.classList.contains("ai-arrow") ||
      e.target.classList.contains("ai-title-wrapper")
    ) {
      this.toggleAccordion(e);
    }

    if (e.target.id === "play-pause-btn") {
      this.togglePlayPause(e);
    }
  },
  toggleAccordion(e) {
    let titleWrapper;

    if (
      e.target.classList.contains("ai-title") ||
      e.target.classList.contains("ai-arrow")
    ) {
      titleWrapper = e.target.closest(".ai-title-wrapper");
    } else if (e.target.classList.contains("ai-title-wrapper")) {
      titleWrapper = e.target;
    }

    if (titleWrapper) {
      const article = titleWrapper.nextElementSibling;
      if (article && article.tagName === "ARTICLE") {
        article.classList.toggle("active");

        if (article.classList.contains("active")) {
          article.style.maxHeight = article.scrollHeight + "px";
        } else {
          article.style.maxHeight = 0;
        }
      }

      const arrow = titleWrapper.querySelector(".ai-arrow");
      arrow.classList.toggle("active");
    }
  },
},
}

</script>
