<template>
  <div class="loading_spinner" v-if="chkRain === false">
    <div class="loading_circle"></div>
  </div>
  <div :id="chartId" v-if="chkRain === true" style="background: var(--background-chart);"></div>
</template>

<script>
import { ref, onMounted, computed, nextTick, getCurrentInstance, watch } from 'vue';
import zingchart from 'zingchart';
import 'zingchart/modules-es6/zingchart-treemap.min.js';
import { mapState, useStore } from 'vuex';

export default {
  name: 'RainbowChart',
  setup() {
    const { proxy } = getCurrentInstance();
    const chartId = ref('zingchart-' + Math.random().toString(36).substr(2, 9));
    const store = useStore();
    const rainbowData = computed(() => store.state.indicator.chartRainbow);
    const language = computed(() => store.state.dominance.lang);

    const getTranslatedText = computed(() => {
      return {
        bonanza: proxy.$t('indi.bon'),
        bail: proxy.$t('indi.ready'),
        frenzy: proxy.$t('indi.frenzy'),
        stash: proxy.$t('indi.stash'),
        buy: proxy.$t('indi.buy'),
        sale: proxy.$t('indi.sale'),
        bitcoin: proxy.$t('pub.btcPrice'),
      };
    });

    const chartData = ref({
      type: "mixed",
      globals: {
        fontFamily: 'var(--main-font-Light), "Noto Sans JP", "Noto Sans SC"',
        fontColor: "var(--color-main)",
        fontSize: '15px',
      },
      gui: {
        contextMenu: {
          empty: false
        }
      },
      backgroundColor: "transparent",
      legend: {
        align: 'center',
        backgroundColor: 'transparent',
        borderColor: 'none',
        layout: 'x7',
        shadow: false,
        item: {
          fontColor: '#FFFFFF',
        },
        marker: {
          'border-color': 'none',
          size: '8px',
        },
      },
      plot: {
        tooltip: {
          visible: false,
        },
        alphaArea: 1,
        aspect: 'spline',
        hoverState: {
          backgroundColor: 'none',
        },
        lineWidth: '0px',
        marker: {
          visible: false,
        },
      },
      scaleX: {
        values: [],
        guide: {
          visible: false,
        },
      },
      scaleY: {
        values: [0.01, 0.1, 1, 10, 100, 1000, 10000, 100000, 1000000], 
        guide: {
          visible: false,
        },
        progression: "log",
      },
      crosshairX: {
        lineColor: '#FFFFFF',
        plotLabel: {
          "backgroundColor": "var(--background-chart2)",
           fontFamily: 'var(--main-font-Light), "Noto Sans JP", "Noto Sans SC"',
           text: "%t: $ %v",
           "thousands-separator": ","
        },
        lineWidth: '1px',
        "border-width": 1,
        marker: {
          visible: false,
        },
        offsetY: '10%',
      },
      series: [
        {
          type: "range",
          text: getTranslatedText.value.bonanza,
          datas: [],
          scales: "scaleX, scaleY",
          values: [],
          backgroundColor: 'var(--chart-red)',
          lineColor: 'var(--chart-red)',
          valueRange: true,
        },
        {
          type: "range",
          text: getTranslatedText.value.bail,
          scales: "scaleX, scaleY",
          values: [],
          backgroundColor: 'var(--chart-yellow)',
          lineColor: 'var(--chart-yellow)',
          valueRange: true,
        },
        {
          type: "range",
          text: getTranslatedText.value.frenzy,
          scales: "scaleX, scaleY",
          values: [],
          backgroundColor: 'var(--rainbow-yellow)',
          lineColor: 'var(--rainbow-yellow)',
          valueRange: true,
        },
        {
          type: "range",
          text: getTranslatedText.value.stash,
          scales: "scaleX, scaleY",
          values: [],
          backgroundColor: 'var(--text-green)',
          lineColor: 'var(--text-green)',
          valueRange: true,
        },
        {
          type: "range",
          text: getTranslatedText.value.buy,
          scales: "scaleX, scaleY",
          values: [],
          backgroundColor: 'var(--scrole-color)',
          lineColor: 'var(--scrole-color)',
          valueRange: true,
        },
        {
          type: "range",
          text: getTranslatedText.value.sale,
          scales: "scaleX, scaleY",
          values: [],
          backgroundColor: 'var(--chart-purple)',
          lineColor: 'var(--chart-purple)',
          valueRange: true,
        },
        {
          type: "line",
          text: getTranslatedText.value.bitcoin,
          scales: "scaleX, scaleY",
          "line-color": "#FFFFFF",
          "line-width": 3,
          values: [],
          marker: {
            "background-color": "#FFFFFF",
            "border-width": 2,
            shadow: 0,
            "border-color": "#1C1C1C",
          },
        },
      ],
    });

    const renderChart = () => {
      zingchart.render({
        id: chartId.value,
        data: chartData.value,
        height: '500px',
        width: '100%',
      });
    };

  const fontLang = () => {
      if (language.value === 'ja') {
        return "Noto Sans JP"
      } else if (language.value === 'zh') {
        return "Noto Sans SC"
      } else {
        return "var(--main-font-Light)"
      }
    }

    const removeBtn = () => {
      nextTick(() => {
          const toolBar = document.querySelector(`#${chartId.value}-trigger-c`);
          if (toolBar) {
          toolBar.style.display = 'none'; // 요소 숨기기
          }
      });
    }

    const updateChartData = (newData) => {
      chartData.value.globals.fontFamily = fontLang();
      chartData.value.series[0].values = newData.max;
      chartData.value.series[1].values = newData.sell;
      chartData.value.series[2].values = newData.fomo;
      chartData.value.series[3].values = newData.acc;
      chartData.value.series[4].values = newData.buy;
      chartData.value.series[5].values = newData.fire;
      chartData.value.series[6].values = newData.btc;

      chartData.value['scaleX'].values = newData.timeValue;

      renderChart();
    }

    const loadRainbowChart = async () => {
      await store.dispatch('indicator/loadRainbow');  
      await nextTick();
      if (rainbowData.value) {
        updateChartData(rainbowData.value);
      }
      removeBtn();
    }

    onMounted(async () => {
      await loadRainbowChart();
    });

    watch(getTranslatedText, () => {
      chartData.value.series[0].text = getTranslatedText.value.bonanza;
      chartData.value.series[1].text = getTranslatedText.value.bail;
      chartData.value.series[2].text = getTranslatedText.value.frenzy;
      chartData.value.series[3].text = getTranslatedText.value.stash;
      chartData.value.series[4].text = getTranslatedText.value.buy;
      chartData.value.series[5].text = getTranslatedText.value.sale;
      chartData.value.series[6].text = getTranslatedText.value.bitcoin;

      if (rainbowData.value) {
        updateChartData(rainbowData.value);
      }
      removeBtn();
    })

    return {
      chartId,
    };
  },
  computed: {
    ...mapState("indicator", ["chkRain"])
  },
};
</script>

<style>
#chartId {
  width: 100%;
  height: 100%;
}
</style>
