import api from "@/api/apiDominance";
import { updateData } from "@/utils/changeValue";

export default {
    namespaced: true,
    state() {
      return {
        // 변수
        loadStateMarket: false,
        loadDomi: false,
  
        // 응답 리스트
        goyaChart: [],
        marketChart: []

  
        // 요청 리스트&변수
  
      };
    },
    mutations: {
      changeMarket(state, value) {
        state.loadStateMarket = value;
      },
      changeDomi(state, value) {
        state.loadDomi = value;
      },
       
    }, 
    actions: {
       // 고야 차트 조회 api
       async loadDominance(context) {
        try{
          let response = await api.getDominance(25);
          updateData(response.result)
          
          let data = response.result.slice(-12);
          
          context.state.goyaChart = data
          context.state.loadDomi = true;
        } catch (error) {
          console.error("Error get dominance chart data :", error);
          return;
        }
      },
       // 마켓 차트 조회 api
       async loadMarket(context) {
        try{
          let response = await api.getMarket();
          let data = response.result;
          data.total_cap = data.total_cap.toLocaleString();
          context.state.marketChart = response.result
          context.state.loadStateMarket = true;
        } catch (error) {
          console.error("Error get market chart data :", error);
          return;
        }
      },
    },
  };
  