<template>
    <!-- 로딩 상태 표시 -->
    <div class="loading_spinner" v-if="!loadStateMarket">
      <div class="loading_circle"></div>
    </div>
    <!-- 마켓 차트 -->
    <div class="market-pie-chart-wrapper"  v-if="loadStateMarket">
      <div class="chart-market-dominance"   @contextmenu.prevent >
        <div :id="chartId" class="dominance_chart" @contextmenu.prevent></div>
      </div>
    </div>
    <img class="click_goya chart-icon" @click="openMini(2)" src="@/assets/images/dominance_images/chart_icon.png">
</template>

<script>
import { ref, onMounted, onBeforeUnmount, computed, watch } from 'vue';
import { useStore, mapState, mapActions, mapMutations } from 'vuex';
import zingchart from 'zingchart';

export default {
  name: 'ZingChart',
  setup() {
    const chartId = ref('zingchart-' + Math.random().toString(36).substr(2, 9));
    const store = useStore();
    const marketData = computed(() => store.state.dominance_chart.marketChart);
    const loadStateMarket = computed(() => store.state.dominance_chart.loadStateMarket);

    const renderChart = () => {
      zingchart.render({
        id: chartId.value,
        data: {
          type: 'ring',
          boreder: "none",
          backgroundColor: "transparent",
          globals: {
            fontFamily: 'var(--main-font-Light), "Noto Sans JP", "Noto Sans SC"',
            fontColor: "var(--color-main)",
          },
          gui: {
            contextMenu: {
              empty: false
            }
          },
          plot: {
            layout: 'auto',
            backgroundColor: "transparent",
            slice: 0.4,
            tooltip: { visible: false },
            valueBox: {
              text: '%t',
              color: '#FFF',
              fontSize: '14px',
              fontWeight: 'bold',
            },
            cursor: 'hand',
            shadow: true,
            placement: "middle",
            borderColor: "transparent",
          },
          series: [
            { 
              values: [marketData.value.btc],
              valueBox: { text: 'BTC<br>(%v%)', decimals: 0, fontColor: 'var(--color-main)', fontSize: '14px', offsetR: '5px', fontWeight: 'bold' },
              backgroundColor: 'var(--chart-red)' 
            },
            { 
              values: [marketData.value.eth],
              valueBox: { text: 'ETH<br>(%v%)', decimals: 0, fontColor: 'var(--color-main)', fontSize: '14px', offsetR: '5px', fontWeight: 'bold' },
              backgroundColor: 'var(--chart-purple)' 
            },
            { 
              values: [marketData.value.top10],
              valueBox: { text: 'TOP10<br>(%v%)', decimals: 0, fontColor: 'var(--color-main)', fontSize: '14px', offsetR: '5px', fontWeight: 'bold' },
              backgroundColor: 'var(--chart-blue)' 
            },
            { 
              values: [marketData.value.alt],
              valueBox: { text: 'ALT<br>(%v%)', decimals: 0, fontColor: 'var(--color-main)', fontSize: '14px', offsetR: '5px', fontWeight: 'bold' },
              backgroundColor: 'var(--chart-green)' 
            },
            { 
              values: [marketData.value.tether],
              valueBox: { text: 'USDT<br>(%v%)', decimals: 0, fontColor: 'var(--color-main)', fontSize: '12px', offsetR: '1px', offsetY: '1px', placement: 'out', fontWeight: 'bold' },
              backgroundColor: 'var(--chart-yellow)' 
            }
          ],
        },
        width: "350px",
        height: "400px",
        events: {
          render: function() {
            document.getElementById(chartId.value).addEventListener('contextmenu', function(e) {
              e.preventDefault();
            });
          }
        }
      });
    };

    const initializeChart = () => {
      if (marketData.value && marketData.value.btc && marketData.value.eth && marketData.value.top10 && marketData.value.alt && marketData.value.tether) {
        renderChart();
      }
    };

    onMounted(async () => {
      await store.dispatch('dominance_chart/loadMarket');
      initializeChart();
    });

    onBeforeUnmount(() => {
      zingchart.exec(chartId.value, 'destroy');
    });

    watch(marketData, () => {
      initializeChart();
    });

    return {
      chartId,
      loadStateMarket
    };
  },
  computed: {
    ...mapState('dominance_chart', ['marketChart', 'loadStateMarket']),
  },
  methods: {
    ...mapActions('dominance_chart', ['loadMarket']),
    ...mapMutations('dominance_mini', ['changeMini']),
    openMini(chart) {
      this.changeMini(chart);
    }
  }
};
</script>

<style>
.dominance_chart {
  width: 100%;
  height: 100%;
}
</style>


