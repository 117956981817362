import DominanceView from "@/components/dominance/DominanceView.vue";
import ScoreView from "@/components/score/ScoreView.vue";
import PriceVolume from "@/components/analysis/PriceVolume.vue"
import LongShort from "@/components/signal/LongShort.vue"
import ClearingView from "@/components/clearing/ClearingView.vue"
import ChainView from "@/components/chain/ChainView.vue"
import TechnicalIndicator from "@/components/indicator/TechnicalIndicator.vue"
import AIBrief from "@/components/brief/AIBrief.vue"


const routes = [
  // 처음 페이지 접속시 로딩되는 페이지
  { path: "/", redirect: "/dominance" },
  // 도미넌스 페이지
  {
    path: "/dominance",
    name: "DominanceView",
    component: DominanceView,
    props: true,
  },
  // 스코어 페이지
  {
    path: "/score",
    name: "ScoreView",
    component: ScoreView,
    props: true,
  },
  // 브리핑 페이지
  {
    path: "/brief",
    name: "AIBrief",
    component: AIBrief,
    props: true,
  },
  // 거래량 & 가격 페이지
  {
    path: "/volume",
    name: "PriceVolume",
    component: PriceVolume,
    props: true,
  },
  // long & short 페이지
  {
    path: "/signal",
    name: "LongShort",
    component: LongShort,
    props: true,
  },
  // 청산 차트 페이지
  {
    path: "/clearing",
    name: "ClearingView",
    component: ClearingView,
    props: true,
  },
  // 체인 차트 페이지
  {
    path: "/indicator",
    name: "TechnicalIndicator",
    component: TechnicalIndicator,
    props: true,
  },
  // 체인 차트 페이지
  {
    path: "/chain",
    name: "ChainView",
    component: ChainView,
    props: true,
  },

];

export default routes;
