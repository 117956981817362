import api from "@/api/apiDominance";
// import { i18n } from "@/i18n"; 

export default {
  namespaced: true,
  state() {
    return {
      // 변수
      loadSig: false,
      warnModal: false,
      guideModal: false,
      lang: localStorage.getItem("lang") || "en",

      // 응답 리스트
      signal: {
        price: {
            name: "시세",
            long: 0,
            short: 0,
            upPer: 0,
            downPer: 0,
            noChange: 0
        },
        score: {
            name: "스코어",
            long: 0,
            short: 0,
            upPer: 50,
            downPer: 50,
            noChange: 0,
            noPer: 0,
        },
        rsi: {
            name: "RSI",
            long: 0,
            short: 0,
            upPer: 0,
            downPer: 0,
            noChange: 0
        },
      },

      coinList: []
  
      // 요청 리스트&변수

    };
  },
  mutations: {
    changeSigLoad(state, value) {
      state.loadSig = value;
    },
    changeWarn(state, value) {
      state.warnModal = value;
    },
    changeGuide(state, value) {
      state.guideModal = value;
    },
    changeLang(state, value) {
      state.lang = value;
    },
  }, 
  actions: {
    // 고야 시그널 조회 api
    async loadSignal(context) {
      try {
        const { signal } = context.state;
        const response = await api.getSignal();
        const { result: info } = response;
    
        const updateSignalData = (key, data) => {
          signal[key].long = data.long;
          signal[key].short = data.short;
          signal[key].upPer = ((data.long / info.total_count) * 100).toFixed(2);
          signal[key].downPer = ((data.short / info.total_count) * 100).toFixed(2);
        };
    
        updateSignalData('price', info.price_count);
        updateSignalData('rsi', info.rsi_count);

      } catch (error) {
        console.error("Error get dominance chart data:", error);
      }
    },

    // 코인 정보 조회
    async loadCoin(context, coin) {
      try {
        const response = await api.getCoin(coin);
        
        response.symbolType = response.symbol.slice(3);
        response.symbol = response.symbol.slice(0, 3);
        response.symbolImg = response.symbol.toLowerCase()

        response.lastPrice = Number(response.lastPrice).toLocaleString()
        response.priceChange = Number(response.priceChange)
        response.priceChangeTo = Number(response.priceChange).toLocaleString()
        response.priceChangePercent = Number(response.priceChangePercent).toFixed(2)

        context.state.coinList = response
      } catch (error) {
        console.error("Error get dominance chart data:", error);
      }
    },
    
    // 스코어 변화량 조회 api
    async loadScore(context) {
      try {
        const response = await api.getScore();
        let score = context.state.signal.score;
        
        score.long = response.up_count;
        score.short = response.down_count;
        score.noChange = response.nochange_count;

        score.upPer = (Number(response.up_count / response.total_count) * 100).toFixed(2);
        score.downPer = (Number(response.down_count / response.total_count) * 100).toFixed(2);
        score.noPer = (Number(response.nochange_count / response.total_count) * 100).toFixed(2);
        
        context.state.loadSig = true;
      } catch (error) {
        console.error("Error get goya score data", error);
      }
    },
    
  },
};
