import { createI18n } from 'vue-i18n'
import ko from './locales/ko.json'
import en from './locales/en.json'
import ja from './locales/ja.json'
import vi from './locales/vi.json'
import ru from './locales/ru.json'
import zh from './locales/zh.json'
import mn from './locales/mn.json'

const i18n = createI18n({
    fallbackLocale: "en", //보조
    locale: localStorage.getItem("localeLang") ?? "en",
    allowComposition: true,
    legacy: false,
    messages:{
        "en":en,
        "ko":ko,
        "ja":ja,
        "vi":vi,
        "ru":ru,
        "zh":zh,
        "mn":mn,
    }
})

export default i18n;