<template>
    <div style="width: 100%;">
        <div v-if="modal === true" class="coin-change-btn">
            <button @click="changeCoin('BTC')"><img src="@/assets/images/coin_logos/btc.png" /> BTC</button>
            <button @click="changeCoin('ETH')"><img src="@/assets/images/coin_logos/eth.png" /> ETH</button>
            <button @click="changeCoin('SOL')"><img src="@/assets/images/coin_logos/sol.png" /> SOL</button>
        </div>
        <div class="container btc" v-if="coinList">
            <div class="btc-wrapper">
                <div class="btc-info">
                    <img :src="getImageUrl(coinList.symbol)" @click="openModal()" />
                    <p>
                        {{ coinList.symbol }}
                        <br />
                        <span>/{{ coinList.symbolType }}</span>
                    </p>
                </div>
                <div class="btc-price-info">
                    <div class="btc-price-info-content">
                        <div class="btc-price">
                            <span>${{ coinList.lastPrice }}</span>
                            <div class="dominance_up_down_mobile">
                                <p class="btc-change-price add-image"
                                    :style="{ color: coinList.priceChange >= 0 ? 'var(--text-green)' : 'var(--text-red)' }">
                                    ${{ coinList.priceChangeTo }}
                                    <img
                                        :src="coinList.priceChange >= 0 ? require('@/assets/images/dominance_images/arrow_green_mini.png') : require('@/assets/images/dominance_images/arrow_red_mini.png')" />
                                </p>
                                <p class="btc-change-price add-image"
                                    :style="{ color: coinList.priceChangePercent >= 0 ? 'var(--text-green)' : 'var(--text-red)' }">
                                    {{ coinList.priceChangePercent }}%
                                    <img
                                        :src="coinList.priceChangePercent >= 0 ? require('@/assets/images/dominance_images/arrow_green_mini.png') : require('@/assets/images/dominance_images/arrow_red_mini.png')" />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="locked__content">
                    <div @click="goRetri" class="locked__content--wrapper">
                        <p>{{ $t('dominance.score') }}</p>
                        <div class="locked__content--item">
                            <img src="../../../assets/images/dominance_images/locked-item.png" alt="">
                        </div>
                    </div>
                    <div @click="goRetri" class="locked__content--wrapper">
                        <p>{{ $t('dominance.rsi') }}</p>
                        <div class="locked__content--item">
                            <img src="../../../assets/images/dominance_images/locked-item.png" alt="">
                        </div>
                    </div>
                    <div @click="goRetri" class="locked__content--wrapper">
                        <p>{{ $t('dominance.signal') }}</p>
                        <div class="locked__content--item">
                            <img src="../../../assets/images/dominance_images/locked-item.png" alt="">
                        </div>
                    </div>
                    <div @click="goRetri" class="locked__content--wrapper">
                        <p>{{ $t('dominance.trend') }}</p>
                        <div class="locked__content--item">
                            <img src="../../../assets/images/dominance_images/locked-item.png" alt="">
                        </div>
                    </div>
                    <div @click="goRetri" class="locked__content--wrapper">
                        <p>{{ $t('dominance.priceLiq') }}</p>
                        <div class="locked__content--item">
                            <img src="../../../assets/images/dominance_images/locked-item.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import arrowRedMini from '@/assets/images/dominance_images/arrow_red_mini.png';
import arrowGreenMini from '@/assets/images/dominance_images/arrow_green_mini.png'

export default {
    name: 'CoinInfoMobile',
    data() {
        return {
            modal: false,

            coinFixed: {
                score: {
                    name: "스코어",
                    value: "-49.10p",
                    color: "red",
                    image: arrowRedMini
                },
                rsi: {
                    name: "RSI",
                    value: "L",
                    color: "green",
                    image: arrowGreenMini

                },
                signal: {
                    name: "시그널",
                    value: "L3(61H)",
                    color: "green",
                    image: arrowGreenMini

                },
                trend: {
                    name: "트렌드",
                    value: "UP",
                    color: "green",
                    image: arrowGreenMini

                },
                price: {
                    name: "시세 위치",
                    value: "고야라인",
                    color: "green",
                    image: arrowGreenMini

                },
            },
        }
    },
    async created() {
        await this.loadCoin("BTC");
    },
    computed: {
        ...mapState("dominance", ["coinList"]),

    },
    methods: {
        ...mapMutations("dominance", ["changeWarn"]),
        ...mapActions("dominance", ["loadCoin"]),

        getImageUrl(symbol) {
            try {
                return require(`@/assets/images/coin_logos/${symbol.toLowerCase()}.png`);
            } catch {
                return require("@/assets/images/coin_logos/default_dark.png");
            }
        },

        openModal() {
            this.modal = true;
        },
        changeCoin(coin) {
            this.loadCoin(coin);
            this.modal = false;
            this.getImageUrl(coin)
        },
        warnOpen() {
            this.changeWarn(true);
        },
        goRetri() {
            window.open("https://www.tryex.io/m/")
        }
    }
}
</script>