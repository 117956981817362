import { API } from "./apiAuth";


// spot coin list 조회 api
const getSpot = async (page, size) => {
  try {
    const response = await API.get(`spot?page=${page}&page_size=${size}`);
    return response.data;
  } catch (error) {
    console.error("Error get spot: ", error);
    throw error;
  }
};

// futer coin list 조회 api
const getFuter = async (page, size) => {
    try {
      const response = await API.get(`futer?page=${page}&page_size=${size}`);
      return response.data;
    } catch (error) {
      console.error("Error get futer: ", error);
      throw error;
    }
};

// heat map data 조회 api
const getHeatMap = async (type) => {
  try {
    const response = await API.get(`hitmap?param=${type}`);
    return response.data;
  } catch (error) {
    console.error("Error get heatmap: ", error);
    throw error;
  }
};

export default {
    getSpot,
    getFuter,
    getHeatMap
};
