import api from "@/api/apiChain";

export default {
    namespaced: true,
    state() {
        return {
            // Variables

            // Response data
            chainData: {
                netflow: [],
                close: [],
                datetime: []
            },
        };
    },
    mutations: {
        setChainData(state, data) {
            state.chainData = data;
        },
    },
    actions: {
        // Fetch ratio chart data API
        async loadChain(context) {
            try {
                let response = await api.getChain();

                if (response && response.results) {
                    let chainData = {
                        netflow: [],
                        close: [],
                        datetime: []
                    };

                    for (let data of response.results) {
                        chainData.netflow.push(parseFloat(data.netflow));
                        chainData.close.push(parseFloat(data.close));
                        chainData.datetime.push(new Date(data.datetime * 1000).getTime());
                    }

                    context.commit('setChainData', chainData);
                }
            } catch (error) {
                console.error("Error fetching chain chart data:", error);
            }
        },
    },
};
