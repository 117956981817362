import api from "@/api/apiDominance";
import { updateData } from "@/utils/changeValue";

export default {
    namespaced: true,
    state() {
      return {
        // 변수
        miniModal: 0,
        loadMini: false,
        loadDomi: false,
  
        // 응답 리스트
        goyaChartMini: [],
        marketChartMini: []

  
        // 요청 리스트&변수
  
      };
    },
    mutations: {
      changeMini(state, value) {
          state.miniModal = value;
      },
      changeType(state, value) {
        state.typeCoin = value;
      }, 
      changeLoad(state, value) {
        state.loadMini = value;
      },
      changeDomi(state, value) {
        state.loadDomi = value;
      },

    
    }, 
    actions: {
      // 고야 미니 차트 조회 api
      async loadDominanceMini(context) {
        try{
          let response = await api.getDominance(50);
          updateData(response.result)
          context.state.goyaChartMini = response.result
          context.state.loadDomi = true;
        } catch (error) {
          console.error("Error get dominance chart data :", error);
          return;
        }
      },
      // 마켓 미니 차트 조회 api
      async loadMarketMini(context) {
        try{
          let response = await api.getMarketMini();
          let info = response.result

          updateData(info.btc)
          updateData(info.eth)
          updateData(info.tether)
          updateData(info.alt)
          updateData(info.top10)

          context.state.goyaChartMini = info
          context.state.loadMini = true;
        } catch (error) {
          console.error("Error get market mini chart data :", error);
          return;
        }
      },
    },
  };
  