<template>
    <div :id="chartId" style="background: var(--background-chart);"></div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, computed, watch, nextTick, getCurrentInstance } from 'vue';
import zingchart from 'zingchart/es6';
import { useStore } from 'vuex';

export default {
    name: 'ChainChart',
    setup() {
        const { proxy } = getCurrentInstance();
        const chartId = ref('zingchart-' + Math.random().toString(36).substr(2, 9));

        const store = useStore();
        const chainData = computed(() => store.state.chain.chainData);
        const language = computed(() => store.state.dominance.lang);

        const translatedText = computed(() => {
            return {
                netflow: proxy.$t('chain.netflow'),
                btcPrice: proxy.$t('pub.btcPrice'),
                date: proxy.$t('chain.date'),
            };
        });


        const chartData = ref({
            type: 'line',
            globals: {
                fontFamily: "var(--main-font-Light)",
                fontColor: "var(--color-main)"
            },
            gui: {
                contextMenu: {
                    empty: false
                }
            },
            backgroundColor: "transparent",
            'scale-x': {
                // label: { text: 'Datetime' },
                transform: {
                    type: 'date',
                    all: '%y.%m.%d '
                },
                guide: {
                    visible: false
                },
                item: {
                    visible: true
                },
                zooming: true,
                values: []
            },
            'scale-y': {
                // label: { text: 'Bitcoin Price' },
                guide: {
                    visible: true
                },
                item: {
                    visible: true
                },
                format: "$%v",
                step: 10000, // Ensure uniform step
                tick: {
                    visible: true,
                    placement: 'default',
                },
                "thousands-separator": ","
            },
            'scale-y-2': {
                // label: { text: 'Netflow (K)' },
                guide: {
                    visible: true
                },
                item: {
                    visible: true
                },
                format: "%vK",
                step: 1, // Ensure uniform step
                tick: {
                    visible: true,
                    placement: 'default',
                },
                "thousands-separator": ","
            },
            plot: {
                tooltip: {
                    'backgroundColor': "var(--background-chart2)",
                },
            },
            legend: {
                fontFamily: "Noto Sans JP",
                layout: 'x2',
                backgroundColor: 'transparent',
                borderColor: 'none',
                overflow: 'page',
                alpha: 0.1,
                shadow: false,
                align: 'center',
                marker: {
                    'border-color': 'none',
                    width: '20px',
                    height: '3px'
                },
                item: {
                fontColor: '#FFFFFF',
                },
                'offset-y': 15,
            },
            series: [
                {
                    values: [],
                    text: translatedText.value.netflow,
                    'line-color': 'var(--chart-skyblue)',
                    scales: 'scale-x,scale-y-2',
                    marker: {
                        visible: false
                    },
                    aspect: 'spline',
                    tooltip: {
                        text: `${translatedText.value.date}: %kt<br>${translatedText.value.netflow}: %vK`, // Corrected tooltip
                        "thousands-separator": ","
                    }
                },
                {
                    values: [],
                    text: translatedText.value.btcPrice,
                    'line-color': 'var(--text-red)',
                    marker: {
                        visible: false
                    },
                    aspect: 'spline',
                    tooltip: {
                        text: `${translatedText.value.date}: %kt<br>${translatedText.value.btcPrice}: $%v`, // Corrected tooltip
                        "thousands-separator": ","
                    }
                },
            ],
           
            // labels: [
            //     {
            //         text: translatedText.value.netflow,
            //         'default-value': true,
            //         x: '40%',
            //         y: '1%',
            //         'background-color': 'var(--chart-skyblue)',
            //         'font-color': 'var(--color-main)',
            //         'border-radius': '5px',
            //         padding: '5px 10px'
            //     },
            //     {
            //         text: translatedText.value.btcPrice,
            //         'default-value': true,
            //         x: '55%',
            //         y: '1%',
            //         'background-color': 'var(--text-red)',
            //         'font-color': 'var(--color-main)',
            //         'border-radius': '5px',
            //         padding: '5px 10px'
            //     }
            // ]
        });

        const renderChart = async () => {
            await nextTick();
            zingchart.render({
                id: chartId.value,
                data: chartData.value,
                height: '600px',
                width: '100%',
            });
        };
        
        const fontLang = () => {
            if (language.value === 'ja') {
            return "Noto Sans JP"
            } else if (language.value === 'zh') {
            return "Noto Sans SC"
            } else {
            return "var(--main-font-Light)"
            }
        }

        const removeBtn = () => {
            nextTick(() => {
                const toolBar = document.querySelector(`#${chartId.value}-trigger-c`);
                if (toolBar) {
                    toolBar.style.display = 'none'; // 요소 숨기기
                }
            });
        }

        const roundToThousands = (value) => {
            return Math.round(value / 1000) * 1000;
        };

        const updateChartData = (newData) => {
            if (!newData || !newData.netflow || !newData.close || !newData.datetime) {
                console.error('No valid data available to update the chart.');
                return;
            }

            const netflowValues = newData.netflow.slice().reverse();
            const closeValues = newData.close.slice().reverse();
            const datetimeValues = newData.datetime.slice().reverse();

            // Synchronize the data points
            const syncedData = [];
            const length = Math.min(netflowValues.length, closeValues.length, datetimeValues.length);

            for (let i = 0; i < length; i++) {
                syncedData.push({
                    datetime: datetimeValues[i],
                    netflow: netflowValues[i],
                    close: closeValues[i]
                });
            }

            const syncedDatetimeValues = syncedData.map(data => data.datetime);
            const syncedNetflowValues = syncedData.map(data => data.netflow);
            const syncedCloseValues = syncedData.map(data => data.close);

            const minCloseValue = roundToThousands(Math.min(...syncedCloseValues) - 5000);
            const maxCloseValue = roundToThousands(Math.max(...syncedCloseValues) + 5000);

            const minNetflowValue = roundToThousands(Math.min(...syncedNetflowValues));
            const maxNetflowValue = roundToThousands(Math.max(...syncedNetflowValues));

            chartData.value = {
                ...chartData.value,
                'globals': {
                    fontFamily: fontLang(),
                    fontSize: '15px',
                    fontColor: "var(--color-main)",
                },
                'scale-x': {
                    ...chartData.value['scale-x'],
                    values: syncedDatetimeValues,
                },
                'scale-y': {
                    ...chartData.value['scale-y'],
                    minValue: minCloseValue,
                    maxValue: maxCloseValue,
                    step: (maxCloseValue - minCloseValue) / 8, // Adjust step to ensure 8 intervals
                },
                'scale-y-2': {
                    ...chartData.value['scale-y-2'],
                    minValue: minNetflowValue,
                    maxValue: maxNetflowValue,
                    step: (maxNetflowValue - minNetflowValue) / 8, // Adjust step to ensure 8 intervals
                },
                series: [
                    { ...chartData.value.series[0], values: syncedNetflowValues },
                    { ...chartData.value.series[1], values: syncedCloseValues },
                ],
            };

            renderChart();
        };

        const loadAndUpdateChart = async () => {
            await store.dispatch('chain/loadChain');
            if (chainData.value) {
                updateChartData(chainData.value);
            }
            removeBtn();
        };

        onMounted(async () => {
            await loadAndUpdateChart();
        });

        onBeforeUnmount(() => {
            zingchart.exec(chartId.value, 'destroy');
        });

        watch(chainData, async (newData) => {
            if (newData) {
                updateChartData(newData);
            }
        });

        watch(translatedText, () => {
            chartData.value.series[0].text = translatedText.value.netflow;
            chartData.value.series[0].tooltip.text = `${translatedText.value.date}: %kt<br>${translatedText.value.netflow}: %vK`;

            chartData.value.series[1].text = translatedText.value.btcPrice;
            chartData.value.series[1].tooltip.text = `${translatedText.value.date}: %kt<br>${translatedText.value.btcPrice}: $%v`;

            chartData.value['scale-y'].label.text = translatedText.value.btcPrice;
            chartData.value['scale-y-2'].label.text = translatedText.value.netflow;

            chartData.value.labels[0].text = translatedText.value.netflow;
            chartData.value.labels[1].text = translatedText.value.btcPrice;

            renderChart();
            removeBtn();
        });

        return {
            chartId,
        };
    }
};
</script>
