<template>
    <!-- futer coin list -->
    <div v-if="coinTab === 0" class="coin-list-wrapper">
        <div class="coin-list-header">
            <div class="coin-list-header-item" @click="sortList('rank')">
                {{ $t('analysis.rank') }}
                <img :src="getArrowImage('rank')" />
            </div>
            <div class="coin-list-header-item" @click="sortList('symbol')">
                {{ $t('analysis.coin') }}
                <img :src="getArrowImage('symbol')" />
            </div>
            <div class="coin-list-header-item" @click="sortList('priceVal')">
                {{ $t('analysis.price') }}
                <img :src="getArrowImage('priceVal')" />
            </div>
            <div class="coin-list-header-item" @click="sortList('priceChangePercent24h')">
                {{ $t('analysis.24price') }}
                <img :src="getArrowImage('priceChangePercent24h')" />
            </div>
            <div class="coin-list-header-item" @click="sortList('volumeVal')">
                {{ $t('analysis.24vol') }}
                <img :src="getArrowImage('volumeVal')" />
            </div>
            <div class="coin-list-header-item" @click="sortList('volumeChangePercent24h')">
                {{ $t('analysis.24change') }}
                <img :src="getArrowImage('volumeChangePercent24h')" />
            </div>
            <div class="coin-list-header-item" @click="sortList('marketCapVal')">
                {{ $t('analysis.marketCap') }}
                <img :src="getArrowImage('marketCapVal')" />
            </div>
            <div class="coin-list-header-item" @click="sortList('liqVal')">
                {{ $t('analysis.liq') }}
                <img :src="getArrowImage('liqVal')" />
            </div>
        </div>
        <div v-if="loadList">
            <div class="coin-content-wrapper" v-if="filteredCoinList.length > 0">
                <div class="coin-list-content" v-for="coin in filteredCoinList" :key="coin">
                    <div class="ranking">{{ coin.rank }}</div>
                    <div class="coin">
                        <img :src="getCoinIcon(coin.symbol)" alt="" class="coin-icon">
                        {{ coin.symbol }}/
                        <span>USDT</span>
                    </div>
                    <div v-if="!coin.price.includes(0.0)">$ {{ coin.price }}</div>
                    <div v-else>$ {{ coin.priceVal }}</div>
                    <div
                        :class="{ 'positive': coin.priceChangePercent24h > 0, 'negative': coin.priceChangePercent24h < 0 }">
                        <span v-if="coin.priceChangePercent24h > 0">+</span>{{ coin.priceChangePercent24h }}%</div>
                    <div>$ {{ coin.volume }}</div>
                    <div
                        :class="{ 'positive': coin.volumeChangePercent24h > 0, 'negative': coin.volumeChangePercent24h < 0 }">
                        <span v-if="coin.volumeChangePercent24h > 0">+</span>{{ coin.volumeChangePercent24h }}%</div>
                    <div>$ {{ coin.marketCap }}</div>
                    <div>$ {{ coin.liquidationUsd24h }}</div>
                </div>
            </div>
            <div class="coin-content-wrapper" v-if="filteredCoinList.length === 0">
                <div class="coin-no-data">{{ $t('brief.noData') }}</div>
            </div>
        </div>
        <div class="loading_spinner" v-else>
            <div class="loading_circle"></div>
        </div>
    </div>


    <!-- spot coin list -->
    <div v-if="coinTab === 1" class="coin-list-wrapper">
        <div class="coin-list-header-spot">
            <div class="coin-list-header-item" @click="sortList('rank')">
                {{ $t('analysis.rank') }}
                <img :src="getArrowImage('rank')" />
            </div>
            <div class="coin-list-header-item" @click="sortList('symbol')">
                {{ $t('analysis.coin') }}
                <img :src="getArrowImage('symbol')" />
            </div>
            <div class="coin-list-header-item" @click="sortList('priceVal')">
                {{ $t('analysis.price') }}
                <img :src="getArrowImage('priceVal')" />
            </div>
            <div class="coin-list-header-item" @click="sortList('priceChangePercent1h')">
                {{ $t('analysis.1price') }}
                <img :src="getArrowImage('priceChangePercent1h')" />
            </div>
            <div class="coin-list-header-item" @click="sortList('priceChangePercent24h')">
                {{ $t('analysis.24price') }}
                <img :src="getArrowImage('priceChangePercent24h')" />
            </div>
            <div class="coin-list-header-item" @click="sortList('priceChangePercent1w')">
                {{ $t('analysis.7price') }}
                <img :src="getArrowImage('priceChangePercent1w')" />
            </div>
            <div class="coin-list-header-item" @click="sortList('volumeVal')">
                {{ $t('analysis.24vol') }}
                <img :src="getArrowImage('volumeVal')" />
            </div>
            <div class="coin-list-header-item" @click="sortList('volumeChangePercent24h')">
                {{ $t('analysis.24change') }}
                <img :src="getArrowImage('volumeChangePercent24h')" />
            </div>
            <div class="coin-list-header-item" @click="sortList('marketCapVal')">
                {{ $t('analysis.marketCap') }}
                <img :src="getArrowImage('marketCapVal')" />
            </div>
        </div>
        <div class="coin-content-wrapper">
            <div class="coin-list-content-spot" v-for="coin in filteredCoinList" :key="coin">
                <div class="ranking">{{ coin.rank }}</div>
                <div class="coin">
                    <img :src="getCoinIcon(coin.symbol)" alt="" class="coin-icon">
                    {{ coin.symbol }}/
                    <span>USDT</span>
                </div>
                <div  v-if="!coin.price.includes(0.0)">$ {{ coin.price }}</div>
                <div  v-else>$ {{ coin.priceVal }}</div>
                <div :class="{ 'positive': coin.priceChangePercent1h > 0, 'negative': coin.priceChangePercent1h < 0 }">
                    {{ coin.priceChangePercent1h }}%</div>
                <div
                    :class="{ 'positive': coin.priceChangePercent24h > 0, 'negative': coin.priceChangePercent24h < 0 }">
                    {{ coin.priceChangePercent24h }}%</div>
                <div :class="{ 'positive': coin.priceChangePercent1w > 0, 'negative': coin.priceChangePercent1w < 0 }">
                    {{ coin.priceChangePercent1w }}%</div>
                <div>$ {{ coin.volume24h }}</div>
                <div
                    :class="{ 'positive': coin.volumeChangePercent24h > 0, 'negative': coin.volumeChangePercent24h < 0 }">
                    {{ coin.volumeChangePercent24h }}%</div>
                <div>$ {{ coin.marketCap }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
    name: "CoinList",
    props: {
        searchCoin: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            sortKey: null,  // 현재 정렬된 키
            sortOrder: 1,   // 정렬 순서 (1: 오름차순, -1: 내림차순)
            activeSortKey: null // 현재 활성화된 정렬 키
        };
    },
    computed: {
        ...mapState("analysis", ["coinList", "coinTab", "loadList"]),

        filteredCoinList() {
            let sortedList = this.sortedCoinList;
            if (this.searchCoin) {
                sortedList = sortedList.filter(coin =>
                    coin.symbol.toLowerCase().includes(this.searchCoin.toLowerCase())
                );
            }
            return sortedList;
        },

        sortedCoinList() {
            let sortedList = [...this.coinList];

            if (this.sortKey) {
                sortedList.sort((a, b) => {
                    let aValue = a[this.sortKey];
                    let bValue = b[this.sortKey];

                    if (!isNaN(aValue) && !isNaN(bValue)) {
                        aValue = parseFloat(aValue);
                        bValue = parseFloat(bValue);
                    }

                    if (aValue < bValue) {
                        return -1 * this.sortOrder;
                    }
                    if (aValue > bValue) {
                        return 1 * this.sortOrder;
                    }
                    return 0;
                });
            }

            return sortedList;
        }
    },
    methods: {
        ...mapActions("analysis", ["loadSpotCoin", "loadFuterCoin"]),

        getCoinIcon(symbol) {
            try {
                return require(`@/assets/images/coin_logos/${symbol.toLowerCase()}.png`);
            } catch {
                return require("@/assets/images/coin_logos/default_dark.png");
            }
        },

        getArrowImage(key) {
            if (this.sortKey === key) {
                return this.sortOrder === 1
                    ? require('@/assets/images/dominance_images/arrow_down.png')
                    : require('@/assets/images/dominance_images/arrow_up.png');
            } else {
                return require('@/assets/images/dominance_images/arrow_down.png');
            }
        },

        sortList(key) {
            if (this.sortKey === key) {
                this.sortOrder *= -1;
            } else {
                this.sortKey = key;
                this.sortOrder = -1;
            }
            this.activeSortKey = key;
        }
    },
    watch: {
        coinTab(val) {
            if (val === 0) {
                this.loadFuterCoin();
            } else if (val === 1) {
                this.loadSpotCoin();
            }
        }
    }
}
</script>
