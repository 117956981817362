<template>
  <div class="mainWrap list">
    <div class="subCont whiteDoc">
      <TitleView />
      <div class="wrapper">
        <div class="indicator-wrapper">
          <!-- rsi 차트 -->
          <div class="bubble-chart-wrapper">
            <div class="bubble-chart-title">
              <div id="screen__pc">
                <div class="sub-title">
                  <img src="@/assets/images/dominance_images/logo.png" class="logo__img"/>
                  <h2>
                    {{ $t('indi.bubble') }}
                  </h2>
                  <img class="guide_img" @click="openGuide(1)"
                      src="@/assets/images/dominance_images/chart_desc_icon.png" />
                </div>
              </div>
              <div id="screen__mobile">
                <div class="sub-title-bubble">
                  <img src="@/assets/images/dominance_images/logo.png" class="logo__img"/>
                  <h2>
                    {{ $t('indi.bubble') }}
                  </h2>
                  <img class="guide_img_mobile_bubble" @click="openGuide(1)"
                      src="@/assets/images/dominance_images/chart_desc_icon.png" />
                </div>
              </div>
              <select id="screen__pc" v-model="coinTime">
                <option value="1">1h</option>
                <option value="2">4h</option>
                <option value="3">12h</option>
                <option value="4">24h</option>
              </select>
            </div>
            <div id="screen__pc" class="bubble-chart">
              <IndicatorChart />
            </div>
            <div id="screen__mobile" class="bubble-chart" style="align-items: center; justify-content: center;">
              <h2 style="margin: 10px;">
                {{ $t('indi.noMobile') }}
                <br>
                {{ $t('indi.fullScreen') }}
              </h2>
              <!-- <div class="select-date">
                <select v-model="coinTime">
                  <option value="1">1h</option>
                  <option value="2">4h</option>
                  <option value="3">12h</option>
                  <option value="4">24h</option>
                </select>
              </div> -->
              <!-- <IndicatorChartMobile /> -->
            </div>
          </div>
          <!-- rsi 표 -->
          <div id="screen__pc" class="rsi-graph-wrapper">
            <div class="rsi-search-wrapper">
              <div class="rsi-search-container">
                <input type="text" :placeholder="$t('pub.searchCoin')" class="search-input" v-model="searchCoin">
                <button class="rsi-search-button">
                  <img src="@/assets/images/dominance_images/search_icon.png" alt="Search">
                </button>
              </div>
            </div>
            <RsiGraph :searchCoin="searchCoin" />
          </div>

          <div id="screen__mobile" class="rsi-graph-wrapper">
            <div class="rsi-search-wrapper">
              <div class="search-container" style="margin-bottom: 10px;">
                <input type="text" :placeholder="$t('pub.searchCoin')" class="search-input" v-model="searchCoin">
                <button class="rsi-search-button">
                  <img src="@/assets/images/dominance_images/search_icon.png" alt="Search">
                </button>
              </div>
            </div>
            <RSIGraphMobile :searchCoin="searchCoin" />
          </div>
          <!-- rainbow 차트 -->
          <div id="screen__pc" >
            <div class="rainbow_wrapper">
              <div class="rsi-chart-wrapper" style="width: 100%;">
                <div class="sub-title">
                  <img src="@/assets/images/dominance_images/logo.png" class="logo__img"/>
                  <h2>
                    {{ $t('indi.colorChart') }}
                  </h2>
                    <img class="guide_img" @click="openGuide(2)" src="@/assets/images/dominance_images/chart_desc_icon.png" />
                </div>
              </div>
              <RainbowChart class="rsi-chart" />
            </div>
          </div>

          <div id="screen__mobile">
            <div class="rainbow_wrapper">
              <div class="rsi-chart-wrapper">
                <div class="sub-title-bubble">
                  <img src="@/assets/images/dominance_images/logo.png" class="logo__img"/>
                  <h2>
                    {{ $t('indi.colorChart') }}
                  </h2>
                  <img class="guide_img_mobile_bubble" @click="openGuide(2)" src="@/assets/images/dominance_images/chart_desc_icon.png" />
                </div>
              </div>
              <RainbowChartMobile class="rsi-chart" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <GuideModal v-if="guideModal" :type="indiType" />




</template>

<script>
import "@/assets/css/indicator.css"
import TitleView from "@/components/public/TitleView.vue"
import IndicatorChart from "@/components/indicator/chart/IndicatorChart.vue"
// import IndicatorChartMobile from "./chart/IndicatorChartMobile.vue"
import RainbowChart from "@/components/indicator/chart/RainbowChart.vue"
import RainbowChartMobile from "./chart/RainbowChartMobile.vue"
import RsiGraph from "@/components/indicator/sub/RsiGraph.vue"
import GuideModal from "@/components/public/GuideModal.vue"
import RSIGraphMobile from "./sub/RSIGraphMobile.vue"

import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: 'ClearingView',
  data() {
    return {
      coinTime: '1',
      searchCoin: "",
      indiType: 1,
    }
  },
  components: {
    TitleView,
    IndicatorChart,
    // IndicatorChartMobile,
    RainbowChart,
    RainbowChartMobile,
    RsiGraph,
    GuideModal,
    RSIGraphMobile

  },
  computed: {
    ...mapState("dominance", ["guideModal"]),
  },
  methods: {
    ...mapMutations("dominance", ["changeGuide"]),
    ...mapMutations("indicator", ["changeTime"]),
    ...mapActions("indicator", ["loadRsiData", "loadRainbow"]),

    openGuide(type) {
      this.indiType = type;
      this.changeGuide(true);
    },

  },
  watch: {
    coinTime(newVal) {
      this.changeTime(newVal)
    }
  }
}
</script>
