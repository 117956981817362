<template>
    <div class="mainWrap list">
        <div class="subCont whiteDoc">
            <TitleView />
            <div class="score_wrapper">
                <h2>{{ $t('score.goyaMent') }}</h2>
                <div id="screen__pc" @click="goRetri" class="score-img"></div>
                <div class="score_img_wrapper">
                    <img id="screen__mobile" @click="goRetriM" src="@/assets/images/dominance_images/score_page_mobile.png" alt="Score Image">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TitleView from "@/components/public/TitleView.vue";

export default {
    name: 'ScoreView',
    components: {
        TitleView,
    },
    methods: {
        goRetri() {
            window.open("https://www.tryex.io/index.asp");
        },
        goRetriM() {
            window.open("https://www.tryex.io/m/");
        }
    }
};
</script>

<!-- Preload the image in the head section -->
<head>
  <link rel="preload" href="@/assets/images/dominance_images/score_page.png" as="image">
  <link rel="preload" href="@/assets/images/dominance_images/score_page_mobile.png" as="image">
</head>
