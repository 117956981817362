<template>
    <GuideModal :type="type" v-if="guideModal" />
    <div class="mainWrap list">
        <div class="subCont whiteDoc">
            <TitleView />
            <div class="analysis-btn-container">
                <button @click="coinChange(0)" class="analysis-button"
                    :class="{ selectType: coinTab === 0 }">{{ $t('analysis.futures') }}</button>
                <button @click="coinChange(1)" class="analysis-button"
                    :class="{ selectType: coinTab === 1 }">{{ $t('analysis.spot') }}</button>
            </div>
            <div class="analysis-wrapper">
                <div class="analysis-coin-title">
                    <div class="analysis-coin-title">
                        <div class="analysis-search">
                            <div id="screen__pc" >
                                <div class="sub-title">
                                    <img src="@/assets/images/dominance_images/logo.png" class="logo__img"/>
                                    <h2>{{ $t('analysis.coinData') }}</h2>
                                    <img class="guide_img" @click="openGuide(1)"
                                        src="@/assets/images/dominance_images/chart_desc_icon.png" />
                                </div>
                            </div>
                            <div id="screen__mobile">
                                <div class="analysis-sub">
                                    <div class="sub-title">
                                        <img src="@/assets/images/dominance_images/logo.png" class="logo__img"/>
                                        <h2>{{ $t('analysis.coinData') }}</h2>
                                        <img class="guide_img_mobile" @click="openGuide(1)"
                                            src="@/assets/images/dominance_images/chart_desc_icon.png" />
                                    </div>
                                </div>
                            </div>
                            <div class="search-container">
                                <input type="text" :placeholder="$t('pub.searchCoin')" class="search-input"
                                    v-model="searchCoin">
                                <button class="search-button">
                                    <img src="@/assets/images/dominance_images/search_icon.png" alt="Search">
                                </button>
                            </div>
                        </div>
                        <div id="screen__pc" class="analysis-container">
                            <CoinList :searchCoin="searchCoin" />
                        </div>
                        <div id="screen__mobile" class="analysis-container">
                            <CoinListMobile :searchCoin="searchCoin" />
                        </div>
                    </div>
                    <div class="heatmap-container-wrapper">
                        <div class="current-coin-title">
                            <div id="screen__pc">
                                <div class="sub-title">
                                    <img src="@/assets/images/dominance_images/logo.png" class="logo__img"/>
                                    <h2>
                                        {{ $t('analysis.heatMap') }}
                                    </h2>
                                    <img class="guide_img" @click="openGuide(2)"
                                            src="@/assets/images/dominance_images/chart_desc_icon.png" />
                                </div>
                            </div>
                            <div id="screen__mobile">
                                <div class="sub-title">
                                    <img src="@/assets/images/dominance_images/logo.png" class="logo__img"/>
                                    <h2>
                                        {{ $t('analysis.heatMap') }}
                                    </h2>
                                    <img class="guide_img_mobile" @click="openGuide(2)"
                                            src="@/assets/images/dominance_images/chart_desc_icon.png" />
                                </div>
                            </div>
                        </div>
                        <div class="heatmap-container">
                            <div class="button-group">
                                <input type="radio" id="volume" name="filter" checked @click="typeChange('volume')">
                                <label for="volume">{{ $t('analysis.volume') }}</label>
                                <input type="radio" id="variation" name="filter" @click="typeChange('change')">
                                <label for="variation">{{ $t('analysis.variation') }}</label>
                                <input type="radio" id="liquidation" name="filter" @click="typeChange('liq')">
                                <label for="liquidation">{{ $t('analysis.liquidation') }}</label>
                            </div>
                            <div id="screen__pc" >
                                <div class="heatmap-content">
                                    <HeatMap />
                                </div>
                            </div>
                            <div id="screen__mobile" >
                                <div  class="heatmap-content">
                                    <HeatMapMobile />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import "@/assets/css/analysis.css";
import TitleView from "@/components/public/TitleView.vue";
import HeatMap from "@/components/analysis/chart/HeatMap.vue";
import CoinList from "@/components/analysis/sub/CoinList.vue";
import GuideModal from "@/components/public/GuideModal.vue"
import CoinListMobile from "./sub/CoinListMobile.vue";
import HeatMapMobile from "./chart/HeatMapMobile.vue";

import { mapMutations, mapState, mapActions } from 'vuex';

export default {
    name: 'PriceVolume',
    data() {
        return {
            searchCoin: "",
            type: 2,
        };
    },
    components: {
        TitleView,
        HeatMap,
        CoinList,
        GuideModal,
        CoinListMobile,
        HeatMapMobile,
    },
    async created() {
        await this.loadFuterCoin();
    },
    computed: {
        ...mapState("analysis", ["coinList", "coinTab"]),
        ...mapState("dominance", ["guideModal"]),
    },
    methods: {
        ...mapMutations("dominance", ["changeGuide"]),
        ...mapMutations("analysis", ["changeList", "changeType", "changeLoad"]),
        ...mapActions("analysis", ["loadFuterCoin"]),

        coinChange(type) {
            this.changeList(type);
        },
        typeChange(type) {
            this.changeType(type);
        },
        openGuide(type) {
            this.type = type;
            this.changeGuide(true);
        },
    },
};
</script>
