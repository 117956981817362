<template>
    <div class="modal_overlay">
        <div class="goya_dominance_chart_wrapper goya">
            <div class="loading_spinner" v-if="!loadDomi">
                <div class="loading_circle"></div>
            </div>
            <div id="goya_dominance_chart" v-else>
                <img @click="closeMini" src="@/assets/images/dominance_images/close_icon.png" class="market cancel_goya" width="30px;"/>
                <div class="goya_dominance_chart_content">
                    <img src="@/assets/images/dominance_images/chart-label.png" />
                    <p>{{ $t('dominance.goyaDominance') }}</p>
                </div>
                <div ref="goya_mini"></div>
            </div>
        </div>
</div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { createChart } from 'lightweight-charts';
import { getTimeZone } from "@/utils/localeTime";

export default {
    name: "GoyaMini",
    data() {
        return {
            localTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            initialValue: 100,
        };
    },
    computed: {
        ...mapState("dominance_mini", ["goyaChartMini", "loadDomi"]),

        chartOptions() {
            return {
                height: 500,
                width: this.$refs.goya_mini ? this.$refs.goya_mini.offsetWidth : 0,
                layout: {
                    background: { color: 'transparent' },
                    textColor: '#fff',
                },
                grid: {
                    vertLines: {
                        color: 'transparent',
                    },
                    horzLines: {
                        color: 'transparent',
                    },
                },
                leftPriceScale: {
                    autoScale: true,
                    scaleMargins: {
                        top: 0.2,
                        bottom: 0.08,
                    },
                    visible: true,
                },
                priceRange: {
                    minValue: 0,
                    maxValue: 80,
                },
                timeScale: {
                    timeVisible: true,
                    secondsVisible: false,
                    tickMarkFormatter(time) {
                       // time을 Date 객체로 변환
                        var convertTime = new Date(time*1000);
                        let lang = localStorage.getItem("lang");
                        let regionCode

                        if (lang === 'ko') {
                            regionCode = 'KR'
                            } else if (lang === 'ja') {
                            regionCode = 'JP'
                            } else if (lang === 'zh') {
                            regionCode = 'CN'
                            } else {
                            regionCode = 'US'
                            }

                        const localTimezone = getTimeZone(regionCode);

                        let optionsT = {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute:"2-digit",
                            hour12: false,
                            timeZone: localTimezone,
                        };

                        let localTime = convertTime.toLocaleString('en-US', optionsT).replace(',', ''); // 쉼표(,) 제거
                        localTime = localTime.replace(/\//g, '-');
                        return localTime;
                    },
                },
                localization:{
                    timeFormatter(originalTime) {
                        let lang = localStorage.getItem("lang");
                        let regionCode

                        if (lang === 'ko') {
                            regionCode = 'KR'
                            } else if (lang === 'ja') {
                            regionCode = 'JP'
                            } else if (lang === 'zh') {
                            regionCode = 'CN'
                            } else {
                            regionCode = 'US'
                            }

                        let timeZone = getTimeZone(regionCode)
                        const zonedDate = new Date(new Date(originalTime * 1000).toLocaleString('en-US', { timeZone:timeZone }));

                        zonedDate.getTime() / 1000;
                        const year = zonedDate.getFullYear();
                        const month = (zonedDate.getMonth() + 1).toString().padStart(2, '0'); // 월은 0부터 시작하므로 +1 필요, 두 자리로 패딩
                        const day = zonedDate.getDate().toString().padStart(2, '0'); // 날짜, 두 자리로 패딩
                        const hours = zonedDate.getHours().toString().padStart(2, '0'); // 시간, 두 자리로 패딩
                        const minutes = zonedDate.getMinutes().toString().padStart(2, '0'); // 분, 두 자리로 패딩
                        let formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;
                        return formattedDateTime;
                    }
                },
                crosshair: {
                    mode: 0,
                },
                rightPriceScale: {
                    visible: false,
                }
            };
        },
        lineOptions() {
            return {
                priceScaleId: 'left',
                priceFormat: {
                    type: 'price',
                    precision: 2,
                    minMove: 0.01,
                },
                color: '#fff',
                lastValueVisible: false,
                pointMarkersVisible: true,
                lineWidth: 1,
                priceLineVisible: false,
            };
        },
    },
    async mounted() {
        const chart = createChart(this.$refs.goya_mini, this.chartOptions);

        const lineSeries = chart.addLineSeries(this.lineOptions);
        lineSeries.setData(this.goyaChartMini);

        this.setChartVisibleRange(chart);
        await this.removeLogoLink()
    },
    methods: {
        ...mapMutations("dominance_mini", ["changeMini", "changeDomi"]),
        
        closeMini() {
            this.changeMini(0);
        },

        setChartVisibleRange(chart) {
            if (this.goyaChartMini.length === 0) return;
            
            const firstTime = this.goyaChartMini[0].time;
            const lastTime = this.goyaChartMini[this.goyaChartMini.length - 1].time;

            chart.timeScale().setVisibleRange({ from: firstTime, to: lastTime });
        },

        async removeLogoLink() {
            const chartElement = this.$refs.goya_mini;
            if (chartElement) {
                const logoLink = chartElement.querySelector('a#tv-attr-logo');
                if (logoLink) {
                    logoLink.remove();
                }
            }
        },
    },
    beforeUnmount() {
        if (this.chart) {
            this.chart.remove();
        }
    },
}
</script>
