<template>
    <div class="mainWrap list">
        <div class="subCont whiteDoc">
            <TitleView />
            <div class="wrapper">
                <div class="ratio-wrapper">
                    <div id="screen__pc">
                        <div class="sub-title">
                            <img src="@/assets/images/dominance_images/logo.png" class="logo__img"/>
                            <h2>
                                {{ $t('signal.signal') }}
                            </h2>
                            <img class="guide_img" @click="openGuide(1)"
                                src="@/assets/images/dominance_images/chart_desc_icon.png" />
                        </div>
                       
                    </div>
                    <div id="screen__mobile">
                        <div class="sub-title">
                            <img src="@/assets/images/dominance_images/logo.png" class="logo__img"/>
                        <h2>
                            {{ $t('signal.signal') }}
                        </h2>
                        <img class="guide_img_mobile" @click="openGuide(1)"
                            src="@/assets/images/dominance_images/chart_desc_icon.png" />
                        </div>
                    </div>
                    <div id="screen__pc" class="ratio-chart-wrapper" style="width: 100%;">
                        <div class="siganl-select">
                            <select v-model="coinTime">
                                <option value="1h">1 hour</option>
                                <option value="4h">4 hour</option>
                                <option value="12h">12hour</option>
                                <option value="24h">24hour</option>
                            </select>
                        </div>
                        <RatioChart />
                    </div>
                    <div id="screen__mobile" class="ratio-chart-wrapper">
                        <div class="siganl-select">
                            <select v-model="coinTime">
                                <option value="1h">1 hour</option>
                                <option value="4h">4 hour</option>
                                <option value="12h">12hour</option>
                                <option value="24h">24hour</option>
                            </select>
                        </div>
                        <RatioChartMobile />
                    </div>
                </div>
                <div class="exchange-wrapper">
                    <div id="screen__pc" >
                        <div class="sub-title">
                            <img src="@/assets/images/dominance_images/logo.png" class="logo__img"/>
                            <h2>
                                {{ $t('signal.ratio') }}
                            </h2>
                            <img class="guide_img" @click="openGuide(2)"
                                src="@/assets/images/dominance_images/chart_desc_icon.png" />
                        </div>
                    </div>
                    <div id="screen__mobile" >
                        <div class="sub-title" >
                            <img src="@/assets/images/dominance_images/logo.png" class="logo__img"/>
                            <h2>
                                {{ $t('signal.ratio') }}
                            </h2>
                            <img class="guide_img_mobile" @click="openGuide(2)"
                                src="@/assets/images/dominance_images/chart_desc_icon.png" />
                        </div>
                    </div>
                    <div id="screen__pc" class="exchage-list-wrapper">
                        <VsRatio :positionData="positionData" />
                    </div>
                    <div id="screen__mobile" class="exchage-list-wrapper">
                        <VsRatioMobile :positionData="positionData" />
                    </div>
                </div>
            </div>
        </div>
        <GuideModal :type="cocntentType" v-if="guideModal" />
    </div>

</template>

<script>
import "@/assets/css/signal.css"
import TitleView from "@/components/public/TitleView.vue"
import RatioChart from "@/components/signal/chart/RatioChart.vue"
import VsRatio from "@/components/signal/sub/VsRatio.vue"
import GuideModal from "@/components/public/GuideModal.vue"
import RatioChartMobile from "./chart/RatioChartMobile.vue";
import VsRatioMobile from "./sub/VsRatioMobile.vue";

import { mapActions, mapMutations, mapState } from "vuex";

export default {
    name: 'LongShort',
    data() {
        return {
            coinTime: "1h",
            cocntentType: 2,
        }
    },
    components: {
        TitleView,
        RatioChart,
        VsRatioMobile,
        RatioChartMobile,
        VsRatio,
        GuideModal
    },
    async created() {
        await this.loadPosition();
    },
    computed: {
        ...mapState("signal", ["positionData"]),
        ...mapState("dominance", ["guideModal"]),
    },
    methods: {
        ...mapMutations("dominance", ["changeGuide"]),
        ...mapMutations("signal", ["changSignalTime"]),
        ...mapActions("signal", ["loadPosition"]),

        openGuide(type) {
            this.cocntentType = type;
            this.changeGuide(true);
        },

        closeGuide() {
            this.changeGuide(false);
        }
    },
    watch: {
        coinTime() {
            this.changSignalTime(this.coinTime)
        },
    }
}
</script>
