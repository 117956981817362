<template>
    <div v-if="loadSig">
        <div class="updown-container signal-container" v-for="data in signal" :key="data">
            <div class="signal-mb-wrapper">
                <p class="signal-mb">
                    <span data-content="dominance" data-word="marketPrice" class="ko">{{getName(data.name)}}</span>
                </p>
                <div class="signal-mb-change">
                    <p class="up_count price-up">{{ $t('dominance.rise') }}: {{ data.long }}</p>
                    <p class="up_count price-up" v-if="data.noChange != 0">{{ $t('dominance.noChange') }}: {{ data.noChange }}</p>
                    <p class="down_count price-down">{{ $t('dominance.fall') }}: {{ data.short }}</p>
                </div>
            </div>
            <div class="flex display_ratio price">
                <div class="up_bg" :style="{ width: data.upPer + '%', minWidth: '80px' }">
                        <p>{{ data.upPer }}%</p>
                </div>
                <div class="zero_bg" v-if="data.noChange != 0"
                    :style="{ width: data.noChange + '%', minWidth: '50px' }">
                    <div>
                        <p class="zero_count score-zero">{{ data.noChange }}%</p>
                    </div>
                </div>
                <div class="down_bg" :style="{ width: data.downPer + '%', minWidth: '80px'}">
                    <p>{{ data.downPer }}%</p>
                </div>
            </div>
        </div>
        <!-- 고정 데이터 -->
        <div class="signal-fixed-mobile">
            <div class="signal_name">
                <p class="signal-mb-signal">
                    <span data-content="dominance">{{ $t('dominance.goyaSignal') }}</span> 
                </p>
            </div>
            <div class="blur_signal_mobile">
                <WarnAuth  class="signal_warn_auth"/>
            </div>
        </div>
        <div class="signal-fixed-mobile-trend">
            <div class="signal_name">
                <p class="signal-mb-fixed">
                    <span data-content="dominance">{{ $t('dominance.goyaTrend') }}</span> 
                </p>
            </div>
            <div class="blur_signal_mobile">
                <WarnAuth  class="signal_warn_auth"/>
            </div>
        </div>
    </div>
    <div class="loading_spinner" v-if="!loadSig">
        <div class="loading_circle"></div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import WarnAuth from "@/components/public/WarnAuth.vue"

export default {
    name: "SignalViewMobile",
    data() {
        return {
            signalFixed: {
                signal: {
                    name: this.$t("dominance['goyaSignal']"),
                    up: 10,
                    noChange: 0,
                    down: 90,
                    upPer: 10,
                    downPer: 90
                },
                trend: {
                    name: this.$t("dominance['goyaTrend']"),
                    up: 60,
                    noChange: 0,
                    down: 40,
                    upPer: 60,
                    downPer: 40
                },
            },
        }
    },
    components: {
        WarnAuth
    },
    async mounted() {
        await this.loadSignal()
        await this.loadScore()
    },
    computed: {
        ...mapState("dominance", ["signal", "coin", "loadSig"]),

    },
    methods: {
        ...mapActions("dominance", ["loadSignal", "loadScore"]),

        getName(type) {
            if (type === '시세') {
                return this.$t("dominance['marketPrice']")
            } else if (type === '스코어') {
                return this.$t("dominance['goyaScore']")
            } else if (type === 'RSI') {
                return this.$t("dominance['goyaRsi']")
            }
        },
    }

}
</script>