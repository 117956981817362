<template>
    <div class="rsi_mobile_container">
        <div class="rsi-mobile-header-wrapper" ref="headerWrapper">
            <div class="rsi-mobile-header">
                <div class="rsi-mobile-header-item">
                    {{ $t('indi.coin') }}
                </div>
                <div class="rsi-mobile-header-item" @click="sortList('priceval')">
                    {{ $t('indi.price') }}
                    <img :src="getArrowImage('priceval')" />
                </div>
                <div class="rsi-mobile-header-item" @click="sortList('priceChangePercent1h')">
                    {{ $t('indi.1hChange') }}
                    <img :src="getArrowImage('priceChangePercent1h')" />
                </div>
                <div class="rsi-mobile-header-item" @click="sortList('priceChangePercent24h')">
                    {{ $t('indi.24hChange') }}
                    <img :src="getArrowImage('priceChangePercent24h')" />
                </div>
                <div class="rsi-mobile-header-item" @click="sortList('rsi15m')">
                    {{ $t('indi.15rsi') }}
                    <img :src="getArrowImage('rsi15m')" />
                </div>
                <div class="rsi-mobile-header-item" @click="sortList('rsi1h')">
                    {{ $t('indi.1hRsi') }}
                    <img :src="getArrowImage('rsi1h')" />
                </div>
                <div class="rsi-mobile-header-item" @click="sortList('rsi4h')">
                    {{ $t('indi.4hRsi') }}
                    <img :src="getArrowImage('rsi4h')" />
                </div>
                <div class="rsi-mobile-header-item" @click="sortList('rsi12h')">
                    {{ $t('indi.12hRsi') }}
                    <img :src="getArrowImage('rsi12h')" />
                </div>
                <div class="rsi-mobile-header-item" @click="sortList('rsi24h')">
                    {{ $t('indi.24hRsi') }}
                    <img :src="getArrowImage('rsi24h')" />
                </div>
            </div>
        </div>
        <div class="rsi-mobile-content-wrapper" ref="contentWrapper">
            <div class="rsi-mobile-content">
                <div class="rsi-mobile-content-item" v-for="coin in filteredCoinList" :key="coin.rank">
                    <div class="rsi-mobile-item-coin">
                        <img :src="getCoinIcon(coin.symbol)" alt="" class="rsi-mobile-coin-icon">
                        {{ coin.symbol }}/
                        <span>USDT</span>
                    </div>
                    <div v-if="!coin.price.includes(0.0)" class="rsi-mobile-item-price">$ {{ coin.price }}</div>
                    <div v-else class="rsi-mobile-item-price">$ {{ coin.priceval }}</div>
                    <div class="rsi-mobile-item-priceChangePercent1h"
                        :class="{ 'positive': coin.priceChangePercent1h > 0, 'negative': coin.priceChangePercent1h < 0 }">
                        <span v-if="coin.priceChangePercent1h > 0">+</span>
                        {{ coin.priceChangePercent1h }}%
                    </div>
                    <div class="rsi-mobile-item-priceChangePercent24h"
                        :class="{ 'positive': coin.priceChangePercent24h > 0, 'negative': coin.priceChangePercent24h < 0 }">
                        <span v-if="coin.priceChangePercent24h > 0">+</span>
                        {{ coin.priceChangePercent24h }}%
                    </div>
                    <div class="rsi-mobile-item-rsi15m">{{ coin.rsi15m }}</div>
                    <div class="rsi-mobile-item-rsi1h">{{ coin.rsi1h }}</div>
                    <div class="rsi-mobile-item-rsi4h">{{ coin.rsi4h }}</div>
                    <div class="rsi-mobile-item-rsi12h">{{ coin.rsi12h }}</div>
                    <div class="rsi-mobile-item-rsi24h">{{ coin.rsi24h }}</div>
                </div>
            </div>
            <div class="no-data" v-if="filteredCoinList.length === 0">
                {{ $t('brief.noData') }}
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';

export default {
    name: "RsiGraphMobile",
    props: {
        searchCoin: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            sortKey: null,  // Current sorted key
            sortOrder: 1,   // Sorting order (1: Ascending, -1: Descending)
            activeSortKey: null // Current active sort key
        };
    },
    created() {
        this.loadRsi();
    },
    computed: {
        ...mapState("indicator", ["graphRsi"]),

        filteredCoinList() {
            let sortedList = this.sortedCoinList;
            if (this.searchCoin) {
                sortedList = sortedList.filter(coin =>
                    coin.symbol.toLowerCase().includes(this.searchCoin.toLowerCase())
                );
            }

            return sortedList;
        },

        sortedCoinList() {
            let sortedList = [...this.graphRsi];

            if (this.sortKey) {
                sortedList.sort((a, b) => {
                    let aValue = a[this.sortKey];
                    let bValue = b[this.sortKey];

                    if (!isNaN(aValue) && !isNaN(bValue)) {
                        aValue = parseFloat(aValue);
                        bValue = parseFloat(bValue);
                    }

                    if (aValue < bValue) {
                        return -1 * this.sortOrder;
                    }
                    if (aValue > bValue) {
                        return 1 * this.sortOrder;
                    }
                    return 0;
                });
            }

            return sortedList;
        }
    },
    methods: {
        ...mapActions("indicator", ["loadRsi"]),

        getCoinIcon(symbol) {
            try {
                return require(`@/assets/images/coin_logos/${symbol.toLowerCase()}.png`);
            } catch {
                return require("@/assets/images/coin_logos/default_dark.png");
            }
        },

        getArrowImage(key) {
            if (this.sortKey === key) {
                return this.sortOrder === 1
                    ? require('@/assets/images/dominance_images/arrow_down.png')
                    : require('@/assets/images/dominance_images/arrow_up.png');
            } else {
                return require('@/assets/images/dominance_images/arrow_down.png');
            }
        },

        sortList(key) {
            if (this.sortKey === key) {
                this.sortOrder *= -1;
            } else {
                this.sortKey = key;
                this.sortOrder = -1;
            }
            this.activeSortKey = key;
        },

        syncScroll() {
            const headerWrapper = this.$refs.headerWrapper;
            const contentWrapper = this.$refs.contentWrapper;

            contentWrapper.addEventListener('scroll', () => {
                headerWrapper.scrollLeft = contentWrapper.scrollLeft;
            });
        }
    },
    mounted() {
        this.syncScroll();
    }
}
</script>
<style scoped>
.rsi_mobile_container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    overflow-x: auto;
    margin-top: 10px;
    border-radius: 10px;
}

.rsi-mobile-content-wrapper::-webkit-scrollbar {
    width: 5px; 
    height: 5px;
}

.rsi-mobile-content-wrapper::-webkit-scrollbar-track {
    background: var(--background-color);
}

.rsi-mobile-content-wrapper::-webkit-scrollbar-thumb {
    background-color: var(--scrole-orange);
    border-radius: 10px;
}

.rsi-mobile-content-wrapper::-webkit-scrollbar-corner {
    background-color: var(--background-color); 
}

.rsi-mobile-header-wrapper {
    position: sticky;
    top: 0;
    z-index: 1;
    overflow: hidden;
    border-radius: 1rem 1rem 0  0;
}

.rsi-mobile-header {
    display: grid;
    grid-template-columns: 210px 150px 150px 150px 150px 150px 150px 150px 150px;
    width: max-content;
    padding-bottom: 0px;
    text-align: center;
    background: var(--background-list3);

}

.rsi-mobile-header-item {
    padding: 20px;
    cursor: pointer;
    white-space: nowrap;
}

.rsi-mobile-header-item img {
    margin-top: 5px;
    margin-left: 3px;
}

.rsi-mobile-content-wrapper {
    margin-top: 10px;
    background: var(--background-list3);
    flex: 1;
    max-height: 500px;
    overflow: auto;
}

.rsi-mobile-content {
    display: grid;
    grid-template-columns: 200px 150px 150px 150px 150px 150px 150px 150px 150px;
    width: max-content;
    text-align: center;
}

.rsi-mobile-content-item {
    display: contents;
    /* Use grid structure */
}

.rsi-mobile-item-ranking,
.rsi-mobile-item-coin,
.rsi-mobile-item-price,
.rsi-mobile-item-priceChangePercent1h,
.rsi-mobile-item-priceChangePercent24h,
.rsi-mobile-item-rsi15m,
.rsi-mobile-item-rsi1h,
.rsi-mobile-item-rsi4h,
.rsi-mobile-item-rsi12h,
.rsi-mobile-item-rsi24h {
    padding: 20px;
    white-space: nowrap;
    border-right: 5px solid var(--background-color);
}

.rsi-mobile-coin-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.positive {
    color: var(--text-green);
}

.negative {
    color: var(--text-red);
}

.no-data {
    padding: 20px;
    text-align: center;
    color: #888;
}
</style>
