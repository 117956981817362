import { API } from "./apiAuth";


// ratio chart data 조회 api
const getRatio = async (time) => {
  try {
    const response = await API.get(`long_short_ratio?interval=${time}&limit=0`);
    return response.data;
  } catch (error) {
    console.error("Error api get ratio: ", error);
    throw error;
  }
};

// ratio coin list data 조회 api
const getPostiion = async (page) => {
  try {
    const response = await API.get(`trade_ls?page=${page}&page_size=300`);
    return response.data;
  } catch (error) {
    console.error("Error api get position long/short: ", error);
    throw error;
  }
};


export default {
    getRatio,
    getPostiion
};
