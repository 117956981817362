<template>
    <div class="left-menu-wrapper">
        <div class="left-menu-container semina">
            <div id="dominance" class="menu-item">
                    <router-link to="/dominance">
                        <div v-if="mainPath === 'dominance'" class="active_menu">
                            <img src="@/assets/images/left_nav/dominance_active.png" />
                        </div>
                        <div v-else class="deactive_menu">
                            <img src="@/assets/images/left_nav/dominance.png" />
                        </div>
                        <div class="left-nav-name">{{ $t('dominance.goyaDominance') }}</div>
                    </router-link>
                    </div>
                <div id="scorelist" class="menu-item">
                    <router-link to="/score">
                        <div v-if="mainPath === 'score'" class="active_menu">
                            <img src="@/assets/images/left_nav/score_active.png" />
                        </div>
                        <div v-else class="deactive_menu">
                            <img src="@/assets/images/left_nav/score.png" />
                        </div>
                        <div class="left-nav-name">{{ $t('score.goyaList') }}</div>
                    </router-link>
                </div>
                <div id="brieflist" class="menu-item">
                    <router-link to="/brief">
                        <div v-if="mainPath === 'brief'" class="active_menu">
                            <img src="@/assets/images/left_nav/briefing_active.png" />
                        </div>
                        <div v-else class="deactive_menu">
                            <img src="@/assets/images/left_nav/briefing.png" />
                        </div>
                        <div class="left-nav-name">{{ $t('brief.brief') }}</div>
                    </router-link>
                </div>
                <div id="analysis" class="menu-item">
                    <router-link to="/volume">
                        <div v-if="mainPath === 'volume'" class="active_menu">
                            <img src="@/assets/images/left_nav/analysis_active.png" />
                        </div>
                        <div v-else class="deactive_menu">
                            <img src="@/assets/images/left_nav/analysis.png" />
                        </div>
                        <div class="left-nav-name" v-html="$t('analysis.analysis')"></div>
                    </router-link>
                </div>
                <div id="signal" class="menu-item">
                    <router-link to="/signal">
                        <div v-if="mainPath === 'signal'" class="active_menu">
                            <img src="@/assets/images/left_nav/signal_active.png" />
                        </div>
                        <div v-else class="deactive_menu">
                            <img src="@/assets/images/left_nav/signal.png" />
                        </div>
                        <div class="left-nav-name">{{ $t('signal.signal') }}</div>
                    </router-link>
                </div>
                <div id="clearing" class="menu-item">
                    <router-link to="/clearing">
                        <div v-if="mainPath === 'clearing'" class="active_menu">
                            <img src="@/assets/images/left_nav/clearing_active.png" />
                        </div>
                        <div v-else class="deactive_menu">
                            <img src="@/assets/images/left_nav/clearing.png" />
                        </div>
                        <div class="left-nav-name">{{ $t('liq.liq') }}</div>
                    </router-link>
                </div>
                <div id="indicator" class="menu-item">
                    <router-link to="/indicator">
                        <div v-if="mainPath === 'indicator'" class="active_menu">
                            <img src="@/assets/images/left_nav/indicator_active.png" />
                        </div>
                        <div v-else class="deactive_menu">
                            <img src="@/assets/images/left_nav/indicator.png" />
                        </div>
                        <div class="left-nav-name">{{ $t('indi.indi') }}</div>
                    </router-link>
                </div>
                <div id="chain" class="menu-item">
                    <router-link to="/chain">
                        <div v-if="mainPath === 'chain'" class="active_menu">
                            <img src="@/assets/images/left_nav/chain_active.png" />
                        </div>
                        <div v-if="mainPath != 'chain'" class="deactive_menu">
                            <img src="@/assets/images/left_nav/chain.png" />
                        </div>
                        <div class="left-nav-name">{{ $t('chain.chain') }}</div>
                    </router-link>
                </div>
                
            </div>
    </div>

</template>

<script>
import { ref, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import "@/assets/css/left_nav.css";

export default {
    name: 'LeftNav',
    setup() {
        const route = useRoute();
        const lang = ref(route.query.lang || 'en'); // 초기 값 설정

        // 쿼리 매개변수 변경을 감지하여 lang 업데이트
        watch(() => route.query.lang, (newLang) => {
            lang.value = newLang || 'en'; // 기본값 설정
        });

        const mainPath = computed(() => {
            return route.path.split("/")[1];
        });

        const goPage = (page) => {
            const url = page === 'dominance' 
                ? `https://retri.xyz/goya_dominance_semina.php?lang=${lang.value}`
                : page === 'score'
                ? `https://retri.xyz/scorelist_semina.php?lang=${lang.value}`
                : '';

            if (url) {
                window.location.href = url;
            }
        };

        return {
            lang,
            mainPath,
            goPage
        };
    }
}
</script>

<style>


</style>