<template>
    <div class="modal_overlay">
        <div class="goya_dominance_chart_wrapper market">
            <div class="loading_spinner" v-if="!loadMini">
                <div class="loading_circle"></div>
            </div>
            <div id="dominance-coin-chart"  v-if="loadMini">
                <img @click="closeMini" src="@/assets/images/dominance_images/close_icon.png" class="market cancel_goya" width="30px;" />
                <div class="goya_dominance_chart_content">
                    <div @click="changeCoin('BTC')" class="goya_dominance_chart_label btc">
                        <img src="@/assets/images/dominance_images/chart-label-red.png" />
                        <p>BTC</p>
                    </div>
                    <div @click="changeCoin('ETH')" class="goya_dominance_chart_label eth">
                        <img src="@/assets/images/dominance_images/chart-label-purple.png" />
                        <p>ETH</p>
                    </div>
                    <div @click="changeCoin('TOP')" class="goya_dominance_chart_label top10">
                        <img src="@/assets/images/dominance_images/chart-label-blue.png" />
                        <p>TOP 10</p>
                    </div>
                    <div @click="changeCoin('ALT')" class="goya_dominance_chart_label alt">
                        <img src="@/assets/images/dominance_images/chart-label-green.png" />
                        <p>ALT</p>
                    </div>
                    <div @click="changeCoin('USDT')" class="goya_dominance_chart_label tether">
                        <img src="@/assets/images/dominance_images/chart-label-orange.png" />
                        <p>USDT</p>
                    </div>
                </div>
                <div ref="market_mini"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import { createChart } from 'lightweight-charts';
import { getTimeZone } from "@/utils/localeTime";

mapMutations
export default {
    name: 'MarketMini',
    data() {
        return {
            localTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,

            // 선택한 코인
            selectCoin: "BTC",

            dominance_coin: [
                {'coin': 'BTC', 'color': '#FC5E5E', 'data': []},
                {'coin': 'ETH', 'color': '#A158FF', 'data': []},
                {'coin': 'USDT', 'color': '#FFA857', 'data': []},
                {'coin': 'ALT', 'color': '#90EB71', 'data': []},
                {'coin': 'TOP', 'color': '#71C3FF', 'data': []}
            ],
            
            lineOptions: {
                priceScaleId: 'left',
                priceFormat: {
                    type: 'price',
                    precision: 2,
                    minMove:0.01,
                },
                color: '#FC5E5E',
                lastValueVisible: false,
                pointMarkersVisible: true,
                lineWidth: 1,
                priceLineVisible: false,
            },

            chart: null

        };
    },
    computed: {
        ...mapState("dominance_mini", ["goyaChartMini", "loadMini"]),

        // 코인별 차트 데이터
        coinData() {
            return this.goyaChartMini
        },

        initialValue() {
            return 100
        },

        chartOptions() {
            return {
                height: 500,
                width: this.$refs.market_mini ? this.$refs.market_mini.offsetWidth : 0,
                layout: {
                background: { color: 'transparent' },
                textColor: '#fff',
                },
                grid: {
                    vertLines: {
                        color: 'transparent',
                    },
                    horzLines: {
                        color: 'transparent',
                    },
                },
                leftPriceScale: {
                    autoScale: true,
                    scaleMargins: {
                        top: 0.2,
                        bottom: 0.08,
                    },
                    visible:true,
                },
                priceRange:{
                    minValue: 0,
                    maxValue: 80,
                },
                timeScale: {
                    timeVisible: true,
                    secondsVisible: false,
                    tickMarkFormatter(time) {
                       // time을 Date 객체로 변환
                        var convertTime = new Date(time*1000);
                        let lang = localStorage.getItem("lang");
                        let regionCode

                        if (lang === 'ko') {
                            regionCode = 'KR'
                            } else if (lang === 'ja') {
                            regionCode = 'JP'
                            } else if (lang === 'zh') {
                            regionCode = 'CN'
                            } else {
                            regionCode = 'US'
                            }

                        const localTimezone = getTimeZone(regionCode);

                        let optionsT = {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute:"2-digit",
                            hour12: false,
                            timeZone: localTimezone,
                        };

                        let localTime = convertTime.toLocaleString('en-US', optionsT).replace(',', ''); // 쉼표(,) 제거
                        localTime = localTime.replace(/\//g, '-');
                        return localTime;
                    },
                },
                localization:{
                    timeFormatter(originalTime) {
                        let lang = localStorage.getItem("lang");
                        let regionCode

                        if (lang === 'ko') {
                            regionCode = 'KR'
                            } else if (lang === 'ja') {
                            regionCode = 'JP'
                            } else if (lang === 'zh') {
                            regionCode = 'CN'
                            } else {
                            regionCode = 'US'
                            }

                        let timeZone = getTimeZone(regionCode)
                        const zonedDate = new Date(new Date(originalTime * 1000).toLocaleString('en-US', { timeZone:timeZone }));

                        zonedDate.getTime() / 1000;
                        const year = zonedDate.getFullYear();
                        const month = (zonedDate.getMonth() + 1).toString().padStart(2, '0'); // 월은 0부터 시작하므로 +1 필요, 두 자리로 패딩
                        const day = zonedDate.getDate().toString().padStart(2, '0'); // 날짜, 두 자리로 패딩
                        const hours = zonedDate.getHours().toString().padStart(2, '0'); // 시간, 두 자리로 패딩
                        const minutes = zonedDate.getMinutes().toString().padStart(2, '0'); // 분, 두 자리로 패딩
                        let formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;
                        return formattedDateTime;
                    }
                },
                crosshair: {
                    mode: 0,
                },
                rightPriceScale: {
                    visible: false,
                }
            };
        },
    },
    async mounted() {
        await this.loadMarketMini();
        await this.populateDominanceData();
        await this.initializeChart();
        await this.removeLogoLink();
    },
    methods: {
        ...mapMutations("dominance_mini", ["changeMini", "changeType"]),
        ...mapActions("dominance_mini", ["loadMarketMini"]),

        
        // 모달창 닫기
        closeMini() {
            this.changeMini(0)
        },

        // 처음 로딩시 차트 데이터 화면에 꽉 차게 설정
        setChartVisibleRange(data) {
            if (data.length === 0) return;

            const firstTime = data[0].time;
            const lastTime = data[this.coinData.btc.length - 1].time;

            this.chart.timeScale().setVisibleRange({ from: firstTime, to: lastTime });
        },

        // 처음 로딩시 BTC 차트 조회
        async initializeChart() {
            this.chart = createChart(this.$refs.market_mini, this.chartOptions);
            this.lineSeries = this.chart.addLineSeries(this.lineOptions);
            this.lineSeries.setData(this.dominance_coin.find(data => data.coin === 'BTC').data);
            this.setChartVisibleRange(this.dominance_coin.find(data => data.coin === 'BTC').data);
        },

        updateChart() {
            const selectedCoinData = this.dominance_coin.find(coin => coin.coin === this.selectCoin).data;
            if (this.lineSeries) {
                this.lineSeries.setData(selectedCoinData);
                this.setChartVisibleRange(this.chart, selectedCoinData);
            }
        },

        // coin 선택시 변경
        changeCoin(coin) {
            if (this.chart) {
                this.chart.remove();
                this.chart = null;
            }

            this.changeType(coin.toLowerCase());
            this.loadMarketMini();
            
            const selectedCoinData = this.dominance_coin.find(data => data.coin === coin);
            
            if (selectedCoinData) {
                this.chart = createChart(this.$refs.market_mini, this.chartOptions);

                this.lineOptions.color = selectedCoinData.color;
                this.lineSeries = this.chart.addLineSeries(this.lineOptions);
                this.lineSeries.setData(selectedCoinData.data); 
                this.setChartVisibleRange(selectedCoinData.data);
            }
        },
        
        // dominance_coin data 에 computed data 에 데이터 넣어주는 함수
        async populateDominanceData() {
            const coinData = {
                BTC: this.coinData.btc,
                ETH: this.coinData.eth,
                USDT: this.coinData.tether,
                ALT: this.coinData.alt,
                TOP: this.coinData.top10,
            };

            this.dominance_coin.forEach(coin => {
                coin.data = coinData[coin.coin] || [];
            });
        },

        async removeLogoLink() {
            const chartElement = this.$refs.market_mini;
            if (chartElement) {
                const logoLink = chartElement.querySelector('a#tv-attr-logo');
                if (logoLink) {
                    logoLink.remove();
                }
            }
        },

    },
    onUnmounted() {
        if (this.chart) {
            this.chart.remove();
            this.chart = null;
        }
    }
}
  </script>