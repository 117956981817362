<template>
  <div class="loading_spinner" v-if="loadingChk === false">
    <div class="loading_circle"></div>
  </div>
  <div :id="chartId" ref="chart" v-if="loadingChk === true"></div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, computed, watch, nextTick, getCurrentInstance } from 'vue';
import zingchart from 'zingchart';
import 'zingchart/modules-es6/zingchart-treemap.min.js';
import { useStore } from 'vuex';
import { mapState } from 'vuex';

export default {
  name: 'ClearingChart',
  setup() {
    const { proxy } = getCurrentInstance();
    const chartId = ref('zingchart-' + Math.random().toString(36).substr(2, 9));
    const store = useStore();
    const clearingData = computed(() => store.state.clearing.clearingData);
    const language = computed(() => store.state.dominance.lang);

    const getTranslatedText = computed(() => {
      return {
        lev: proxy.$t('liq.lev'),
        nowPrice: proxy.$t('liq.nowPrice'),
        price: proxy.$t('analysis.price'),
        volume: proxy.$t('analysis.volume'),
      };
    });

    const chartData = ref({
      graphset: [
        {
          type: 'mixed',
          globals: {
            fontFamily: "var(--main-font-Light)",
            fontColor: "var(--color-main)"
          },
          'background-color': 'transparent',
          border: 'none',
          title: {
            text: '',
            'font-color': '#7E7E7E',
            backgroundColor: 'none',
            'font-size': '22px',
            alpha: 1,
            'adjust-layout': true,
          },
          plotarea: {
            margin: 'dynamic',
          },
          legend: {
            fontFamily: "Noto Sans JP",
            layout: 'x2',
            backgroundColor: 'transparent',
            borderColor: 'none',
            overflow: 'page',
            alpha: 0.1,
            shadow: false,
            align: 'center',
            marker: {
              'border-color': 'none',
              size: '8px',
            },
            item: {
              fontColor: '#FFFFFF',
            },
            'offset-y': -30,
          },
          gui: {
            contextMenu: {
              visible: false
            }
          },
          plot: {
            animation: {
              effect: 'ANIMATION_SLIDE_BOTTOM',
              sequence: 0,
              speed: 0,
            },
          },
          tooltip: {
            fontFamily: "var(--main-font-Light)",
            visible: true,
            text:  " %t <br />" + getTranslatedText.value.price + ": $ %kl <br />" + getTranslatedText.value.volume + ": %v <br />",
            shared: true, 
            backgroundColor: 'var(--background-chart2)'
          },
          'scale-y': {
            values: '',
            guide: {
              visible: true,
              lineColor: 'var(--background-list3)',
            },
            multiplier: true
          },
          'scale-x': {
            values: [],
            guide: {
              visible: false,
            },
            "thousands-separator": ","
          },
          labels: [
            {
              text: '',
              'background-color': '#FFFFFF',
              'font-size': '13px',
              'font-color': '#000',
              padding: '10%',
              'border-radius': '5px',
              'offset-y': 0,
              height: '25px',
              hook: 'node:plot=4;index=0',
            },
          ],
          series: [
            {
              type: 'bar',
              values: [],
              'background-color': 'var(--chart-red)',
              text: '100x' + getTranslatedText.value.lev,
            },
            {
              type: 'bar',
              values: [],
              'background-color': 'var(--chart-orange1)',
              text: '50x' + getTranslatedText.value.lev,
            },
            {
              type: 'bar',
              values: [],
              'background-color': 'var(--text-green)',
              text: '25x' + getTranslatedText.value.lev,
            },
            {
              type: 'bar',
              values: [],
              'background-color': 'var(--chart-skyblue)',
              text: '10x' + getTranslatedText.value.lev,
            },
            {
              type: 'bar',
              values: [],
              'background-color': '#FFFFFF',
              text: getTranslatedText.value.nowPrice,
              legendMarker: {
                'border-width': 1,
                'border-color': '#FFFFFF',
                type: 'line',
                'line-color': '#FFFFFF',
                size: '6px',
              },
              'guide-label': {
                visible: false,
              },
              'tooltip': {
                visible: false,
              },
            },
          ],
        },
      ],
    });

    zingchart.TOUCHZOOM = 'pinch';

    const renderChart = async () => {
      await nextTick();
      zingchart.render({
        id: chartId.value,
        data: chartData.value,
        height: '500px',
        width: '100%',
      });
    };

    const fontLang = () => {
        if (language.value === 'ja') {
          return "Noto Sans JP"
        } else if (language.value === 'zh') {
          return "Noto Sans SC"
        } else {
          return "var(--main-font-Light)"
        }
      }

    const removeBtn = () => {
      nextTick(() => {
        const toolBar = document.querySelector(`#${chartId.value}-trigger-c`);
        if (toolBar) {
          toolBar.style.display = 'none'; // 요소 숨기기
        }
      });
    }

    const updateChartData = (newData) => {
      chartData.value = {
        ...chartData.value,
        graphset: [
          {
            ...chartData.value.graphset[0],
            'scale-y': { ...chartData.value.graphset[0]['scale-y'], values: newData.volRange },
            'scale-x': { ...chartData.value.graphset[0]['scale-x'], values: newData.priceRange },
            'tooltip': { ...chartData.value.graphset[0]['tooltip'], fontFamily: fontLang() },
            'globals': { ...chartData.value.graphset[0]['globals'], fontFamily: fontLang() },
            labels: [
              {
                ...chartData.value.graphset[0].labels[0],
                text: `${newData.btcPrice[0][0].toLocaleString()}`,
              },
            ],
            series: [
              { ...chartData.value.graphset[0].series[0], values: newData.lev100 },
              { ...chartData.value.graphset[0].series[1], values: newData.lev50 },
              { ...chartData.value.graphset[0].series[2], values: newData.lev25 },
              { ...chartData.value.graphset[0].series[3], values: newData.lev10 },
              { ...chartData.value.graphset[0].series[4], values: newData.btcPrice },
            ],
          },
        ],
      };

      renderChart();
    };

    const loadAndUpdateChart = async () => {
      await store.dispatch('clearing/loadClearing');
      if (clearingData.value) {
        updateChartData(clearingData.value);
      }
      removeBtn();
    };

    onMounted(async () => {
      await loadAndUpdateChart();
    });

    onBeforeUnmount(() => {
      zingchart.exec(chartId.value, 'destroy');
    });

    watch(() => store.state.clearing.day, async () => {
      await loadAndUpdateChart();
    });

    watch(clearingData, async (newData) => {
      if (newData) {
        updateChartData(newData);
      }
    });

    watch(getTranslatedText, () => {
      chartData.value.graphset[0].series[0].text = '100x' + getTranslatedText.value.lev
      chartData.value.graphset[0].series[1].text = '50x' + getTranslatedText.value.lev
      chartData.value.graphset[0].series[2].text = '25x' + getTranslatedText.value.lev
      chartData.value.graphset[0].series[3].text = '10x' + getTranslatedText.value.lev
      chartData.value.graphset[0].series[4].text = getTranslatedText.value.nowPrice
      
      chartData.value.graphset[0].tooltip.text = " %t <br />" + getTranslatedText.value.price + ": %kl <br />" + getTranslatedText.value.volume + ": %v <br />",
    
      renderChart();
    })

    return {
      chartId,
    };
  },
  computed: {
    ...mapState('clearing', ['loadingChk', 'day']),
  },
};
</script>

<style>
#chartId {
  width: 100%;
  height: 100%;
}
</style>
