<template>
    <div v-if="loadSig">
        <div class="updown-container signal-container" v-for="data in signal" :key="data">
            <p class="signal-mb">
                <span data-content="dominance" data-word="marketPrice" class="ko">{{getName(data.name)}}</span> 
            </p>
            <div class="flex display_ratio price">
                <div class="up_bg"  :style="{ width: data.upPer + '%', minWidth: '170px' }">
                    <div v-if="data.name === 'RSI'">
                        <p class="up_count price-up" >{{ $t('signal.long') }}: {{data.long}}</p> 
                        <p>{{data.upPer}}%</p>
                    </div>
                    <div v-else>
                        <p class="up_count price-up" >{{ $t('dominance.rise') }}: {{data.long}}</p> 
                        <p>{{data.upPer}}%</p>
                    </div>
                </div>
                <div class="zero_bg" v-if="data.noChange != 0"  :style="{ width: data.noPer + '%', minWidth: '240px' }">
                    <div><p class="zero_count score-zero">{{ $t('dominance.noChange') }}: {{data.noChange}}</p><p>{{data.noPer}}%</p></div>
                </div>
                <div class="down_bg" :style="{ width: data.downPer + '%', minWidth: '170px' }">
                    <div v-if="data.name === 'RSI'">
                        <p>{{data.downPer}}%</p>
                        <p class="down_count price-down" >{{ $t('signal.short') }}: {{data.short}}</p> 
                    </div>
                    <div v-else>
                        <p>{{data.downPer}}%</p>
                        <p class="down_count price-down" >{{ $t('dominance.fall') }}: {{data.short}}</p> 
                    </div>
                </div>
            </div>
        </div>
        <!-- 고정 데이터 -->
            <div class="signal-fixed">
                <div class="signal_name">
                    <p class="signal-mb-signal">
                        <span data-content="dominance">{{ $t('dominance.goyaSignal') }}</span> 
                    </p>
                    <p class="signal-mb-fixed">
                        <span data-content="dominance">{{ $t('dominance.goyaTrend') }}</span> 
                    </p>
                </div>
                <div class="blur_signal">
                    <WarnAuth  class="signal_warn_auth"/>
                </div>
            </div>
        </div>
    <div class="loading_spinner" v-if="!loadSig">
        <div class="loading_circle"></div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import WarnAuth from "@/components/public/WarnAuth.vue"

export default{
    name: "SignalView",
    data() {
        return {
            signalFixed: {
                signal: {
                name: this.$t("dominance['goyaSignal']"),
                up: 10,
                noChange: 0,
                down: 90,
                upPer: 10,
                downPer: 90
                },
                trend: {
                name: this.$t("dominance['goyaTrend']"),
                up: 60,
                noChange: 0,
                down: 40,
                upPer: 60,
                downPer: 40
                },
            },
        }
    },
    components: {
        WarnAuth
    },
    async mounted() {
        await this.loadSignal()
        await this.loadScore()
    },
    computed: {
        ...mapState("dominance", ["signal", "coin", "loadSig"]),

    },
    methods: {
        ...mapActions("dominance", ["loadSignal", "loadScore"]),

        getName(type) {
            if (type === '시세') {
                return this.$t("dominance['marketPrice']")
            } else if (type === '스코어') {
                return this.$t("dominance['goyaScore']")
            } else if (type === 'RSI') {
                return this.$t("dominance['goyaRsi']")
            }
        },
    }
    
}
</script>