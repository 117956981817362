import axios from "axios";

export const API = axios.create({
  baseURL: "https://coin.retri.xyz/coinglass/",
  headers: {
    Accept: "*/*",
  },
});

export const API2 = axios.create({
  baseURL: "https://chart-api.blocksquare.tech/analysis/",
  headers: {
    Accept: "*/*",
  },
});

export const API3 = axios.create({
  baseURL: "https://fapi.binance.com/fapi/v1/ticker/",
  headers: {
    Accept: "*/*",
  },
});

export const API4 = axios.create({
  baseURL: "https://chart.goya-alert.com/",
  headers: {
    Accept: "*/*",
  },
});

export const API5 = axios.create({
  baseURL: "http://www.geoplugin.net/json.gp",
  headers: {
    Accept: "*/*",
  },
});



