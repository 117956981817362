<template>
    <div class="modal_overlay">
        <div class="guide_wrapper" :class="{ analysis_guide : mainPath === 'volume'}">
            <div class="close_guide">
                <!-- <h1>{{ $t('guide.guide') }}</h1> -->
                <button class="guide_close_btc">
                    <img @click="closeModal" src="@/assets/images/dominance_images/close_icon.png" />
                </button>
            </div>
            <div class="guide_content">
                <h1 class="guide_title">{{ getTitle() }}</h1>
                <div class="guide_ment" v-html="getGuide()"></div>
            </div>
        </div>
    </div>
</template>

<script>
import "@/assets/css/left_nav.css"
import { mapMutations } from 'vuex'

export default {
    name: "GuideModal",
    props: {
        type: Number,
    },
    computed: {
        mainPath() {
            return this.$route.path.split("/")[1];
        },

        lang() {
            return localStorage.getItem("lang") || "en"
        },

        widePage() {
            return (
                this.mainPath === 'signal',
                this.mainPath === 'indicator' && this.type === 1,
                this.mainPath === 'chain'
            )
        },

        wideJp() {
            return (
                this.mainPath === 'signal' && this.lang == 'ja'
            )
        },
        middlePage() {
            return (
                this.mainPath === 'clearing',
                this.mainPath === 'indicator' && this.type === 2
            )
        },
        clearJP() {
            return (
                this.mainPath === 'clearing' && this.lang === 'ja'
            )
        }
    },
    methods: {
        ...mapMutations("dominance", ["changeGuide"]),

        closeModal() {
            this.changeGuide(false);
        },

        getGuide() {
            // analysis
            if (this.type === 1 && this.mainPath === 'volume') {
                return this.$t("guide['analysis']['data']")
            } else if (this.type === 2 && this.mainPath === 'volume') {
                return this.$t("guide['analysis']['chart']")
            }

            // signal
            else if (this.mainPath === 'signal' && this.type === 1) {
                return `
                    ${this.$t('guide.signal.chart.text')}
                `
            } else if (this.mainPath === 'signal' && this.type === 2) {
                return `
                    ${this.$t('guide.signal.data.text')} 
                `
            }

            // clearing
            else if (this.mainPath === 'clearing') {
                return `
                    ${this.$t('guide.liq.text1')} <br /><br />
                    ${this.$t('guide.liq.text2')} 
                    ${this.$t('guide.liq.text3')} <br /><br />
                    ${this.$t('guide.liq.text4')} 
                `
            }

            // indicator
            else if (this.mainPath === 'indicator' && this.type === 1) {
                return ` ${this.$t('guide.indi.bubble.text')} `
            } else if (this.mainPath === 'indicator' && this.type === 2) {
                return ` ${this.$t('guide.indi.color.text')} `
            }

            // chain
            else if (this.mainPath === 'chain') {
                return ` ${this.$t('guide.chain.text')} `
            }
        },

        getTitle() {

            // analysis
            if (this.mainPath === 'volume' && this.type === 1) {
                return this.$t("analysis['coinData']")
            } else if (this.mainPath === 'volume' && this.type === 2) {
                return this.$t("analysis['heatMap']")
            }

            // signal
            else if (this.mainPath === 'signal' && this.type === 1) {
                return this.$t("signal['signal']")
            } else if (this.mainPath === 'signal' && this.type === 2) {
                return this.$t("signal['ratio']")
            }

            // clearing
            else if (this.mainPath === 'clearing') {
                return this.$t("liq['liq']")
            }

            // indicator
            else if (this.mainPath === 'indicator' && this.type === 1) {
                return this.$t("indi['bubble']")
            } else if (this.mainPath === 'indicator' && this.type === 2) {
                return this.$t("indi['colorChart']")
            }

            // chain
            else if (this.mainPath === 'chain') {
                return this.$t("chain['inOutFlow']")
            }


        },
    },
}
</script>