<template>
    <div class="ment_wrapper">
        <img v-if="mainPath === 'dominance'" src="@/assets/images/dominance_images/lock.svg" />
        <h2 class="warn_ment">
            {{ $t('warn.warnMessage') }}
        </h2>
        <br/>
        <button @click="goRetri" v-if="mainPath === 'score'">{{ $t('warn.singBtn') }}</button>
    </div>
</template>

<script>
export default{
    name:"WarnAuth",
    computed: {
        mainPath() {
            return this.$route.path.split("/")[1];
        }
    },
    methods: {
        goRetri() {
            window.open("https://www.tryex.io/index.asp")
        }
    },
}
</script>

<style>
</style>