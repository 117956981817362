<template>
  <div class="chart-wrapper">
    <div class="loading_spinner" v-if="!loadDomi">
      <div class="loading_circle"></div>
    </div>
    <div id="goya_dominance_mini_chart" v-if="loadDomi">
      <img class="click_goya chart-icon" @click="openMini(1)" src="@/assets/images/dominance_images/chart_icon.png">

      <div class="tv-lightweight-chart">
        <div ref="chart"></div>
      </div>
      <div class="dominance_mini_legend" ref="legend"></div>
    </div>
  </div>
</template>

<script>
import { createChart } from 'lightweight-charts';
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { getTimeZone } from "@/utils/localeTime";

export default {
  name: 'GoyaDominanceMobile',
  setup() {
    const store = useStore();
    const chart = ref(null);
    const legend = ref(null);

    const goyaChart = computed(() => store.state.dominance_chart.goyaChart);
    const loadDomi = computed(() => store.state.dominance_chart.loadDomi);

    const chartOptions = computed(() => ({
      height: 350,
      width: chart.value ? chart.value.offsetWidth : 0,
      layout: {
        background: { color: 'transparent' },
        textColor: '#fff',
      },
      grid: {
        vertLines: {
          color: 'transparent',
        },
        horzLines: {
          color: 'transparent',
        },
      },
      timeScale: {
        timeVisible: true,
        secondsVisible: false,
        tickMarkFormatter(time) {
            // time을 Date 객체로 변환
            var convertTime = new Date(time*1000);
            let lang = localStorage.getItem("lang");
            let regionCode

            if (lang === 'ko') {
                regionCode = 'KR'
                } else if (lang === 'ja') {
                regionCode = 'JP'
                } else if (lang === 'zh') {
                regionCode = 'CN'
                } else {
                regionCode = 'US'
                }

            const localTimezone = getTimeZone(regionCode);

            let optionsT = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute:"2-digit",
                hour12: false,
                timeZone: localTimezone,
            };

            let localTime = convertTime.toLocaleString('en-US', optionsT).replace(',', ''); // 쉼표(,) 제거
            localTime = localTime.replace(/\//g, '-');
            return localTime;
        },
    },
    localization:{
        timeFormatter(originalTime) {
            let lang = localStorage.getItem("lang");
            let regionCode

            if (lang === 'ko') {
                regionCode = 'KR'
                } else if (lang === 'ja') {
                regionCode = 'JP'
                } else if (lang === 'zh') {
                regionCode = 'CN'
                } else {
                regionCode = 'US'
                }

            let timeZone = getTimeZone(regionCode)
            const zonedDate = new Date(new Date(originalTime * 1000).toLocaleString('en-US', { timeZone:timeZone }));

            zonedDate.getTime() / 1000;
            const year = zonedDate.getFullYear();
            const month = (zonedDate.getMonth() + 1).toString().padStart(2, '0'); // 월은 0부터 시작하므로 +1 필요, 두 자리로 패딩
            const day = zonedDate.getDate().toString().padStart(2, '0'); // 날짜, 두 자리로 패딩
            const hours = zonedDate.getHours().toString().padStart(2, '0'); // 시간, 두 자리로 패딩
            const minutes = zonedDate.getMinutes().toString().padStart(2, '0'); // 분, 두 자리로 패딩
            let formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;
            return formattedDateTime;
        }
    },
      crosshair: {
        mode: 0,
      },
      rightPriceScale: {
        visible: false,
      }
    }));

    const areaOptions = computed(() => ({
      priceScaleId: 'right',
      priceFormat: {
        type: 'price',
        precision: 2,
        minMove: 0.01,
      },
      lineColor: '#fff',
      topColor: '#707070',
      bottomColor: '#2D2D2D',
      lastValueVisible: false,
      pointMarkersVisible: true,
      lineWidth: 1,
      priceLineVisible: false,
    }));

    const loadDominance = async () => {
      await store.dispatch('dominance_chart/loadDominance');
    };

    const loadDominanceMini = async () => {
      await store.dispatch('dominance_mini/loadDominanceMini');
    };

    const changeMini = (chart) => {
      store.commit('dominance_mini/changeMini', chart);
    };

    const setChartVisibleRange = (chart) => {
      if (!goyaChart.value || goyaChart.value.length === 0) return;

      const firstTime = goyaChart.value[0].time;
      const lastTime = goyaChart.value[goyaChart.value.length - 6].time;

      chart.timeScale().setVisibleRange({ from: firstTime, to: lastTime });
    };

    const updateLegend = (value) => {
      if (!goyaChart.value || goyaChart.value.length < 2) return;

      const currentIndex = goyaChart.value.findIndex(item => item.value === value);
      if (currentIndex === -1 || currentIndex === 0) return;

      const lastValue = goyaChart.value[currentIndex].value;
      const previousValue = goyaChart.value[currentIndex - 1].value;

      const percentageChange = ((lastValue - previousValue) / 100 * 100).toFixed(2);
      const direction = percentageChange >= 0 ? '+' : '';
      const color = percentageChange >= 0 ? 'var(--text-green)' : 'var(--text-red)';
      const arrowImage = percentageChange >= 0
        ? require('@/assets/images/dominance_images/arrow_blue.png')
        : require('@/assets/images/dominance_images/arrow_red.png');

      if (legend.value) {
        legend.value.innerHTML = `
          <p class="goya-point">${lastValue.toFixed(1)}<span>p</span></p>
          <p class="goya_percent add-image" style="color:${color}">
            ${direction}${percentageChange}p
            <img src="${arrowImage}" alt="${direction} change" width="10px" " style="margin-top: 5px;"/>
          </p>
        `;
      }
    };

    const openMini = async (chart) => {
      await loadDominanceMini();
      changeMini(chart);
    };

    const initializeChart = async () => {
      if (goyaChart.value && goyaChart.value.length && chart.value) {
        const chartInstance = createChart(chart.value, chartOptions.value);
        const areaSeries = chartInstance.addAreaSeries(areaOptions.value);
        areaSeries.setData(goyaChart.value);
        setChartVisibleRange(chartInstance) / 2;
        updateLegend();

        chartInstance.subscribeCrosshairMove((param) => {
          if (!param || !param.time) {
            updateLegend();
          } else {
            const point = param.seriesData.get(areaSeries);
            if (point) {
              updateLegend(point.value);
            }
          }
        });

        if (goyaChart.value.length > 1) {
          const lastValue = goyaChart.value[goyaChart.value.length - 1].value;
          updateLegend(lastValue);
        }
      }
    };

    const removeLogoLink = () => {
      const chartElement = chart.value;
      if (chartElement) {
        const logoLink = chartElement.querySelector('a#tv-attr-logo');
        if (logoLink) {
          logoLink.remove();
        }
      }
    };


    onMounted(async () => {
      await loadDominance();
      if (loadDomi.value) {
        await initializeChart();
        removeLogoLink();
      }
      
    });

    watch(loadDomi, async (newVal) => {
      if (newVal) {
        await initializeChart();
        removeLogoLink();
      }

    });

    return {
      chart,
      legend,
      goyaChart,
      loadDomi,
      openMini
    };
  },
};
</script>
