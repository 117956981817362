<template>
    <div>
      <div class="header_bg">
        <div id="screen__pc">
          <div class="header_container dominance">
            <div class="logo_box">
            </div>
            <div class="logo_box">
              <img @click="goHome" src="@/assets/images/dominance_images/logo.png" class="web" style="cursor:auto">
            </div>
            <div class="select-wrapper">
              <div class="nice-select select" tabindex="0">
                <span class="current-close" @click="changeModal" v-if="!modalChk">{{ selectLang }}</span>
                <span class="current" @click="changeModal" v-else>{{ selectLang }}</span>
                <ul class="list" v-if="modalChk">
                  <li class="option" @click="changLang('ja')">JP</li>
                  <li class="option" @click="changLang('ko')">KR</li>
                  <li class="option" @click="changLang('en')">US</li>
                  <li class="option" @click="changLang('zh')">CN</li>
                  <!-- <li class="option" @click="changLang('vi')">VN</li> -->
                  <!-- <li class="option" @click="changLang('ru')">RU</li> -->
                  <!-- <li class="option" @click="changLang('mn')">MN</li> -->
                </ul>
              </div>
              <button class="login-btn" @click="goLogin">{{ $t('login') }}</button>
            </div>
          </div>

        </div>
        <div id="screen__mobile" class="header_container dominance">
          <img @click="modalClick" src="@/assets/images/dominance_images/menu_icon.png" style="cursor: auto; width: 15px; height: 15px;">
          <img @click="goHome" src="@/assets/images/dominance_images/logo.png" style="cursor:auto; width: auto; height: 30px;">
          <div style="width: 15px;"></div>
        </div>
      </div>
      <div id="screen__mobile" class="menu__modal" v-if="showModal">
        <div class="menu__modal--top">
          <div @click="goRetriM" class="menu__modal--login">Log in</div>
          <div style="display: flex; flex-direction: row; align-items: center; gap: 10px;">
            <div class="modal select_wrapper">
              <div class="nice-select select" tabindex="0">
                <span class="current-close" @click="changeModal" v-if="!modalChk">{{ selectLang }}</span>
                <span class="current" @click="changeModal" v-else>{{ selectLang }}</span>
                <ul class="list" v-if="modalChk">
                  <li class="option" @click="changLang('ja')">JP</li>
                  <li class="option" @click="changLang('ko')">KR</li>
                  <li class="option" @click="changLang('en')">US</li>
                  <li class="option" @click="changLang('zh')">CN</li>
                  <!-- <li class="option" @click="changLang('vi')">VN</li> -->
                  <!-- <li class="option" @click="changLang('ru')">RU</li> -->
                  <!-- <li class="option" @click="changLang('mn')">MN</li> -->
                </ul>
              </div>
            </div>
            <img @click="closeClick" src="@/assets/images/dominance_images/close_button.png" alt="" style="width: 15px; height: 15px; margin-bottom: 3px;">
          </div>
        </div>
        <div class="menu__modal--body">
          <router-link @click="closeClick" class="menu__modal--link" to="/dominance">
            <img src="@/assets/images/dominance_images/nav_menu.svg" alt=""> {{ $t('dominance.goyaDominance') }}
          </router-link>
          <router-link @click="closeClick" class="menu__modal--link" to="/score">
            <img src="@/assets/images/dominance_images/nav_list.svg" alt=""> {{ $t('score.goyaList') }}
          </router-link>
          <router-link @click="closeClick" class="menu__modal--link" to="/brief">
            <img src="@/assets/images/dominance_images/nav_brief.svg" alt=""> {{ $t('brief.brief') }}
          </router-link>
          <router-link @click="closeClick" class="menu__modal--link" to="/volume">
            <img src="@/assets/images/dominance_images/nav_analysis.svg" alt=""> {{ $t('analysis.analysis') }}
          </router-link>
          <router-link @click="closeClick" class="menu__modal--link" to="/signal">
            <img src="@/assets/images/dominance_images/nav_ls.svg" alt=""> {{ $t('signal.signal') }}
          </router-link>
          <router-link @click="closeClick" class="menu__modal--link" to="/clearing">
            <img src="@/assets/images/dominance_images/nav_liq.svg" alt=""> {{ $t('liq.liq') }}
          </router-link>
          <router-link @click="closeClick" class="menu__modal--link" to="/indicator">
            <img src="@/assets/images/dominance_images/nav_bubble.svg" alt=""> {{ $t('indi.indi') }}
          </router-link>
          <router-link @click="closeClick" class="menu__modal--link" to="/chain">
            <img src="@/assets/images/dominance_images/nav_netflow.svg" alt=""> {{ $t('chain.chain') }}
          </router-link>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import "@/assets/css/setting.css"
  import "@/assets/css/dominance.css"
  import "@/assets/css/header.css"
  import "@/assets/css/reset.css"
  import "@/assets/css/mobile.css"
  
  import { useRouter } from 'vue-router';
  // import { useRoute, useRouter } from 'vue-router';
  import { useI18n } from "vue-i18n";
  import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';

  
  export default {
    name: 'App',
    setup() {
      const router = useRouter();
      const { locale } = useI18n({ useScope: "global" });
      const store = useStore();
      let lang = ref(store.state.dominance.lang);

      const updateLocale = (newLang) => {
        store.commit('dominance/changeLang', newLang);
        lang.value = newLang;
        localStorage.setItem('lang', newLang);
        locale.value = newLang;
      };
  
      onMounted(() => {
          updateLocale(lang.value);
      });
  
      const selectLang = computed(() => {
        switch (lang.value) {
          case 'ko': return 'KR';
          case 'en': return 'US';
          case 'ja': return 'JP';
          case 'zh': return 'CN';
          case 'vi': return 'VN';
          case 'ru': return 'RU';
          case 'mn': return 'MN';
          default: return 'US';
        }
      });
  
      const modalChk = ref(false);
      const showModal = ref(false);
  
      function changLang(newLang) {
        updateLocale(newLang);
        modalChk.value = false;
        router.go()
        // location.reload();
      }
  
      function changeModal() {
        modalChk.value = !modalChk.value;
      }
  
      function goHome() {
        router.push('/dominance')
      }
  
      function modalClick() {
        showModal.value = true;
      }
  
      function closeClick() {
        showModal.value = false;
        modalChk.value = false;
      }
  
      function goRetri() {
        window.open("https://www.tryex.io/index.asp");
      }

      function goRetriM() {
        window.open("https://www.tryex.io/m/");
      }
  
      return {
        locale,
        selectLang,
        modalChk,
        showModal,
        changLang,
        changeModal,
        goHome,
        modalClick,
        closeClick,
        goRetri,
        goRetriM
      };
    },
    methods: {
      goLogin() {
        window.open("https://www.tryex.io/index.asp")
      },
    }
  }
  </script>
  
<style>
.logo_box{
  width: 80% !important;
}

.header_container img {
  width: auto;
  height: 40px;
  padding-left: 58px;
}

</style>
  