import { API } from "./apiAuth";

// Fetch chain chart data API
const getChain = async () => {
    try {
        const response = await API.get('netflow_score_chart');  // Adjust the endpoint as needed
        return response.data;
    } catch (error) {
        console.error("Error fetching chain chart data: ", error);
        throw error;
    }
};

export default {
    getChain,
};
