
import api from "@/api/apiSignal";
import { formatVolume } from "@/utils/changeUnit";
import { getTimeZone, convertTimeZone } from "@/utils/localeTime";

export default {
    namespaced: true,
    state() {
      return {
      // 변수
      loadGraph: false,

      // 응답
      ratioData: [],
      positionData: [],

      // 요청
        time : "1h",
        pageInfo: {
            page : 1,
            size: 20
          },
      }
    },
    mutations: {    
      changSignalTime(state, time) {
          state.time = time
      },
      changeLoad(state, value) {
        state.loadGraph = value
    },
    }, 
    actions: {
      // ratio chart data 조회 api
      async loadRatioData(context) {
        try {
            let response = await api.getRatio(context.state.time);
            let long = []
            let short = []
            let price = []
            let datatime = []

            let ratioData = response.results.reverse();

            const getDate = (dateTime) => { 
              let lang = localStorage.getItem("lang");
              let regionCode

              if (lang === 'ko') {
                regionCode = 'KR'
              } else if (lang === 'ja') {
                regionCode = 'JP'
              } else if (lang === 'zh') {
                regionCode = 'CN'
              } else {
                regionCode = 'US'
              }

            const timeZone = getTimeZone(regionCode);
            const localeDate = convertTimeZone(dateTime, timeZone);

            // Format the date
            const year = localeDate.getFullYear();
            const month = String(localeDate.getMonth() + 1).padStart(2, "0");
            const day = String(localeDate.getDate()).padStart(2, "0");

            // // Format the time
            const hours = String(localeDate.getHours()).padStart(2, "0");
            const minutes = String(localeDate.getMinutes()).padStart(2, "0");
            let nowDate = `${year}.${month}.${day} ${hours}:${minutes}`;

            return nowDate
          }

          for (let ratio of ratioData) {
              long.push(ratio.longac);
              short.push(ratio.shortac);
              price.push(ratio.price);

              let changeTime = getDate(ratio.datetime)
              datatime.push(changeTime)
          }

          let maxPrice = Math.max(...price);
          let minPrice = Math.min(...price);

          const roundToSecondDigit = (num, direction) => {
              const factor = 10000; 
              return direction === 'ceil'
                ? Math.ceil(num / factor) * factor
                : Math.floor(num / factor) * factor;
          };

          let max = roundToSecondDigit(maxPrice, 'ceil')
          let min = roundToSecondDigit(minPrice, 'floor')

          let range = `${min}:${max}:2000`

          context.state.ratioData.range = range
          context.state.ratioData.long = long
          context.state.ratioData.short = short
          context.state.ratioData.price = price
          context.state.ratioData.datatime = datatime
        } catch (error) {
            console.error("Error store get ratio:", error);
            return;
        }
      },
      // L/S ratio coin list 조회 api
      async loadPosition(context) {
        try {
            let page = context.state.pageInfo
            let response = await api.getPostiion(page.page, page.size);
            for (let data of response.results) {
              data.longVal = data.longvolume
              data.shortVal = data.shortvolume
              data.longvolume = "$" + formatVolume(data.longvolume)
              data.shortvolume = "$" + formatVolume(data.shortvolume)
            } 
            context.state.positionData = response.results;
            context.state.loadGraph = true;

          
        } catch (error) {
            console.error("Error get spot lost:", error);
            return;
        }
      },
    },
  }
  