<template>
  <div :id="chartId"></div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, computed, watch, nextTick, getCurrentInstance } from 'vue';
import zingchart from 'zingchart';
import 'zingchart/modules-es6/zingchart-treemap.min.js';
import { mapActions, mapState } from 'vuex';
import { useStore } from 'vuex';

export default {
  name: 'RatioChartMobile',
  setup() {
    const { proxy } = getCurrentInstance();
    const chartId = ref('zingchart-' + Math.random().toString(36).substr(2, 9));
    const store = useStore();
    const ratioInfo = computed(() => store.state.signal.ratioData);
    const ratioRange = computed(() => store.state.signal.range);
    const language = computed(() => store.state.dominance.lang);

    const getTranslatedText = () => {
        return {
          long: proxy.$t('signal.long'),
          short: proxy.$t('signal.short'),
          btcPrice: proxy.$t('pub.btcPrice'),
        };
      };

    const translatedText = getTranslatedText();

    const chartData = ref({
      type: "mixed",
      globals: {
        fontFamily: "var(--main-font-Light)",
        fontColor: 'var(--color-main)', 
      },
      gui: {
        contextMenu: {
          visible: false
        }
      },
      backgroundColor: "transparent",
      legend: {
        layout: 'x3',
        backgroundColor: 'transparent',
        borderColor: 'none',
        overflow: 'page',
        alpha: 0.05,
        shadow: false,
        align: 'center',
        marker: {
          'border-color': 'none',
          size: '8px',
        },
        item: {
          fontColor: '#FFFFFF',
        },
        'toggle-action': 'none'
      },
      responsive: [{
        breakpoint: 1400,
        options: {
          'height': '300px',  // 1400px 이하에서 높이 조정
        }
      }],
      'scale-x': {
        values: [],
        guide: {
          visible: false,
        },
      },
      'scale-y': {
        values: "0:100:25",
        guide: {
          visible: false,
        },
      },
      'scale-y-2': {
        guide: {
          visible: false,
        },
        "thousands-separator": ","
      },
      'crosshair-x': {
        marker: {
          visible: false,
        },
        offsetY: '10%',
        plotLabel: {
          fontColor: "var(--color-main)",
          fontFamily: "var(--main-font-Light)",
          text: "",
        },
      },
      plot: {
        tooltip: {
          visible: false,
        },
        hoverState: {
          visible: false,
        },
        highlightState: {
          visible: false,
        }
      },
      series: [
        {
          type: "bar",
          text: translatedText.long,
          scales: "scale-x,scale-y",
          borderRadiusBottomLeft: '30px',
          borderRadiusBottomRight: '30px',
          values: [],
          'background-color': "var(--signal-green1) var(--signal-green2)",
          stacked: true,
          stack: 0.1,
          "bar-width": "100%",
          "guide-label": {
              "backgroundColor": "var(--background-chart2)",
              "text": "%t: %v %",
          }
        },
        {
          type: "bar",
          text: translatedText.short,
          scales: "scale-x,scale-y",
          borderRadiusTopLeft: '30px',
          borderRadiusTopRight: '30px',
          values: [],
          'background-color': "var(--signal-red2) var(--chart-red)",
          stacked: true,
          stack: 0.1,
          "bar-width": "100%",
          plot: {
            "value-box": {
              visible: false,
            },
          },
          "guide-label": {
            "text": "%t: %v %",
          }
        },
        {
          type: "line",
          text: translatedText.btcPrice,
          legendMarker: {
            'border-width': 1,
            'border-color': "var(--color-main)",
            type: "circle",
            'show-line': true,
            'line-color': "var(--color-main)",
            'size': '7px',
          },
          "line-color": "var(--color-main)",
          "line-width": 3,
          scales: "scale-x,scale-y-2",
          values: [],
          marker: {
            "background-color": "var(--color-main)",
            "border-width": 2,
            shadow: 0,
            "border-color": "var(--color-main)",
          },
          plot: {
            "value-box": {
              visible: false,
            },
          },
          formatValue: [],
          guideLabel: {
            text: '%t: $%v',
            "thousands-separator": ","
          },
        },
      ],
    });


    const renderChart = () => {
      zingchart.render({
        id: chartId.value,
        data: chartData.value,
        height: '450px',
        width: '100%',
        contextMenu: {
          visible: false
        }
      });
    };

    const fontLang = () => {
        if (language.value === 'ja') {
          return "Noto Sans JP"
        } else if (language.value === 'zh') {
          return "Noto Sans SC"
        } else {
          return "var(--main-font-Light)"
        }
      }

    const updateChartData = (newData) => {
      chartData.value.globals.fontFamily = fontLang();
      chartData.value['crosshair-x'].fontFamily = fontLang();
      const limitedDataCount = 8;
      const longData = newData.long.slice(0, limitedDataCount);
      const shortData = newData.short.slice(0, limitedDataCount);
      const priceData = newData.price.slice(0, limitedDataCount);
      const datatimeData = newData.datatime.slice(0, limitedDataCount);

      chartData.value.series[0].values = longData;
      chartData.value.series[1].values = shortData;
      chartData.value.series[2].values = priceData;
      chartData.value.series[2].formatValue = priceData.map(value => value.toLocaleString());
      chartData.value['scale-x'].values = datatimeData;

      renderChart();
    };
    
    const removeBtn = () => {
      nextTick(() => {
          const toolBar = document.querySelector(`#${chartId.value}-trigger-c`);
          if (toolBar) {
          toolBar.style.display = 'none'; // 요소 숨기기
          }
      });
    }

    const loadAndUpdateChart = async () => {
      await store.dispatch('signal/loadRatioData');  // 액션을 디스패치하여 데이터 로드
      await nextTick();
      if (ratioInfo.value) {
        updateChartData(ratioInfo.value);
      }
      removeBtn();
    };

    onMounted(async () => {
      await loadAndUpdateChart();
    });

    onBeforeUnmount(() => {
      zingchart.exec(chartId.value, 'destroy');
    });

    watch(() => store.state.signal.time, async () => {
      await loadAndUpdateChart();
    });

    watch(ratioRange, (newRange) => {
      if (newRange && chartData.value) {
        chartData.value['scale-y-2'].values = newRange;
        renderChart(); // 차트 다시 렌더링
      }
    });

    return {
      chartId,
    };
  },
  computed: {
    ...mapState("signal", ["ratioData", "time", "range"]),
  },
  methods: {
    ...mapActions("signal", ["loadRatioData"]),
  },
};
</script>

<style>
#chartId {
  width: 100%;
  height: 500px;
}

@media (max-width: 1400px) {
  .chartId {
    height: 300px;
    /* 1400px 이하에서 높이 조정 */
  }
}
</style>
