
import api from "@/api/apiIndicator";

export default {
    namespaced: true,
    state() {
      return {
        // 변수
        chkRain: false,
        chkRsi: false,

        // 응답
        chartRsi: {},
        chartRainbow: {},
        graphRsi: [],

        // 요청
        time : "1",
      }
    },
    mutations: {    
        changeRain(state, value) {
            state.chkRain = value;
        },
        changeRsi(state, value) {
            state.chkRsi = value;
        },
        changeTime(state, value) {
            state.time = value;
        },
    }, 
    actions: {
      // rsi chart data 조회 api
      async loadRsiData(context) {
        try {
            let response = await api.getRsiChart(Number(context.state.time));

            context.state.chartRsi = {
                symbol: [],
                symbolWidth: [],
                coinInfo: [],
                rsi: 0,
            }

            let info = context.state.chartRsi

            let rsi = response.results.slice(0, 20)
            let rsi_fixed= {symbol: '', rsi: '0', rsi_dev: '', price: '', pricechangepercent: ''}
            rsi.unshift(rsi_fixed)

            for (let i = 0; i < rsi.length; i++) {
                const data = rsi[i];
                info.symbol.push(data.symbol);
                if (data.symbol === '' ) {
                    info.symbolWidth.push([0, Number(data.rsi), 7])
                } else {
                    info.symbolWidth.push([(i-1)*50+25, Number(data.rsi), 1])
                }
                info.coinInfo.push(
                    {
                        'symbol': data.symbol,
                        'rsi': data.rsi,
                        'price': data.price,
                        'pricechangepercent': data.pricechangepercent 
                    }
                )
            }
            info.rsi = [response.rsi_avg, response.rsi_avg, response.rsi_avg]

            context.state.chkRsi = true;
        } catch (error) {
            console.error("Error get rsi chart data:", error);
            return;
        }
      },

      // rainbow chart data 조회 api
      async loadRainbow(context) {
        try {
            let response = await api.getRrainbowChart();
            
            context.state.chartRainbow = {
                btc: [],
                acc: [],
                max: [],
                sell: [],
                fomo: [],
                buy: [],
                fire: [],
                timeValue: [],
                price: [],
            };
    
            let info = context.state.chartRainbow;
            let reData = response.results;


            const arraysToProcess = [
                'accumulate', 'maximumbubble', 'sell_seriously',
                'fomo', 'buy', 'firesale', 'btcprice'
            ];
    
            // datetime을 기준으로 데이터를 그룹화
            let groupedData = {};
    
            arraysToProcess.forEach(key => {
                reData[key].forEach(item => {
                    if (!groupedData[item.datetime]) {
                        groupedData[item.datetime] = {};
                    }
                    groupedData[item.datetime][key] = item.value;
                });
            });

            const getDate = (dateTime) => { 
                const date = new Date(dateTime* 1000);
                const year = date.getUTCFullYear();
                const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1 필요
                const day = String(date.getUTCDate()).padStart(2, '0');

                return `${year}-${month}-${day}`
            }
           
            for (let datetime in groupedData) {
                let changeTime = getDate(datetime)
                info.timeValue.push(changeTime)
    
                let data = groupedData[datetime];
    
                if (data.btcprice && Number(data.btcprice) !== 0.0) {
                    let priceNum = Number(data.btcprice) + (Number(data.btcprice)*0.03)
                    let btcResult = parseFloat(priceNum.toFixed(4))
                    info.btc.push(btcResult);
                }

                arraysToProcess.forEach(key => {
                    if (data[key] !== undefined) {
                        let priceChange = Number(data[key]) + (Number(data[key])*0.03)
                        let rangeResult = parseFloat(priceChange.toFixed(4))
                        let rangeNum = parseFloat(rangeResult.toFixed(4))

                        let adjustedValue = rangeNum * (1.7 || 0);
                        let valueArray = [adjustedValue, rangeNum];
                        
                        info.price.push(rangeNum);
                        
                        switch (key) {
                            case 'accumulate':
                                info.acc.push(valueArray);
                                break;
                            case 'maximumbubble':
                                info.max.push(valueArray);
                                break;
                            case 'sell_seriously':
                                info.sell.push(valueArray);
                                break;
                            case 'fomo':
                                info.fomo.push(valueArray);
                                break;
                            case 'buy':
                                info.buy.push(valueArray);
                                break;
                            case 'firesale':
                                info.fire.push(valueArray);
                                break;
                        }
                    }
                });
            }
    
            context.state.chkRain = true;
        } catch (error) {
            console.error("Error get rainbow chart data:", error);
            return;
        }
      },
      // rsi 표 data 조회 api
      async loadRsi(context) {
        try {
            let response = await api.getRsi();

            for (let i = 0; i < response.results.length; i++) {
                response.results[i].rank = i+1
                response.results[i].priceval = response.results[i].price;
                response.results[i].price = Number(response.results[i].price).toLocaleString();
              }

            context.state.graphRsi = response.results
        } catch (error) {
            console.error("Error get rsi data:", error);
            return;
        }
      },
    },
  }
  