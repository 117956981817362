<template>
  <div :class="fontClass">
    <HeaderView />
    <LeftNav id="screen__pc" />
    <router-view></router-view>
  </div>
</template>

<script>
import "@/assets/css/setting.css"
import "@/assets/css/dominance.css"
import "@/assets/css/header.css"
import "@/assets/css/reset.css"
import "@/assets/css/mobile.css"

import LeftNav from "@/components/LeftNav.vue"
import HeaderView from "@/components/HeaderView.vue"

import { useI18n } from "vue-i18n";
import { mapMutations } from 'vuex'


export default {
  name: 'App',
  components: {
    LeftNav,
    HeaderView
  },
  setup() {
    const { t, locale } = useI18n({ useScope: "global" });
    return {
      t,
      locale,
    };
  },
  data() {
    return {
      lang: localStorage.getItem("lang") || "en",
    }
  },
  async created() {
    window.updateLocale = this.updateLocale.bind(this);
    await this.updateLocale();
  },
  computed: {
    mainPath() {
      return this.$route.path.split("/")[1];
    },
    fontClass() {
      if (this.lang === 'ko') {
        return 'ko';
      } else if (this.lang === 'ja') {
        return 'ja';
      } else if (this.lang === 'zh') {
        return 'zh';
      } else {
        return 'en';
      }
    }
  },
  methods: {
    ...mapMutations("analysis", ["changeLoad", "changeType"]),
    ...mapMutations("dominance", ["changeSigLoad", "changeWarn"]),
    ...mapMutations("dominance_chart", ["changeDomi", "changeMarket"]),
    ...mapMutations("indicator", ["changeRain", "changeRsi", "changeTime"]),
    ...mapMutations("signal", [ "changeLoad", "changSignalTime"]),
    ...mapMutations("clearing", [ "changeDay"]),

    async updateLocale() {
      localStorage.setItem('lang', this.lang);
      this.$i18n.locale = this.lang;
    },
  },
  watch: {
    mainPath() {
      this.changeLoad(false);
      this.changeDomi(false);
      this.changeMarket(false);
      this.changeSigLoad(false);
      this.changeLoad(false);
      this.changeRain(false);
      this.changeRsi(false);
      this.changeLoad(false);
      this.changeDay("1d");
      this.changeTime("1");
      this.changSignalTime("1h")
      this.changeType("volume")
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style></style>