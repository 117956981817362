import { API4 } from "./apiAuth";


// Function to get AI Brief Title
const getAiBriefTitle = async (timetype, limit) => {
    try {
        const response = await API4.get(
        `analysis/gpt-title-not?timetype=${timetype}&limit=${limit}`
        );
       return response.data.result
    } catch (error) {
        console.error("Error get AI Brief", error);
        return;
    }
};

// Function to get AI Brief
const getAiBrief = async (id, language) => {
    try {
        const response = await API4.get(
        `analysis/gpt-languge?id=${id}&language=${language}`
        );
        return response.data;
    } catch (error) {
        console.error("Error get AI Brief", error);
        return;
    }
};

export default {
    getAiBriefTitle,
    getAiBrief
}

