<template>
    <div class="title_container">
        <div class="list_content">
            <div class="analysis_title" v-if="mainPath === 'volume'">
                <h1>
                    {{ getTitle() }} 
                </h1>
                <img  @click="openTitleGuide(1)"  class="analysis_guide_img" src="@/assets/images/dominance_images/analysis_guide.png" />
            </div>
            <div class="main_title" v-else>
                <h1>
                    {{ getTitle() }} 
                </h1>
            </div>
            <div class="title_update">
                <img src="@/assets/images/dominance_images/update_calendar_icon.png">
                <p class="update_time">{{ nowTime }}</p>
            </div>
        </div>
    </div>
    <div v-if="titleModal">
        <div class="modal_overlay"></div>
        <div class="guide_wrapper" :class="[{wide_guide: widePage}, {wide_guide_jp: wideJp}]">
            <div class="close_guide">
                <!-- <h1>{{ $t('guide.guide') }}</h1> -->
                <button class="guide_close_btc" >
                    <img @click="closeModal" src="@/assets/images/dominance_images/close_icon.png" />
                </button>
            </div>
            <div class="guide_content">
                <h1 class="guide_title">{{ getTitle() }}</h1>
                <div class="guide_ment"  v-html="getGuide()">
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { getTimeZone } from "@/utils/localeTime";

export default{
    name: "TitleView",
    data() {
        return {
            nowTime: "",
            titleModal: false,
        }
    },
    components: {
    },
    created() {
        this.getTime();
    },
    computed: {
        ...mapState("dominance", ["guideModal"]),

        mainPath() {
            return this.$route.path.split("/")[1];
        }
    },
    methods: {
        ...mapMutations("dominance", ["changeGuide"]),

        getTitle() {
            if (this.mainPath === 'dominance') {
                return this.$t("dominance['goyaDominance']")
            } else if (this.mainPath === 'volume') {
                return this.$t("analysis['title']")
            } else if (this.mainPath === 'signal') {
                return this.$t("signal['signal']")
            } else if (this.mainPath === 'clearing') {
                return this.$t("liq['liq']")
            } else if (this.mainPath === 'indicator') {
                return this.$t("indi['indi']")
            } else if (this.mainPath === 'chain') {
                return this.$t("chain['chain']")
            } else if (this.mainPath === 'brief') {
                return this.$t("brief['brief']")
            } else if (this.mainPath === 'score') {
                return this.$t("score['goyaList']")
            }
        },

        getTime() {
            let lang = localStorage.getItem("lang");
            let regionCode

            if (lang === 'ko') {
            regionCode = 'KR'
            } else if (lang === 'ja') {
            regionCode = 'JP'
            } else if (lang === 'zh') {
            regionCode = 'CN'
            } else {
            regionCode = 'US'
            }

            const timeZone = getTimeZone(regionCode);
            let dateTime = new Date();
            const options = {
            timeZone: timeZone, // 예: 'Asia/Seoul', 'Asia/Tokyo', 'Asia/Shanghai'
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false // 24시간 포맷
        };

        const formatter = new Intl.DateTimeFormat([], options);
        const formattedDateTime = formatter.formatToParts(dateTime);

        const year = formattedDateTime.find(part => part.type === 'year').value;
        const month = formattedDateTime.find(part => part.type === 'month').value;
        const day = formattedDateTime.find(part => part.type === 'day').value;
        const time = formattedDateTime.find(part => part.type === 'hour').value;
        
        this.nowTime = `${year}.${month}.${day} ${time}:00`;

        },

        openTitleGuide() {
            this.titleModal = true;
        },

        
        getGuide() {
            return this.$t("guide['analysis']['text']")
        },

        closeModal() {
            this.titleModal = false;
        }
    }
}
</script>

<style scoped>
.guide_img {
    margin-top: 0.5vw;
}
</style>