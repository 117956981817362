import { API } from "./apiAuth";


// rsi chart data 조회 api
const getRsiChart = async (limit) => {
  try {
    const response = await API.get(`rsi_derivate?limit=${limit}`);
    return response.data;
  } catch (error) {
    console.error("Error get rsi chart data: ", error);
    throw error;
  }
};

// rainbow chart data 조회 api
const getRrainbowChart = async () => {
  try {
    const response = await API.get(`rainbow_score_chart`);
    return response.data;
  } catch (error) {
    console.error("Error get rainbow chart data: ", error);
    throw error;
  }
};

// rsi 표 데이터 조회 api
const getRsi = async () => {
  try {
    const response = await API.get(`rsi_value_chart?page=1&size=300`);
    return response.data;
  } catch (error) {
    console.error("Error get rsi data: ", error);
    throw error;
  }
};


export default {
    getRsiChart,
    getRrainbowChart,
    getRsi,
};


