<template>
  <div class="mainWrap list">
    <div class="subCont whiteDoc">
      <TitleView />
      <div class="wrapper">
        <div class="clearing-title">
          <div id="screen__pc">
            <div class="sub-title">
              <img src="@/assets/images/dominance_images/logo.png" class="logo__img"/>
              <h2>
                {{ $t('liq.liqMAp') }}
              </h2>
              <img class="guide_img" @click="openGuide()" src="@/assets/images/dominance_images/chart_desc_icon.png" />
            </div>
        </div>
          <div id="screen__mobile">
            <div class="sub-title">
              <img src="@/assets/images/dominance_images/logo.png" class="logo__img"/>
              <h2>
                {{ $t('liq.liqMAp') }}
              </h2>
              <img class="guide_img_mobile" @click="openGuide()" src="@/assets/images/dominance_images/chart_desc_icon.png" />
            </div>
        </div>

        </div>

        <!-- 청산 차트 -->
        <div id="screen__pc" class="clearing-chart-wrapper" style="width: 100%;">
          <div class="select-date">
            <select v-model="coinDate">
              <option value="1d">1D</option>
              <option value="7d">7D</option>
            </select>
          </div>
          <ClearingChart />
        </div>
        <div id="screen__mobile" class="clearing-chart-wrapper">
          <div class="select-date">
            <select v-model="coinDate">
              <option value="1d">1D</option>
              <option value="7d">7D</option>
            </select>
          </div>
          <ClearingChartMobile />
        </div>
      </div>
      <!-- <div class="fixed_back"></div> -->
    </div>
  </div>
  <GuideModal v-if="guideModal" />

</template>

<script>
import "@/assets/css/clearing.css"
import TitleView from "@/components/public/TitleView.vue"
import ClearingChart from "@/components/clearing/chart/ClearingChart.vue"
import GuideModal from "@/components/public/GuideModal.vue"
import ClearingChartMobile from "./chart/ClearingChartMobile.vue";

import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: 'ClearingView',
  data() {
    return {
      coinDate: "1d",
    }
  },
  components: {
    TitleView,
    ClearingChart,
    GuideModal,
    ClearingChartMobile
  },
  computed: {
    ...mapState("dominance", ["guideModal"]),

  },
  methods: {
    ...mapMutations("dominance", ["changeGuide"]),
    ...mapMutations("clearing", ["changeDay"]),
    ...mapActions("clearing", ["loadClearing"]),

    openGuide(type) {
      this.cocntentType = type;
      this.changeGuide(true);
    },

  },
  watch: {
    coinDate(newVal) {
      this.changeDay(newVal);
      this.loadClearing(newVal);
    },
  }
}
</script>
