
import api from "@/api/apiClearing";

export default {
    namespaced: true,
    state() {
      return {
      // 변수
      loadingChk: false,

      // 응답
      clearingData: [],

      // 요청
        day: "1d",
      }
    },
    mutations: {    
      changeDay(state, date) {
        state.day = date;
      },
      changeLoad(state, value) {
        state.loadingChk = value;
      },
    }, 
    actions: {
      // ratio chart data 조회 api
      async loadClearing(context) {
        try {
          let response = await api.getLiq(context.state.day);
          let clearingData = context.state.clearingData
          let lev10 = [];
          let lev25 = [];
          let lev50 = [];
          let lev100 = [];

          let totalVol = [];
          let totalPrice = [];
          let btcPrice = [];

          for (let data of response.results) {
            // 각 레버리지별 volume 나눠주는 부분
            if (data.lever === 10) {
              lev10.push([Number(data.price), Number(data.volume)])
              totalVol.push(Number(data.volume))
              totalPrice.push(Number(data.price))
            } else if (data.lever === 25) {
              lev25.push([Number(data.price), Number(data.volume)])
                totalVol.push(Number(data.volume))
              totalPrice.push(Number(data.price))
            } else if (data.lever === 50) {
              lev50.push([Number(data.price), Number(data.volume)])
                totalVol.push(Number(data.volume))
              totalPrice.push(Number(data.price))
            } else if (data.lever === 100) {
              lev100.push([Number(data.price), Number(data.volume)])
                totalVol.push(Number(data.volume))
              totalPrice.push(Number(data.price))
            }
          }
          clearingData.lev10 = lev10
          clearingData.lev25 = lev25
          clearingData.lev50 = lev50
          clearingData.lev100 = lev100
            
          let priceMax = Math.max(...totalPrice);
          let priceMin = Math.min(...totalPrice);

          let volMax = Math.max(...totalVol);
          let volMin = Math.min(...totalVol);

          let maxPrice = Math.ceil(priceMax / 100) * 100
          let minPrice = Math.floor(priceMin / 100) * 100
          let maxVol = Math.ceil(volMax / 1000000) * 1000000
          let minVol = Math.floor(volMin / 10000) * 10000

          let priceRange 

          if (context.state.day === '1d') {
            priceRange = `${minPrice}:${maxPrice}:100`
          } else {
            priceRange = `${minPrice}:${maxPrice}:50`
          }
          let volRange = `${minVol}:${maxVol}:10000`

          btcPrice = [[Number(response.BTC), maxVol], [0, 0]]

          clearingData.btcPrice = btcPrice
          clearingData.priceRange = priceRange
          clearingData.volRange = volRange

          context.state.loadingChk = true;

        } catch (error) {
            console.error("Error store get clearing chart data:", error);
            return;
        }
      },
    },
  }
  