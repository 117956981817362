<template>
    <div class="mainWrap list">
        <div class="subCont whiteDoc">
            <TitleView />
            <div class="wrapper">
                <div class="chain-chart-wrapper">
                    <!-- title -->
                    <div class="chain-title">
                        <div id="screen__pc">
                            <div class="sub-title">
                                <img src="@/assets/images/dominance_images/logo.png" class="logo__img"/>
                                <h2>
                                    {{ $t('chain.inOutFlow') }}
                                </h2>
                                <img class="guide_img" @click="openGuide()" src="@/assets/images/dominance_images/chart_desc_icon.png" />
                            </div>
                        </div>
                        <div id="screen__mobile">
                            <div class="sub-title-bubble">
                                <img src="@/assets/images/dominance_images/logo.png" class="logo__img"/>
                                <h2>
                                    {{ $t('chain.inOutFlow') }}
                                </h2>
                                <img class="guide_img_mobile_bubble" @click="openGuide()" src="@/assets/images/dominance_images/chart_desc_icon.png" />
                            </div>
                        </div>
                    </div>

                    <!-- Netflow and Price Chart -->
                    <div id="screen__pc" class="chain-chart-wrapper" style="width: 100%;">
                        <ChainChart />
                    </div>
                    <div id="screen__mobile" class="chain-chart-wrapper">
                        <ChainChartMobile />
                    </div>
                </div>
            </div>
        </div>
    </div>
  <GuideModal v-if="guideModal" />

</template>

<script>
import "@/assets/css/indicator.css";
import TitleView from "@/components/public/TitleView.vue";
import ChainChart from "@/components/chain/chart/ChainChart.vue";
import ChainChartMobile from "@/components/chain/chart/ChainChartMobile.vue"
import GuideModal from "@/components/public/GuideModal.vue"

import { mapState, mapMutations, mapActions } from "vuex";

export default {
    name: 'ChainView',
    components: {
        TitleView,
        ChainChart,
        ChainChartMobile,
        GuideModal
    },
    created() {
        this.loadChain();
    },
    computed: {
        ...mapState("dominance", ["guideModal"]),
    },
    methods: {
        ...mapMutations("dominance", ["changeGuide"]),
        ...mapActions("chain", ["loadChain"]),

        openGuide() {
            this.changeGuide(true);
        },
    },
};
</script>
