<template>
    <div style="display: flex; flex-direction: column; width: 100%;">
        <div class="ratio-list-header">
            <div class="ratio-list-header-item ratio-list-header-first" @click="sortList('symbol')">
                {{ $t('signal.symbol') }}
                <img :src="getArrowImage('symbol')" />
            </div>
            <div class="ratio-list-header-content">
                <div class="ratio-list-header-item" @click="sortList('long')">
                    {{ $t('signal.LSRatio') }}
                    <img :src="getArrowImage('long')" />
                </div>
                <div class="ratio-list-header-item" @click="sortList('longvolume')">
                    {{ $t('signal.LongVol') }}
                    <img :src="getArrowImage('longvolume')" />
                </div>
                <div class="ratio-list-header-item" @click="sortList('shortvolume')">
                    {{ $t('signal.ShortVol') }}
                    <img :src="getArrowImage('shortvolume')" />
                </div>
            </div>
        </div>
        <div v-if="loadGraph">
            <div class="ratio-content-wrapper" v-if="filteredCoinList.length > 0">
                <div class="ratio-list-content" v-for="position in filteredCoinList" :key="position.symbol">
                    <div class="coin">
                        <img :src="getCoinIcon(position.symbol)" alt="" class="coin-icon">
                        {{ position.symbol }}/USDT
                    </div>
                    <div class="LS-ratio">
                        <div class="ratio_up_bg"
                            :style="{ width: position.long + '%', minWidth: '120px', maxHeight: '20px' }">
                            <p>{{ position.long }}%</p>
                        </div>
                        <div class="ratio_down_bg"
                            :style="{ width: position.short + '%', minWidth: '120px', maxHeight: '20px' }">
                            <p>{{ position.short }}%</p>
                        </div>
                    </div>
                    <div class="long-short-volume">
                        <div class="long-volume">{{ position.longvolume }}</div>
                        <div class="short-volume">{{ position.shortvolume }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="loading_spinner" v-else>
            <div class="loading_circle"></div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
    name: "VsRatioMobile",
    props: {
        positionData: Array,
        searchCoin: String // 검색어를 prop으로 추가
    },
    data() {
        return {
            sortKey: null,  // 현재 정렬된 키
            sortOrder: 1,   // 정렬 순서 (1: 오름차순, -1: 내림차순)
            activeSortKey: null // 현재 활성화된 정렬 키
        };
    },
    computed: {
        ...mapState("signal", ["loadGraph"]),

        filteredCoinList() {
            let sortedList = this.sortedCoinList;
            if (this.searchCoin) {
                sortedList = sortedList.filter(coin =>
                    coin.symbol.toLowerCase().includes(this.searchCoin.toLowerCase())
                );
            }
            return sortedList;
        },

        sortedCoinList() {
            return this.positionData.slice().sort((a, b) => {
                let aValue = a[this.sortKey];
                let bValue = b[this.sortKey];

                if (!isNaN(aValue) && !isNaN(bValue)) {
                    aValue = parseFloat(aValue);
                    bValue = parseFloat(bValue);
                }

                if (aValue < bValue) {
                    return -1 * this.sortOrder;
                }
                if (aValue > bValue) {
                    return 1 * this.sortOrder;
                }
                return 0;
            });
        },
    },
    methods: {
        ...mapActions("analysis", ["loadSpotCoin", "loadFuterCoin"]),

        getCoinIcon(symbol) {
            try {
                return require(`@/assets/images/coin_logos/${symbol.toLowerCase()}.png`);
            } catch {
                return require("@/assets/images/coin_logos/default_dark.png");
            }
        },

        getArrowImage(key) {
            if (this.sortKey === key) {
                return this.sortOrder === 1
                    ? require('@/assets/images/dominance_images/arrow_down.png')
                    : require('@/assets/images/dominance_images/arrow_up.png');
            } else {
                return require('@/assets/images/dominance_images/arrow_down.png');
            }
        },

        sortList(key) {
            if (this.sortKey === key) {
                this.sortOrder *= -1;
            } else {
                this.sortKey = key;
                this.sortOrder = -1;
            }
            this.activeSortKey = key;
        }
    },
    watch: {
        coinTab(val) {
            if (val === 0) {
                this.loadFuterCoin();
            } else if (val === 1) {
                this.loadSpotCoin();
            }
        }
    }
}
</script>

<style>
#chartId {
    width: 100%;
    height: 500px;
}

@media (max-width: 1400px) {
    .chartId {
        height: 300px;
    }
}
</style>
