import { createStore } from "vuex";
import dominance from "./dominance"
import dominance_mini from "./dominance_mini"
import dominance_chart from "./dominance_chart"
import analysis from "./analysis"
import signal from "./signal"
import clearing from "./clearing"
import indicator from "./indicator"
import chain from "./chain"
import brief from "./brief"

const store = createStore({
  modules: {
    dominance,
    dominance_mini,
    dominance_chart,
    analysis,
    signal,
    clearing,
    indicator,
    chain,
    brief
  },
});

export default store;
