<template>
<div>
    <div v-if="modal === true" class="coin-change-btn">
        <button @click="changeCoin('BTC')"><img src="@/assets/images/coin_logos/btc.png"/>  BTC</button>
        <button @click="changeCoin('ETH')"><img src="@/assets/images/coin_logos/eth.png"/> ETH</button>
        <button @click="changeCoin('SOL')"><img src="@/assets/images/coin_logos/sol.png"/> SOL</button>
    </div>
    <div class="container btc" v-if="coinList">
        <div class="btc-wrapper">
            <div class="btc-info">
                <img :src="getImageUrl(coinList.symbol)" @click="openModal()" />
                <p>

                    {{ coinList.symbol }}
                    <br />
                    <span>/{{ coinList.symbolType }}</span>
                </p>
            </div>
            <div class="btc-price-info">
                <div class="btc-price-info-content">
                    <p class="btc-price">
                        <span>${{ coinList.lastPrice }}</span>
                    </p>
                    <div class="dominance_up_down">
                        <p class="btc-change-price add-image"  :style="{ color: coinList.priceChange >= 0 ? 'var(--text-green)' : 'var(--text-red)' }">
                            ${{ coinList.priceChangeTo }}
                            <img :src="coinList.priceChange >= 0 ? require('@/assets/images/dominance_images/arrow_green_mini.png') : require('@/assets/images/dominance_images/arrow_red_mini.png')" />
                        </p>
                        <p class="btc-change-price add-image" :style="{ color: coinList.priceChangePercent >= 0 ? 'var(--text-green)' : 'var(--text-red)' }">
                            {{ coinList.priceChangePercent }}%
                            <img :src="coinList.priceChangePercent >= 0 ? require('@/assets/images/dominance_images/arrow_green_mini.png') : require('@/assets/images/dominance_images/arrow_red_mini.png')" />
                        </p>
                    </div>
                    <div class="bulr_wrapper_price">
                        <div class="price-info-blur">
                            <p v-for="coin in coinFixed" :key="coin">
                                <span data-content="dominance" data-word="score" class="ko">{{coin.name}}</span>
                            </p>
                        </div>
                        <div class="blur_signal_coin">
                                <WarnAuth  class="signal_warn_auth"/>
                        </div>
                    </div>
                </div>

                   
            </div>
            <div  @click="warnOpen">
                <img src="@/assets/images/dominance_images/chart_icon.png">
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import WarnAuth from "@/components/public/WarnAuth.vue"

import arrowRedMini from '@/assets/images/dominance_images/arrow_red_mini.png';
import arrowGreenMini from '@/assets/images/dominance_images/arrow_green_mini.png'

export default {
  name: 'CoinInfo',
  data() {
    return{
        modal: false,

        coinFixed: {
            score: {
                name: this.$t("dominance['score']"),
                value: "-49.10p",
                color: "red",
                image: arrowRedMini
            },
            rsi: {
                name: this.$t("dominance['rsi']"),
                value: "L",
                color: "green",
                image: arrowGreenMini

            },
            signal: {
                name: this.$t("dominance['signal']"),
                value: "L3(61H)",
                color: "green",
                image: arrowGreenMini

            },
            trend: {
                name: this.$t("dominance['trend']"),
                value: "UP",
                color: "green",
                image: arrowGreenMini

            },
            price: {
                name: this.$t("dominance['priceLocation']"),
                value: "고야라인",
                color: "green",
                image: arrowGreenMini

            },
        },
    }
    },
    components: {
        WarnAuth,
    },
    async created() {
        await this.loadCoin("BTC");
    },
    computed: {
        ...mapState("dominance", ["coinList"]),

    },
    methods: {
        ...mapMutations("dominance", ["changeWarn"]),
        ...mapActions("dominance", ["loadCoin"]),

        getImageUrl(symbol) {
            try {
                return require(`@/assets/images/coin_logos/${symbol.toLowerCase()}.png`); 
            } catch {
                return require("@/assets/images/coin_logos/default_dark.png");
            }
        },

        openModal() {
            this.modal = true;
        },
        changeCoin(coin) {
            this.loadCoin(coin);
            this.modal = false;
            this.getImageUrl(coin)
        },
        warnOpen() {
            this.changeWarn(true);
        }
    }
}
  </script>