<template>
  <div class="loading_spinner" v-if="chkRsi === false">
    <div class="loading_circle"></div>
  </div>
  <div :id="chartId" style="width: 100%; height: 100%; background: var(--background-list2);" v-else></div>
</template>

<script>
import { ref, onMounted, nextTick, computed, watch, getCurrentInstance } from 'vue';
import zingchart from 'zingchart';
import 'zingchart/modules-es6/zingchart-treemap.min.js';
import { mapState, useStore } from 'vuex';

export default {
  name: 'ClearingChart',
  setup() {
    const { proxy } = getCurrentInstance();
    const chartId = ref('zingchart-' + Math.random().toString(36).substr(2, 9));
    const store = useStore();
    const rsiData = computed(() => store.state.indicator.chartRsi);
    const rsiTime = computed(() => store.state.indicator.time);
    const language = computed(() => store.state.dominance.lang);

    const getTranslatedText = computed(() => {
      return {
        overSold: proxy.$t('indi.overSold'),
        weak: proxy.$t('indi.weak'),
        neutral: proxy.$t('indi.neutral'),
        strong: proxy.$t('indi.strong'),
        overBought: proxy.$t('indi.overBought'),
        price: proxy.$t('analysis.price'),
        variation: proxy.$t('analysis.variation'),
      };
    });


    const chartData = ref({
      "type": "mixed",
      globals: {
        fontFamily: "var(--main-font-Light)",
        fontColor: "var(--color-main)",
        fontSize: '15px',
      },
      gui: {
        contextMenu: {
          empty: false
        }
      },
      backgroundColor: "transparent",
      "scale-x": {
        "values": "0:1000:500",
        visible: false,
      },
      "scale-y": { 
        minValue: 0,   
        maxValue: 100, 
        "guide": {
          "visible": false
        }
      },
      legend: {
        align: 'center',
        backgroundColor: 'transparent',
        borderColor: 'none',
        layout: 'x5',
        item: {
          fontColor: '#FFFFFF', 
          cursor: 'default',
        },
        marker: {
          'border-color': 'none',
          size: '8px',
        },
        'toggle-action': 'none'
      },
      plot: {
        tooltip: {
          visible: false,
        },
        alphaArea: 1,
        aspect: 'spline',
        hoverState: {
        backgroundColor: 'none',
        },
        lineWidth: '0px',
      },
      labels: [{
        text: "",
        "background-color": "transparent",
        "font-size": "14px",
        "font-color": "#FFF",
        "border-radius": "5px",
        "offset-x": 23,
        "height": "35px",
        "hook": "node:plot=6;index=2",
      }],
      "series": [
        {
          type: "range",
          text: getTranslatedText.value.overBought,
          values: [
            [100, 84],
            [100, 84],
            [100, 84],
          ],
          marker: {
            visible: false,
          },
          "value-box": {
            visible: false,
          },
          backgroundColor: 'var(--bubble-red)',
          'legend-marker': {
            backgroundColor: 'var(--bubble-red)',
          },
        },
        {
          type: "range",
          text: getTranslatedText.value.strong,
          values: [
            [82, 66],
            [82, 66],
            [82, 66],
          ],
          marker: {
            visible: false,
          },
          "value-box": {
            visible: false,
          },
          backgroundColor: 'var(--bubble-orange)',
          'legend-marker': {
            backgroundColor: 'var(--bubble-orange)',
          },
        },
        {
          type: "range",
          text: getTranslatedText.value.neutral,
          values: [
            [64, 36],
            [64, 36],
            [64, 36],
          ],
          marker: {
            visible: false,
          },
          "value-box": {
            visible: false,
          },
          backgroundColor: 'var(--bubble-gray)',
          'legend-marker': {
            backgroundColor: 'var(--bubble-gray)',
          },
        },
        {
          type: "range",
          text: getTranslatedText.value.weak,
          values: [
            [34, 18],
            [34, 18],
            [34, 18],
          ],
          marker: {
            visible: false,
          },
          "value-box": {
            visible: false,
          },
          backgroundColor: 'var(--bubble-green)',
          'legend-marker': {
            backgroundColor: 'var(--bubble-green)',
          },
        },
        {
          type: "range",
          text: getTranslatedText.value.overSold,
          values: [
            [16, 0],
            [16, 0],
            [16, 0],
          ],
          marker: {
            visible: false,
          },
          "value-box": {
            visible: false,
          },
          backgroundColor: 'var(--bubble-blue)',
          'legend-marker': {
            backgroundColor: 'var(--bubble-blue)',
          },
        },
        {
          "type": "bubble",
          "text": "",
          "values": [],
          marker: {
            size: '0px',
            type: 'circle',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
          },
          tooltip: {
            visible: true, 
            text: '%data-coin-infos',
            "backgroundColor": "var(--background-chart2)",
            paddingBottom: '15px',
            cursor: 'point',
          },
          valueBox: {
            text: '%data-coin-names',
          },
          dataCoinNames: [],
          dataCoinInfos: [],
          'legend-marker': {
            visible: false,
          },
        },
        {
          "type": "line",
          "text": "",
          "values": [],
          "line-color": "#FFF",
          "line-style": "dashed",
          lineWidth: '1px',
          marker: {
            visible: false,
          },
          'legend-marker': {
            visible: false,
          },
        },
      ]
    });

    const renderChart = () => {
      zingchart.render({
        id: chartId.value,
        data: chartData.value,
        height: '600px',
        width: '100%',
      });
    };

    const fontLang = () => {
        if (language.value === 'ja') {
          return "Noto Sans JP"
        } else if (language.value === 'zh') {
          return "Noto Sans SC"
        } else {
          return "var(--main-font-Light)"
        }
      }

    const updateChartData = (newData) => {
      chartData.value.globals.fontFamily = fontLang();
      chartData.value.series[5].values = newData.symbolWidth;
      chartData.value.series[6].values = newData.rsi;
      chartData.value.series[5].dataCoinNames = newData.symbol;
      chartData.value.labels[0].text = `AVG RSI <br /> ${newData.rsi[0]}`;
      chartData.value.series[5].dataCoinInfos = newData.symbol.map((_, symbol) => {
        return `
        ${newData.coinInfo[symbol].symbol}
        RSI: ${newData.coinInfo[symbol].rsi}
        ${getTranslatedText.value.price}: $ ${Number(newData.coinInfo[symbol].price).toLocaleString()}
        ${getTranslatedText.value.variation}: ${newData.coinInfo[symbol].pricechangepercent}
        `;
      });

      chartData.value.series[5].dataCoinInfos[0] = ''

      renderChart();
    }

    const removeBtn = () => {
      nextTick(() => {
          const toolBar = document.querySelector(`#${chartId.value}-trigger-c`);
          const chartBtn = document.getElementById(`${chartId.value}-license-text`);
      
          if (toolBar) {
            toolBar.style.display = 'none'; 
          }

          if (chartBtn) {
            chartBtn.style.display = 'none'; 
          }
      });
    }

    const loadRsiChart = async () => {
      await store.dispatch('indicator/loadRsiData'); 
      await nextTick();
      if (rsiData.value) {
        updateChartData(rsiData.value);
      }
      removeBtn();
    }

    onMounted(async () => {
      await loadRsiChart();
    });

    watch(() => store.state.indicator.time, async () => {
        await loadRsiChart();
      });

    watch(rsiTime, (newRange) => {
    if (newRange && rsiData.value) {
      updateChartData(rsiData.value);
      }
    });

    watch(getTranslatedText, () => {
      chartData.value.series[0].text = getTranslatedText.value.overBought;
      chartData.value.series[1].text = getTranslatedText.value.strong;
      chartData.value.series[2].text = getTranslatedText.value.neutral;
      chartData.value.series[3].text = getTranslatedText.value.weak;
      chartData.value.series[4].text = getTranslatedText.value.overSold;
      
      if (rsiData.value) {
        updateChartData(rsiData.value);
      }

      removeBtn();
    })
  

    return {
      chartId,
    };
  },
  computed: {
    ...mapState("indicator", ["chkRsi"])
  }
};
</script>

<style>
</style>
