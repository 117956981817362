import { API, API2, API3 } from "./apiAuth";

// dominance chart data 조회 api
const getDominance = async (time) => {
    try {
      const response = await API2.get(`dominance?hour=${time}`);
      return response.data;
    } catch (error) {
      console.error("Error get dominance chart data : ", error);
      throw error;
    }
  };

// market chart data 조회 api
const getMarket = async () => {
    try {
      const response = await API2.get(`dominance-score`);
      return response.data;
    } catch (error) {
      console.error("Error get market chart data : ", error);
      throw error;
    }
  };

// market mini chart data 조회 api
const getMarketMini = async () => {
  try {
    const response = await API2.get(`dominance-line`);
    return response.data;
  } catch (error) {
    console.error("Error get market mini chart data : ", error);
    throw error;
  }
};

// goya signal data 조회 api
const getSignal = async () => {
  try {
    const response = await API2.get(`goya-signals`);
    return response.data;
  } catch (error) {
    console.error("Error get goya signal data : ", error);
    throw error;
  }
};

// goya signal data 조회 api
const getCoin = async (coin) => {
  try {
    const response = await API3.get(`24hr?symbol=${coin}USDT`);
    return response.data;
  } catch (error) {
    console.error("Error get goya signal data : ", error);
    throw error;
  }
};

// goya score data 조회 api
const getScore = async () => {
  try {
    const response = await API.get(`score_count`);
    return response.data.results[0];
  } catch (error) {
    console.error("Error get goya score data  : ", error);
    throw error;
  }
};









export default {
    getDominance,
    getMarket,
    getMarketMini,
    getSignal,
    getCoin,
    getScore
};
