import api from "@/api/apiAnalysis";
import { formatVolume } from "@/utils/changeUnit";
import i18n from '@/i18n/index';

export default {
    namespaced: true,
    state() {
      return {
      // 변수
      coinTab: 0,
      loadList: false,

      // 응답
      coinList: [],
      heatList: [],

      // 요청
      pageInfo: {
        page : 1,
        size: 300
      },

      heatType: "volume",

      }
    },
    mutations: {
      changeList(state, type) {
        state.coinTab = type
      },
      changeType(state, type) {
        state.heatType = type
      },
      changeLoad(state, type) {
        state.loadList = type
      },
    
    }, 
    actions: {
      // spot coin list 조회 api
      async loadSpotCoin(context) {
        try {
            let page = context.state.pageInfo
            let response = await api.getSpot(page.page, page.size);

            for (let i = 0; i < response.results.length; i++) {
              response.results[i].rank = i+1
              response.results[i].priceVal = response.results[i].price
              response.results[i].volumeVal = response.results[i].volume24h
              response.results[i].marketCapVal = response.results[i].marketCap
              response.results[i].price = response.results[i].price.toLocaleString();
              response.results[i].volume24h = formatVolume(response.results[i].volume24h)
              response.results[i].marketCap = formatVolume(response.results[i].marketCap)
            }

            context.state.coinList = response.results
        } catch (error) {
            console.error("Error get spot lost:", error);
            return;
        }
      },
      // futer coin list 조회 api
      async loadFuterCoin(context) {
        try {
            let page = context.state.pageInfo
            let response = await api.getFuter(page.page, page.size);

            for (let i = 0; i < response.results.length; i++) {
              response.results[i].rank = i+1
              response.results[i].priceVal = response.results[i].price
              response.results[i].volumeVal = response.results[i].volume
              response.results[i].liqVal = response.results[i].liquidationUsd24h
              response.results[i].marketCapVal = response.results[i].marketCap
              response.results[i].price = response.results[i].price.toLocaleString();
              response.results[i].volume = formatVolume(response.results[i].volume)
              response.results[i].marketCap = formatVolume(response.results[i].marketCap)
              response.results[i].liquidationUsd24h = formatVolume(response.results[i].liquidationUsd24h)
            }

            context.state.coinList = response.results
            context.state.loadList = true
        } catch (error) {
            console.error("Error get futer list:", error);
            return;
        }
      },
      // heat map data 조회 api
      async loadHeatMap(context) {
        try {
          let type = context.state.heatType
          let response = await api.getHeatMap(type);
          context.state.heatList = []
          let heatList = context.state.heatList
          let data = "";
          let value = "";
          let heatData
          let info = response.results
          let long
          let short
          let longVal
          let shortVal


          if (type === 'volume' || type === 'liq') {
            heatData = info.slice(0, 25)
          } else {
            for (let i=0; i < info.length; i++) {
              info.sort((a, b) => Math.abs(b.priceChange24h) - Math.abs(a.priceChange24h));
            }
            heatData = info.slice(0, 25)
          }
          
          for (let result of heatData) {
            if (type === 'volume') {
              data = "$" + formatVolume(result.volume)
              value = result.volume
            } else if (type === 'change') {
              data = result.priceChange24h
              value = Math.abs(result.priceChange24h)
            } else if (type === 'liq') {
              data = "$" + formatVolume(result.liquidation)
              value = result.liquidation
              long = formatVolume(result.longLiquidationUsd24h)
              longVal = result.longLiquidationUsd24h
              short = formatVolume(result.shortLiquidationUsd24h)
              shortVal = result.shortLiquidationUsd24h
            }

          let info 

          if (type === 'volume' || type === 'change') {
            info = {
              value: value,
              data: data,
              text: result.symbol + "<br />" + "$" + result.price.toLocaleString() + "<br />" + data,
            }
          } else if (type === 'liq') {
            info = {
              value: value,
              data: data,
              text: result.symbol + "<br />" 
              + "$" + result.price.toLocaleString() + "<br />" 
              + data +  "<br />" 
              + `${ i18n.global.t('signal.long') }: ` + long +  "<br />" 
              + `${ i18n.global.t('signal.short') }: ` + short,
              long: longVal,
              short: shortVal,
            }
          }
            heatList.push(info)
          }
        } catch (error) {
            // console.error("Error get heat map data:", error);
            return;
        }
      },
    },
  }
  