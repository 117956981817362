<template>
    <div class="modal_overlay"></div>
    <div class="warn_ment_wrapper">
        <div class="close_warn">
            <button class="close_btc" >
                <img @click="closeModal" src="@/assets/images/dominance_images/close_icon.png" />
            </button>
        </div>
        <div class="warn_login_ment">
            <div id="screen__pc">
                <h2 class="warn_ment_modal">
                    <img src="@/assets/images/dominance_images/lock.svg" />
                    {{ $t('warn.warnMessage') }}
                </h2>
                <br/>
                <div class="btn_wrapper">
                    <button class="login_btn" @click="goRetri">{{ $t('warn.singBtn') }}</button>
                </div>
            </div>
            <div id="screen__mobile">
                <div class="login_warn_ment">
                    <!-- <div class="ment_warn"> -->
                        <h2 class="warn_ment_modal">
                            <img src="@/assets/images/dominance_images/lock.svg" />
                            {{ $t('warn.warnMessage') }}
                        </h2>
                    <!-- </div> -->
                    <br/>
                    <div class="btn_wrapper">
                        <button class="login_btn_mobile" @click="goRetriM">{{ $t('warn.singBtn') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default{
    name:"WarnModal",
    // computed: {
    //     mainPath() {
    //         return this.$route.path.split("/")[1];
    //     }
    // },
    methods: {
        ...mapMutations("dominance", ["changeWarn"]),

        goRetri() {
            window.open("https://www.tryex.io/index.asp")
            this.changeWarn(false);
        },
        goRetriM() {
            window.open("https://www.tryex.io/m/");
        },
        closeModal() {
            this.changeWarn(false);
        }
    },
}
</script>