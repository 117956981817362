<template>
    <div class="mainWrap list">
        <div class="subCont whiteDoc">
            <TitleView />
            <div id="screen__pc" class="wrapper wrapper__dominance">
                <div class="dominance-wrapper">

                    <!-- 고야 차트 -->
                    <div class="goya-dominance">
                        <h1>
                            <img src="@/assets/images/dominance_images/logo.png" class="logo__img"/>
                            <span data-content="dominance" data-word="goyaD" class="ko">{{ $t('dominance.goyaDominance')
                                }}</span>
                        </h1>
                        <div class="container">
                            <GoyaDominance />
                        </div>
                    </div>

                    <!-- 마켓 차트 -->
                    <div class="market-dominance">
                        <h1>
                            <img src="@/assets/images/dominance_images/logo.png" class="logo__img"/>
                            <span data-content="dominance" data-word="goyaD" class="ko">{{ $t('dominance.marketDominance') }}</span>
                        </h1>
                        <div class="container">
                            <div class="market-pie-info-wrapper">
                                <p data-content="dominance" data-word="totalV" class="ko">{{ $t('dominance.totalVolume')}}(24h)</p>
                                <div class="market-pie-info-content">
                                    <div class="market-pie-info">
                                        <h1 class="market-pie-volume">$ {{ marketChart.total_cap }}</h1>
                                    </div>
                                    <p class="market-pie-percent">
                                        <span class="add-image" :class="[{ plus: marketChart.rate_of_change > 0}, { minus: marketChart.rate_of_change < 0}]">
                                            {{ marketChart.rate_of_change }}%
                                            <img
                                                :src="marketChart.rate_of_change < 0 ? require('@/assets/images/dominance_images/arrow_red.png') : require('@/assets/images/dominance_images/arrow_blue.png')">

                                        </span>
                                    </p>
                                </div>
                            </div>
                            <MarketDominance />
                        </div>
                    </div>
                </div>

                <!-- 시그널 -->
                <div class="goya-signal">
                    <h1>
                        <img src="@/assets/images/dominance_images/logo.png" class="logo__img"/>
                        <span data-content="dominance" data-word="goyaSignal" class="ko">{{ $t('dominance.goyaSignals')
                            }}</span>
                    </h1>
                    <div class="container">
                        <SignalView />
                    </div>
                </div>

                <!-- BTC 코인 -->
                <CoinInfo />

                <!-- 하단 메뉴 -->
                <div class="bottom-menu">
                    <div class="bottom-menu-list" @click="warnOpen">
                        <img src="@/assets/images/dominance_images/list_icon.png" />
                        <p data-content="dominance" class="ko">{{ $t('dominance.goyaList') }}</p>
                    </div>
                    <div class="bottom-menu-list" @click="warnOpen">
                        <img src="@/assets/images/dominance_images/predict_chart_icon.png" />
                        <p data-content="dominance" class="ko">{{ $t('dominance.pastPredict') }}</p>
                    </div>
                    <router-link to="/brief" class="bottom-menu-list">
                        <img src="@/assets/images/dominance_images/breif_icon.png" />
                        <p data-content="dominance" class="ko">{{ $t('dominance.aiBrief') }}</p>
                    </router-link>
                    <div class="bottom-menu-list" @click="warnOpen">
                        <img src="@/assets/images/dominance_images/issue_calender_icon.png" />
                        <p data-content="dominance" class="ko">{{ $t('dominance.issueCalendar') }}</p>
                    </div>
                </div>
            </div>
            <div id="screen__mobile" class="wrapper wrapper__mobile">
                    
                <!-- 고야 차트 -->
                <div class="goya-dominance">
                    <h1>
                        <img src="@/assets/images/dominance_images/logo.png" class="logo__img"/>
                        <span data-content="dominance" data-word="goyaD" class="ko">{{ $t('dominance.goyaDominance')
                            }}</span>
                    </h1>
                    <div class="container">
                        <GoyaDominanceMobile />
                    </div>
                </div>

                <!-- 마켓 차트 -->
                <div class="market-dominance">
                    <h1>
                        <img src="@/assets/images/dominance_images/logo.png" class="logo__img"/>
                        <span data-content="dominance" data-word="goyaD" class="ko">{{
                            $t('dominance.marketDominance') }}</span>
                    </h1>
                    <div class="container">
                        <div class="market-pie-info-wrapper">
                            <p data-content="dominance" data-word="totalV" class="ko">{{ $t('dominance.totalVolume')
                                }}(24h)</p>
                            <div class="market-pie-info-content">
                                <div class="market-pie-info">
                                    <h1 class="market-pie-volume" style="margin: 0 !important;">$ {{
                                        marketChart.total_cap }}</h1>
                                </div>
                                <br />
                                <div>
                                    <p class="market-pie-percent">
                                        <span class="add-image" :class="[{ plus: marketChart.rate_of_change > 0}, { minus: marketChart.rate_of_change < 0}]">
                                            {{ marketChart.rate_of_change }}%
                                            <img
                                                :src="marketChart.rate_of_change < 0 ? require('@/assets/images/dominance_images/arrow_red.png') : require('@/assets/images/dominance_images/arrow_blue.png')">

                                        </span>
                                    </p>
                                </div>
                                
                            </div>
                        </div>
                        <MarketDominance />
                    </div>
                </div>

                <!-- 시그널 -->
                <div class="goya-signal">
                    <h1 style="margin: 1rem 0 2rem;">
                        <img src="@/assets/images/dominance_images/logo.png" class="logo__img"/>
                        <span data-content="dominance" data-word="goyaSignal" class="ko">{{ $t('dominance.goyaSignal')
                            }}</span>
                    </h1>
                    <div class="container">
                        <SignalViewMobile />
                    </div>
                </div>

                <!-- BTC 코인 -->
                <CoinInfoMobile />

                <!-- 하단 버튼 -->
                <div class="menu_bottom">
                    <div class="bottom-menu">
                        <div class="bottom-menu-list" @click="warnOpen">
                            <img src="@/assets/images/dominance_images/list_icon.png" />
                            <p data-content="dominance" class="ko">{{ $t('dominance.goyaList') }}</p>
                        </div>
                        <div class="bottom-menu-list" @click="warnOpen">
                            <img src="@/assets/images/dominance_images/predict_chart_icon.png" />
                            <p data-content="dominance" class="ko">{{ $t('dominance.pastPredict') }}</p>
                        </div>
                        <router-link to="/brief" class="bottom-menu-list">
                            <img src="@/assets/images/dominance_images/breif_icon.png" />
                            <p data-content="dominance" class="ko">{{ $t('dominance.aiBrief') }}</p>
                        </router-link>
                        <div class="bottom-menu-list" @click="warnOpen">
                            <img src="@/assets/images/dominance_images/issue_calender_icon.png" />
                            <p data-content="dominance" class="ko">{{ $t('dominance.issueCalendar') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <WarnModal v-if="warnModal" />
    </div>

    <GoyaMini v-if="miniModal === 1" />
    <MarketMini v-if="miniModal === 2" />
</template>


<script>
import { mapState, mapMutations, mapActions } from 'vuex';

import MarketDominance from '@/components/dominance/chart/MarketDominance.vue';
import GoyaDominance from '@/components/dominance/chart/GoyaDominance.vue'
import MarketMini from "@/components/dominance/chart/MarketMini.vue"
import GoyaMini from "@/components/dominance/chart/GoyaMini.vue"
import TitleView from "@/components/public/TitleView.vue"
import WarnModal from "@/components/public/WarnModal.vue"
import SignalView from "@/components/dominance/sub/SignalView.vue"
import CoinInfo from "@/components/dominance/sub/CoinInfo.vue"
import GoyaDominanceMobile from './chart/GoyaDominanceMobile.vue';
import SignalViewMobile from './sub/SignalViewMobile.vue';
import CoinInfoMobile from './sub/CoinInfoMobile.vue';

export default {
    name: 'DominanceView',
    components: {
        GoyaDominance,
        GoyaDominanceMobile,
        MarketDominance,
        GoyaMini,
        MarketMini,
        TitleView,
        SignalView,
        SignalViewMobile,
        CoinInfo,
        CoinInfoMobile,
        WarnModal
    },
    async crated() {
        await this.loadDominance();
        await this.loadMarket();
    },
    computed: {
        ...mapState("dominance", ["warnModal"]),
        ...mapState("dominance_mini", ["miniModal"]),
        ...mapState("dominance_chart", ["marketChart"]),

        lang() {
            return localStorage.getItem("lang")
        }
    },
    methods: {
        ...mapMutations("dominance", ["changeWarn"]),
        ...mapMutations("dominance_mini", ["changeMini", "changeLoad"]),
        ...mapActions("dominance_chart", ["loadDominance", "loadMarket"]),

        openMini(chart) {
            this.changeMini(chart)
        },
        warnOpen() {
            this.changeWarn(true);
        },
    },
}
</script>

<style scoped>
.warn_ment {
    margin: 0 5vw 0 5vw;
}
</style>