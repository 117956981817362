<template>
    <div :id="chartId"></div>
  </template>
  
  <script>
  import { ref, onMounted, onBeforeUnmount, computed, watch, nextTick, getCurrentInstance } from 'vue';
  import zingchart from 'zingchart';
  import 'zingchart/modules-es6/zingchart-treemap.min.js';
  import { mapActions, mapState } from 'vuex';
  import { useStore } from 'vuex';
  
  export default {
    name: 'RatioChart',
    setup() {
      const { proxy } = getCurrentInstance();
      const chartId = ref('zingchart-' + Math.random().toString(36).substr(2, 9));
      const store = useStore();
      const ratioInfo = computed(() => store.state.signal.ratioData);
      const ratioRange = computed(() => store.state.signal.range);
      const language = computed(() => store.state.dominance.lang);

      const getTranslatedText = computed(() => {
        return {
          long: proxy.$t('signal.long'),
          short: proxy.$t('signal.short'),
          btcPrice: proxy.$t('pub.btcPrice'),
        };
      });

      const chartData = ref({
        type: "mixed",
        globals: {
          fontFamily: "var(--main-font-Light)",
          fontColor: 'var(--color-main)', 
          fontSize: '15px',

        },
        gui: {
        contextMenu: {
          empty: false
        }
      },
        backgroundColor: "transparent",
        legend: {
          layout: 'x3',
          backgroundColor: 'transparent',
          borderColor: 'none',
          overflow: 'page',
          alpha: 0.05,
          shadow: false,
          align: 'center',
          marker: {
            'border-color': 'none',
            width: '20px',
            height: '5px',
            size: '10px'
          },
          item: {
            fontColor: 'var(--color-main)', 
          },
          'toggle-action': 'none'
        },
        responsive: [{
          breakpoint: 1400,
          options: {
            'height': '300px', 
          }
        }],
        'scale-x': {
          values: [],
          guide: { 
            visible: false,
          },

        },
        'scale-y': {
          values: "0:100:25",
          guide: { 
            visible: false,
          },
        },
        'scale-y-2': {
          values: [],
          guide: { 
            visible: false,
          },
          "thousands-separator": ","
        },
        
        'crosshair-x': {
          offsetY: '10%'
        },
        plot: {
              tooltip: {
                visible: false,
              },
              hoverState: {
                visible: false,
              },
              highlightState: {
                visible: false,
              }
            },
        series: [
          {
            type: "bar",
            text: getTranslatedText.value.long,
            scales: "scale-x,scale-y",
            borderRadiusBottomLeft: '30px',
            borderRadiusBottomRight: '30px',
            values: [],
            'background-color': "var(--signal-green1) var(--signal-green2)",
            stacked: true,
            stack: 0.1,
            "bar-width": "100%",
            "guide-label": {
              "backgroundColor": "var(--background-chart2)",
              "text": "%t: %v %",
            }
          },
          {
            type: "bar",
            text: getTranslatedText.value.short,
            scales: "scale-x,scale-y",
            borderRadiusTopLeft: '30px',
            borderRadiusTopRight: '30px',
            values: [],
            'background-color': "var(--signal-red2) var(--chart-red)",
            stacked: true,
            stack: 0.1,
            "bar-width": "100%",
            plot: {
              "value-box": {
                visible: false,
              },
            },
            "guide-label": {
              "text": "%t: %v %",

            }
          },
          {
            type: "line",
            text: getTranslatedText.value.btcPrice,
            legendMarker: {
              'border-width': 1,
              'border-color': "var(--color-main)",
              type: "circle",
              'show-line': true,
              'line-color': "var(--color-main)",
              'size': '5px',
              width: '20px',
              height: '5px'
            },
            "line-color": "var(--color-main)",
            "line-width": 3,
            scales: "scale-x,scale-y-2",
            values: [],
            // strVal: [],
            // label: "123",
            marker: {
              "background-color": "var(--color-main)",
              "border-width": 2,
              shadow: 0,
              "border-color": "var(--color-main)",
            },
            plot: {
              "value-box": {
                visible: false,
              },
            },
            guideLabel: {
              text: `%t: $%v`,
              "thousands-separator": ","
            },
          },
        ],
      });
  
      const renderChart = () => {
        zingchart.render({
          id: chartId.value,
          data: chartData.value,
          height: '450px',
          width: '100%',
 
        });
      };

      const fontLang = () => {
        if (language.value === 'ja') {
          return "Noto Sans JP"
        } else if (language.value === 'zh') {
          return "Noto Sans SC"
        } else {
          return "var(--main-font-Light)"
        }
      }

      const removeBtn = () => {
        nextTick(() => {
          const toolBar = document.querySelector(`#${chartId.value}-trigger-c`);
          if (toolBar) {
            toolBar.style.display = 'none';
          }
        });
      }
  
      const updateChartData = (newData) => {
        chartData.value.globals.fontFamily = fontLang();
        chartData.value.series[0].values = newData.long;
        chartData.value.series[1].values = newData.short;
        chartData.value.series[2].values = newData.price;
        // chartData.value.series[2].strVal = newData.price.toLocaleString();
        
        chartData.value['scale-x'].values = newData.datatime;
        chartData.value['scale-y-2'].values = newData.range;
        
        renderChart();
      };
  
    const loadAndUpdateChart = async () => {
      await store.dispatch('signal/loadRatioData');  // 액션을 디스패치하여 데이터 로드
      await nextTick(); 
      if (ratioInfo.value) {
        updateChartData(ratioInfo.value);
      }
      removeBtn();
    };
  
      onMounted(async () => {
        await loadAndUpdateChart();
      });
  ``
      onBeforeUnmount(() => {
        zingchart.exec(chartId.value, 'destroy');
      });
  
      watch(() => store.state.signal.time, async () => {
        await loadAndUpdateChart();
      });
  
      watch(ratioRange, (newRange) => {
      if (newRange && chartData.value) {
          chartData.value['scale-y-2'].values = newRange;
          renderChart(); // 차트 다시 렌더링
        }
      });

      watch(getTranslatedText, () => {
        chartData.value.series[0].text = getTranslatedText.value.long;
        chartData.value.series[1].text = getTranslatedText.value.short;
        chartData.value.series[2].text = getTranslatedText.value.btcPrice;

        renderChart();
      })
  
      return {
        chartId,
      };
    },
    computed: {
      ...mapState("signal", ["ratioData", "time", "range"]),
    },
    methods: {
      ...mapActions("signal", ["loadRatioData"]),
    },
  };
  </script>
  
  <style>
  #chartId {
    width: 100%;
    height: 500px;
  }

  @media (max-width: 1400px) {
  .chartId {
    height: 300px; /* 1400px 이하에서 높이 조정 */
  }
}
  </style>
  