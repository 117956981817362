import api from "@/api/apiBrief";

export default {
    namespaced: true,
    state() {
      return {
      // 변수
      loadDetail: false,

      // 응답
      titleList: [],
      aiBrief: [],

      // 요청
      briefTime: "AM",
      limit: 0,

      }
    },
    mutations: {
      changeTime(state, type) {
        if (type === 0) {
          state.briefTime = "AM"
        } else {
          state.briefTime = "PM"
        }
      },
      changeType(state, type) {
        state.heatType = type
      },

    }, 
    actions: {
      // brief title list 조회 api
      async loadTitle(context) {
        try {
            let response = await api.getAiBriefTitle(context.state.briefTime, context.state.limit)
              for (let i = 0; i < response.length; i++) {
              response[i].mainTitle = response[i].title.slice(0, 11)
            }
            context.state.titleList = response
        } catch (error) {
            console.error("Error get spot lost:", error);
            return;
        }
      },

      // brief title list 조회 api
      async loadDetail(context, info) {
        try {
            context.state.aiId = info.id;
            let response = await api.getAiBrief(info.id, info.lang)

            let date = response.result.date.split("T")[0].replaceAll("-", ".");
            let time = response.result.date.split("T")[1].substr(0, 5);
  
            response.result.Date = date;
            response.result.Time = time;

            context.state.aiBrief = response.result;
            context.state.loadDetail = true;

        } catch (error) {
            console.error("Error get spot lost:", error);
            return;
        }
      },
    }
  }
  