<template>
    <div>
        <div class="rsi-list-wrapper">
            <div class="rsi-list-header">
                <div class="rsi-list-header-item">
                    {{ $t('indi.rank') }}
                </div>
                <div class="rsi-list-header-item">
                    {{ $t('indi.coin') }}
                </div>
                <div 
                    class="rsi-list-header-item" 
                    @click="sortList('priceval')"
                >
                    {{ $t('indi.price') }}
                    <img :src="getArrowImage('priceval')" />
                </div>
                <div 
                    class="rsi-list-header-item" 
                    @click="sortList('priceChangePercent1h')"
                >
                    {{ $t('indi.1hChange') }}
                    <img :src="getArrowImage('priceChangePercent1h')" />
                </div>
                <div 
                    class="rsi-list-header-item" 
                    @click="sortList('priceChangePercent24h')"
                >
                    {{ $t('indi.24hChange') }}
                    <img :src="getArrowImage('priceChangePercent24h')" />
                </div>
                <div 
                    class="rsi-list-header-item" 
                    @click="sortList('rsi15m')"
                >
                    {{ $t('indi.15rsi') }}
                    <img :src="getArrowImage('rsi15m')" />
                </div>
                <div 
                    class="rsi-list-header-item" 
                    @click="sortList('rsi1h')"
                >
                    {{ $t('indi.1hRsi') }}
                    <img :src="getArrowImage('rsi1h')" />
                </div>
                <div 
                    class="rsi-list-header-item" 
                    @click="sortList('rsi4h')"
                >
                    {{ $t('indi.4hRsi') }}
                    <img :src="getArrowImage('rsi4h')" />
                </div>
                <div 
                    class="rsi-list-header-item" 
                    @click="sortList('rsi12h')"
                >
                    {{ $t('indi.12hRsi') }}
                    <img :src="getArrowImage('rsi12h')" />
                </div>
                <div 
                    class="rsi-list-header-item" 
                    @click="sortList('rsi24h')"
                >
                    {{ $t('indi.24hRsi') }}
                    <img :src="getArrowImage('rsi24h')" />
                </div>
            </div>
            <div >
                <div class="rsi-content-wrapper" >
                    <div class="rsi-list-content" v-for="coin in filteredCoinList" :key="coin">
                        <div class="ranking">{{ coin.rank }}</div>
                        <div class="coin">
                            <img :src="getCoinIcon(coin.symbol)" alt="" class="coin-icon">
                            {{ coin.symbol }}/
                            <span>USDT</span>
                        </div>
                        <div v-if="!coin.price.includes(0.0)">$ {{ coin.price }}</div>
                        <div v-else>$ {{ coin.priceval }}</div>
                        <div :class="{'positive': coin.priceChangePercent1h > 0, 'negative': coin.priceChangePercent1h < 0}">
                            <span v-if="coin.priceChangePercent1h > 0">+</span>
                            {{ coin.priceChangePercent1h }}%
                        </div>
                        <div :class="{'positive': coin.priceChangePercent24h > 0, 'negative': coin.priceChangePercent24h < 0}">
                            <span v-if="coin.priceChangePercent24h > 0">+</span>
                            {{ coin.priceChangePercent24h }}%
                        </div>
                        <div>{{ coin.rsi15m }}</div>
                        <div>{{ coin.rsi1h }}</div>
                        <div>{{ coin.rsi4h }}</div>
                        <div>{{ coin.rsi12h }}</div>
                        <div>{{ coin.rsi24h }}</div>
                    </div>
                </div>
                <div class="coin-content-wrapper" v-if="filteredCoinList.length === 0">
                    <div class="coin-no-data">{{ $t('brief.noData') }}</div>
                </div>
            </div>
            <!-- <div class="loading_spinner" >
                <div class="loading_circle"></div>
            </div> -->
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
    name: "RsiGraph",
    props: {
        searchCoin: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            sortKey: null,  // 현재 정렬된 키
            sortOrder: 1,   // 정렬 순서 (1: 오름차순, -1: 내림차순)
            activeSortKey: null // 현재 활성화된 정렬 키
        };
    },
    created() {
        this.loadRsi();
    },
    computed: {
        ...mapState("indicator", ["graphRsi"]),
        
        filteredCoinList() {
            let sortedList = this.sortedCoinList; 
            if (this.searchCoin) {
                sortedList = sortedList.filter(coin => 
                    coin.symbol.toLowerCase().includes(this.searchCoin.toLowerCase())
                );
            }
            return sortedList;
        },
        
        sortedCoinList() {
            let sortedList = [...this.graphRsi]; 

            if (this.sortKey) {
                sortedList.sort((a, b) => {
                    let aValue = a[this.sortKey];
                    let bValue = b[this.sortKey];
                    
                    if (!isNaN(aValue) && !isNaN(bValue)) {
                        aValue = parseFloat(aValue);
                        bValue = parseFloat(bValue);
                    }
                    
                    if (aValue < bValue) {
                        return -1 * this.sortOrder;
                    }
                    if (aValue > bValue) {
                        return 1 * this.sortOrder;
                    }
                    return 0;
                });
            }
            
            return sortedList;
        }
    },
    methods: {
        ...mapActions("indicator", ["loadRsi"]),

        getCoinIcon(symbol) {
            try {
                return require(`@/assets/images/coin_logos/${symbol.toLowerCase()}.png`); 
            } catch {
                return require("@/assets/images/coin_logos/default_dark.png");
            }
        },

        getArrowImage(key) {
            if (this.sortKey === key) {
                return this.sortOrder === 1 
                    ? require('@/assets/images/dominance_images/arrow_down.png') 
                    : require('@/assets/images/dominance_images/arrow_up.png');
            } else {
                return require('@/assets/images/dominance_images/arrow_down.png');
            }
        },

        sortList(key) {
            if (this.sortKey === key) {
                this.sortOrder *= -1;
            } else {
                this.sortKey = key;
                this.sortOrder = -1;
            }
            this.activeSortKey = key;
        }
    },
    watch: {
        coinTab(val) {
            if (val === 0) {
                this.loadFuterCoin();
            } else if (val === 1) {
                this.loadSpotCoin();
            }
        }
    }
}
</script>

